import {useTssProjects, useUserRoleInProject} from '../lib'
import {Divider, ListItemIcon, MenuItem, Select, Stack, Typography, Unstable_Grid2 as Grid} from '@mui/material'
import {InterviewFetchData} from './InterviewFetchData'
import groupBy from "lodash/groupBy";
import {InterviewListItem} from "./InterviewListItem";
import {Fragment, useState} from "react";
import {ListFilterState} from "./fixtures";
import {InterviewListFilter} from "./InterviewListFilter";
import {ListAlt, ListOutlined} from "@mui/icons-material";
import {InterviewListFileUpload} from "./fileUpload/InterviewListFileUpload";

type TssInterviewListProps = {
    projectId: string
}


export default function TssInterviewList({projectId}: TssInterviewListProps) {
    const projects = useTssProjects()
    const project = projects.find(i => i.id === projectId)
    const userRole = useUserRoleInProject(projectId)

    const projectInterviews = project?.project_interviews ?? []
    // const [projectInterviews] = useState(project?.project_interviews ?? [])
    const listOfWealthGroups = projectInterviews
        ?.map(i => i.wealth_group)
        .filter((v, i, s) => s.findIndex(r => r.id === v.id) === i)
    const listOfLocations = projectInterviews
        ?.map(i => i.location)
        .filter((v, i, s) => s.findIndex(r => r.id === v.id) === i)

    const [view, setView] = useState<'a' | 'b'>(projectInterviews?.length > 15 ? 'a' : 'b')
    const [filter, setFilter] = useState<ListFilterState>(null)
    const [wealthGroupFilter, setWealthGroupFilter] = useState<string>('')
    const filteredList = (filter || wealthGroupFilter) ? projectInterviews.filter((interview) => {
        let filterA = true
        if (filter === '04') {
            filterA = !!interview.status
        } else if (filter === '03') {
            filterA = !!interview.project_interview_base?.is_done && !interview.status
        } else if (filter === '02') {
            filterA = !!interview.project_interview_base?.created_at && !interview.project_interview_base?.is_done
        } else if (filter === '01') {
            filterA = !interview.project_interview_base?.created_at
        }

        let filterB = wealthGroupFilter ? interview.wealth_group?.id === wealthGroupFilter : true
        return filterA && filterB
    }) : projectInterviews
    const grouped = groupBy(filteredList, 'location.id')
    if (!project?.id) {
        console.error("should never reach this...")
        return null
    }
    return (
        <Stack spacing={1}>
            {!projectInterviews?.length && (
                <InterviewFetchData/>
            )}
            <Stack justifyContent={'space-between'} direction={'row'} spacing={2} alignItems={'center'}>
                {(userRole === 'teamleader' || userRole === 'supervisor') ? (
                    <div>
                        <InterviewListFileUpload projectId={project.id} locationList={listOfLocations}/>
                    </div>
                ) : <div/>}
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Select value={view} size={'small'} onChange={event => {
                        setView(event.target.value as 'a' | 'b')
                    }} sx={{
                        minWidth: 60,
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiListItemIcon-root': {
                                minWidth: 32
                            }
                        },

                    }}>
                        <MenuItem value={'a'}>
                            <ListItemIcon><ListAlt/></ListItemIcon>
                        </MenuItem>
                        <MenuItem value={'b'}>
                            <ListItemIcon><ListOutlined/></ListItemIcon>
                        </MenuItem>
                    </Select>
                    <Select size={'small'} displayEmpty={true} value={wealthGroupFilter} onChange={ev => {
                        setWealthGroupFilter(ev.target.value as string)
                    }}>
                        <MenuItem value={''}>All</MenuItem>
                        {listOfWealthGroups?.map(wealthGroup => (
                            <MenuItem key={wealthGroup.id} value={wealthGroup.id}>{wealthGroup.en}</MenuItem>
                        ))}
                    </Select>
                    <InterviewListFilter onChange={v => setFilter(v)}/>
                </Stack>
            </Stack>
            <Grid container spacing={4} flexWrap={'wrap'}>
                {view === 'b' && filteredList?.map(interview => {
                    return (
                        <InterviewListItem interview={interview}
                                           project={project}
                                           key={interview.id}/>
                    )
                })}
                {view === 'a' && Object.keys(grouped).map((key) => {
                    const interviewList = grouped[key]

                    if (!interviewList.length) {
                        return null
                    }
                    return (
                        <Fragment key={key}>
                            <Grid xs={12}>
                                <Divider><Typography
                                    variant={'h5'}>{interviewList[0].location.name}</Typography></Divider>
                            </Grid>
                            {interviewList.map((interview) => (
                                <InterviewListItem interview={interview}
                                                   project={project}
                                                   key={interview.id}/>
                            ))}
                        </Fragment>
                    )
                })}
            </Grid>
        </Stack>
    )
}
