import {LoadingButton} from "@mui/lab";
import {useTssInterviewDetailQuery} from "../lib/gql/__codegen__/react-query";
import {useSnackbar} from "notistack";
import {prepareTssVillageDataSyncDown} from "./syncTssDb/prepareTssVillageDataSyncDown";
import omit from "lodash/omit";
import {TssVillageData, useSetVillageData, useTssUpdatedAt, useUnsetTssUpdatedAt, villageBaseDataKeys} from "../lib";
import {useState} from "react";

type InterviewListItemDownloadProps = {
    interviewId: string
}

export function InterviewListItemDownload({interviewId}: InterviewListItemDownloadProps) {
    const {refetch, isFetching} = useTssInterviewDetailQuery({
        id: interviewId
    }, {
        enabled: false
    })
    const setVillageData = useSetVillageData()
    const unset = useUnsetTssUpdatedAt()
    const updatedAt = useTssUpdatedAt(interviewId)
    const {enqueueSnackbar} = useSnackbar()
    const [downloaded, setDownloaded] = useState(false)


    if (updatedAt || downloaded) {
        return null
    }

    return (
        <LoadingButton loading={isFetching} onClick={async () => {
            const confirm = window.confirm('Are you sure you want to download the interview?')
            if (confirm) {
                try {

                    const data = await refetch()
                    const projectInterviewByPk = data?.data?.project_interview_by_pk
                    const prepared = prepareTssVillageDataSyncDown(projectInterviewByPk)
                    const newVillageData = omit({
                        ...prepared
                    }, villageBaseDataKeys);
                    setVillageData(interviewId, newVillageData as TssVillageData)
                    unset(interviewId)
                    setDownloaded(true)
                } catch (e) {
                    console.error(e)
                    enqueueSnackbar('Something went wrong. Please contact support team.', {
                        variant: 'error'
                    })
                }
                // setIsEnabled(true)
            }
        }}>
            Download
        </LoadingButton>
    )
}