import {FileUploadMenu} from "../../common/form/fileUpload/FileUpload";
import {useIsOnline} from "../../provider";
import {useInsertProjectFilesMutation, useProjectFilesQuery} from "../../lib/gql/__codegen__/react-query";
import {TssState} from "../../lib";
import {MenuItem, TextField} from "@mui/material";
import {useState} from "react";
import {FileListItem} from "../../common/form/fileUpload/fileUploadTypes";

export type LocationItem = TssState['projects'][number]['project_interviews'][number]["location"];

export function InterviewListFileUpload({projectId, locationList}: {
    projectId: string,
    locationList: LocationItem[]
}) {
    const {isOnline} = useIsOnline()
    const [locationId, setLocationId] = useState<string>()
    const {mutateAsync, isPending, error} = useInsertProjectFilesMutation()
    const {data, isFetching, refetch} = useProjectFilesQuery({projectId}, {
        enabled: isOnline
    })

    if (!isOnline) {
        return null
    }
    const fileList: FileListItem[] = data?.project_file?.map(i => ({
        primaryText: i.file?.name || 'File name missing',
        secondaryText: i.location?.name || 'Livelihood Zone',
        file: i.file
    })) ?? [];
    return (
        <>
            <FileUploadMenu
                CustomSelector={<TextField select={true}
                                           label={'Tag Attachment'}
                                           size={'small'}
                                           onChange={event => {
                                               setLocationId(event.target.value as string)
                                           }}>
                    <MenuItem value={''}>Livelihood Zone</MenuItem>
                    {locationList.map((location) => (
                        <MenuItem value={location.id} key={location.id}>{location.name}</MenuItem>
                    ))}
                </TextField>}
                triggerProps={{
                    variant: 'outlined',
                    size: 'large',
                    children: 'Baseline Attachments'
                }}
                fileList={fileList}
                isLoading={isPending}
                refetchFileQuery={refetch}
                afterUpload={async (files) => {
                    try {
                        if (Array.isArray(files) && files.length > 0) {
                            await mutateAsync({
                                objects: files.map(i => ({
                                    project_id: projectId,
                                    file_id: i,
                                    location_id: locationId || null
                                }))
                            })
                        } else if (typeof files === 'string') {
                            await mutateAsync({
                                objects: [{
                                    project_id: projectId,
                                    file_id: files,
                                    location_id: locationId || null
                                }]
                            })
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }}
                fileInputProps={{
                    multiple: true
                }}/>
        </>
    )
}
