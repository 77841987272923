import ProjectList from "../../../tss/admin/ProjectList";
import {TssLayout} from "../../../theme/layouts/tss";
import {PageRoot} from "../../../common/layout/PageRoot";

export default function TssLanding() {
    return (
        <PageRoot title={'Baselines'}>
            <TssLayout title={'Baselines'}>
                <ProjectList/>
            </TssLayout>
        </PageRoot>
    )
}
