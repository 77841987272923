import {Alert, Stack} from "@mui/material";
import {CheckCircle, CloudUpload, Lock, LockReset, Publish, Unpublished} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {
    Status_Enum,
    useOnlineInterviewData,
    useTssSetUploadedTimestamp,
    useTssVillageById,
    useUnsetTssUpdatedAt,
    useUserRoleInInterview
} from "../../lib";
import {prepareTssVillageDataSyncUp} from "../syncTssDb/prepareTssVillageDataSyncUp";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {PopupState} from "material-ui-popup-state/hooks";
import {useTssProjectRefetch} from "./useTssProjectRefetch";
import {
    useTssInterviewDetailUpdateMutation,
    useTssInterviewUpdateStateMutation,
    useUpdateInterviewMutation
} from "../../lib/gql/__codegen__/react-query";
import {useEffect} from "react";
import {useInterviewContext} from "../InterviewProvider";


type TssUploadInterviewCardProps = {
    popupState: PopupState | null
}

export function TssUploadInterviewCard({popupState}: TssUploadInterviewCardProps) {
    const {id: interviewId} = useInterviewContext()
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const onlineData = useOnlineInterviewData()
    const unset = useUnsetTssUpdatedAt()
    const setUpdatedAt = useTssSetUploadedTimestamp()
    const {mutateAsync: triggerUpload, isPending: isUploading, error} = useTssInterviewDetailUpdateMutation()
    const {
        mutateAsync: triggerUpdate,
        isPending: isStateUpdating,
        error: errorState
    } = useTssInterviewUpdateStateMutation()

    const {
        mutateAsync: triggerLockUpdate,
        isPending: isLockStateUpdating,
        error: errorInterview
    } = useUpdateInterviewMutation()
    const {execute} = useTssProjectRefetch({
        id: onlineData?.projectId || ''
    })

    // const [uploadInterview, {loading, error}] = useTssInterviewDetailUpdateMutation()
    const role = useUserRoleInInterview(interviewId)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(
        () => {
            if (error || errorState || errorInterview) {
                enqueueSnackbar('Error while uploading. Check console for errors.', {
                    variant: 'error'
                })
                console.error(error || errorState || errorInterview)
            }
        },
        [error, errorInterview, errorState, enqueueSnackbar]
    )

    const canLockState = role === 'teamleader' || role === 'supervisor'
    const isDone = onlineData?.is_done;
    const interviewStatus = onlineData?.interviewStatus

    return (
        <Alert icon={isDone ? <Lock/> : <CloudUpload/>} severity={'info'}>
            {isDone ? interviewStatus ? t('interviewIsLockedMessage') : t('interviewReviewMessage') : t('uploadInterview')}<br/>
            <Stack direction={'row'} spacing={2} marginTop={2}>
                {!isDone && !interviewStatus && (
                    <LoadingButton startIcon={<Publish/>}
                                   loading={isUploading}
                                   variant={'outlined'}
                                   onClick={async () => {
                                       const conf = window.confirm(t('confirmUpload'))
                                       if (conf && villageData) {
                                           const {
                                               projectInterviewInsertInput,
                                               projectInterviewBackup
                                           } = prepareTssVillageDataSyncUp(villageData)
                                           const res = await triggerUpload({
                                               id: interviewId,
                                               set: projectInterviewInsertInput,
                                               backup: projectInterviewBackup
                                           })
                                           if (res?.insert_project_interview_base_one) {
                                               unset(interviewId)
                                               setUpdatedAt(interviewId, res.insert_project_interview_base_one.created_at)
                                               if (onlineData.projectId) {
                                                   await execute()
                                               }
                                               popupState?.close()
                                           }
                                       }
                                   }}
                    >upload</LoadingButton>
                )}

                {onlineData?.id && (
                    <>
                        {!interviewStatus && (
                            <LoadingButton variant={'outlined'}
                                           loading={isStateUpdating}
                                           startIcon={isDone ? <Unpublished/> : <CheckCircle/>}
                                           color={isDone ? 'warning' : 'success'}
                                           onClick={async () => {
                                               const conf = window.confirm(t('confirmUpdateState'))
                                               if (conf) {
                                                   await triggerUpdate({id: onlineData.id, done: !onlineData?.is_done})
                                                   if (onlineData.projectId) {
                                                       await execute()
                                                   }
                                                   popupState?.close()
                                               }
                                           }}>
                                {isDone ? t('interviewStateUnset') : t('interviewStateSetDone')}
                            </LoadingButton>
                        )}
                        {canLockState && isDone && (
                            <LoadingButton variant={'outlined'}
                                           loading={isLockStateUpdating}
                                           startIcon={interviewStatus ? <LockReset/> : <Lock/>}
                                           color={interviewStatus ? 'warning' : 'success'}
                                           onClick={async () => {
                                               const conf = window.confirm(t('confirmUpdateState'))
                                               if (conf) {
                                                   const res = await triggerLockUpdate({
                                                       id: onlineData.interviewId, set: {
                                                           status: interviewStatus ? null : Status_Enum.Locked
                                                       }
                                                   })
                                                   if (res?.update_project_interview_by_pk?.id) {
                                                       if (onlineData.projectId) {
                                                           await execute()
                                                       }
                                                       popupState?.close()
                                                   }
                                               }
                                           }}>
                                {interviewStatus ? t('interviewStateUnlock') : t('interviewStateLock')}
                            </LoadingButton>
                        )}
                    </>
                )}

            </Stack>
        </Alert>
    )
}