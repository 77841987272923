import {Alert, Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {ProjectListTssQuery, useTssState} from "../../lib";
import {useTranslation} from "react-i18next";
import {useIsOnline} from "../../provider";
import {useProjectListTssQuery} from "../../lib/gql/__codegen__/react-query";
import {useUserId} from "@nhost/react";
import {LoadingScreen} from "../../theme/components/LoadingScreen";
import {Delete} from "@mui/icons-material";
import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";
import {stringToMb} from "../../lib/helpers/stringToMb";
import {useShallow} from "zustand/react/shallow";


type TssAdminListItemProps = {
    project: ProjectListTssQuery['project'][number]
    isAllowed: boolean
};

function TssAdminListItem({project, isAllowed}: TssAdminListItemProps) {
    const [interviews, resetProjectData] = useTssState(useShallow(state => [state.villages, state.resetProjectData]))
    const interviewIds = project.project_interviews.map(i => i.id);
    const currentInterviewList = interviews.filter(i => interviewIds.includes(i.id));
    const sizeInMb = stringToMb(JSON.stringify(currentInterviewList))
    return (
        <TableRow>
            <TableCell>
                {isAllowed ?
                    <MuiLink component={Link}
                             to={`/baseline?id=${project.id}`}>{project.name}</MuiLink> : <>{project.name}</>}
            </TableCell>
            <TableCell>
                {project.project_interviews?.map(i => i.location.country_region.country.en)
                    .filter((v, i, s) => s.indexOf(v) === i)
                    .join(', ')}
            </TableCell>
            <TableCell>
                {project.project_interviews?.map(i => i.location.zone.name)
                    .filter((v, i, s) => s.indexOf(v) === i)
                    .join(', ')}
            </TableCell>
            <TableCell>
                {isAllowed ? 'Yes' : 'No'}
            </TableCell>
            <TableCell>
                {project.project_interviews.length}
            </TableCell>
            <TableCell>
                {project.project_interviews.filter(i => i.project_interview_base?.id).length}
            </TableCell>
            <TableCell>
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <span>{currentInterviewList.length} {currentInterviewList.length ? `${sizeInMb}Mb` : ''}</span>
                </Stack>
            </TableCell>
            <TableCell>
                <IconButton size={'small'} color={'error'}
                            onClick={() => {
                                const confirm = window.confirm('Are you sure you want to delete the entire project data?')
                                if (confirm) {
                                    resetProjectData(project.id)
                                }
                            }}
                >
                    <Delete fontSize={'small'}/>
                </IconButton>
            </TableCell>

        </TableRow>
    )
}

export default function TssAdmin() {
    const {t} = useTranslation()
    const [resetState] = useTssState(useShallow(state => [state.resetState]))
    const [projects, interviews] = useTssState(useShallow(state => [state.projects, state.villages]))
    const userId = useUserId()
    const {isOnline} = useIsOnline()
    const {data, isLoading, isStale, error} = useProjectListTssQuery({
        where: {
            _or: [
                {
                    teamleader_user_id: {
                        _eq: userId
                    }
                },
                {
                    supervisor_user_id: {
                        _eq: userId
                    }
                },
                {
                    project_interviews: {
                        project_interview_researcher_mns: {
                            user_id: {
                                _eq: userId
                            }
                        }
                    }
                }
            ]
        }
    }, {
        enabled: !!userId
    })

    const currentOnlineProjectIds = data?.project.map(p => p.id) ?? []

    if (isLoading && isOnline) {
        return <LoadingScreen/>
    }

    return (
        <Stack spacing={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Country
                        </TableCell>
                        <TableCell>
                            Zone
                        </TableCell>
                        <TableCell>
                            Has permission
                        </TableCell>
                        <TableCell>
                            Total Interviews
                        </TableCell>
                        <TableCell>
                            Uploaded
                        </TableCell>
                        <TableCell>
                            Localstorage
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map((item) => (
                        <TssAdminListItem key={item.id} project={item}
                                          isAllowed={currentOnlineProjectIds.includes(item.id)}/>
                    ))}
                </TableBody>
            </Table>
            <Box marginTop={2}>
                <Alert severity={'warning'}>
                    {t('resetLocalStorageInfo')}<br/>
                    <Button color={'error'}
                            onClick={() => {
                                const confirm = window.confirm(t('resetLocalStorageMessage'))
                                if (confirm) {
                                    resetState()
                                }
                            }}>
                        {t('resetLocalStorage')}
                    </Button>
                </Alert>
            </Box>
        </Stack>
    )
}