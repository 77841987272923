import {ListFilterState} from "./fixtures";
import {useTranslation} from "react-i18next";
import {ListItemIcon, ListItemText, MenuItem, Select} from "@mui/material";
import {CheckCircleOutlined, CloudOff, CloudQueue, Lock} from "@mui/icons-material";

export function InterviewListFilter({onChange}: { onChange: (value: ListFilterState) => void }) {
    const {t} = useTranslation()
    return (
        <Select
            size={'small'}
            displayEmpty={true}
            defaultValue={''}
            sx={{
                minWidth: 120,
                '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiListItemIcon-root': {
                        minWidth: 32
                    }
                },

            }}
            onChange={event => {
                onChange(event.target.value as ListFilterState)
            }}>
            <MenuItem value={''}>
                {t('showAll')}
            </MenuItem>
            <MenuItem value={'01'}>
                <ListItemIcon>
                    <CloudOff/>
                </ListItemIcon>
                <ListItemText primary={t('notUploaded')}/>
            </MenuItem>
            <MenuItem value={'02'}>
                <ListItemIcon>
                    <CloudQueue/>
                </ListItemIcon>
                <ListItemText primary={t('interviewUploaded')}/>
            </MenuItem>
            <MenuItem value={'03'}>
                <ListItemIcon>
                    <CheckCircleOutlined/>
                </ListItemIcon>
                <ListItemText primary={t('interviewStateSetDone')}/>
            </MenuItem>
            <MenuItem value={'04'}>
                <ListItemIcon>
                    <Lock/>
                </ListItemIcon>
                <ListItemText primary={t('interviewStatus.LOCKED')}/>
            </MenuItem>
        </Select>
    )
}