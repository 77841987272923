import {Alert, Card, CardContent, Collapse, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ExchangedFoodTableForm} from "./partials/ExchangedFoodTableForm";
import {useWatch} from "react-hook-form";
import {LivestockFormData} from "../../../lib/state/tssTypes/tssLivestockTypes";
import {PurchaseSaleTableBase} from "../../../common/form/purchaseSaleForm";

function WrappedAlert() {
    const {t} = useTranslation()
    const values: LivestockFormData['exchanged_for_food'] = useWatch({
        name: 'exchanged_for_food'
    })
    const exists = values?.some(i => i.is_resold)
    return (
        <Collapse in={exists}>
            <Alert severity={'warning'} variant={'outlined'}>{t('resoldWarningMessage', {ns: 'tss'})}</Alert>
        </Collapse>
    )
}

export default function ExchangedGiftsForm() {
    const {t} = useTranslation()
    return (
        <div>
            <Typography variant={'h6'}>
                {t('exchangedForFood', {ns: 'tss'})}
            </Typography>
            <Card sx={{marginTop: 2}} variant={'outlined'}>
                <CardContent>
                    <ExchangedFoodTableForm name={'exchanged_for_food'}/>
                    <WrappedAlert/>
                </CardContent>
            </Card>

            <Typography marginTop={3} variant={'h6'}>
                {t('givenAway', {ns: 'tss'})}
            </Typography>
            <Card sx={{marginTop: 2}} variant={'outlined'}>
                <CardContent>
                    <PurchaseSaleTableBase name={'animals_given_away'} formStruct={[{
                        label: t('quantityGivenAway', {ns: 'tss'}),
                        type: 'number',
                        name: 'amount'
                    }, {
                        label: t('reason', {ns: 'tss'}),
                        type: 'text',
                        name: 'description'

                    }]}/>
                </CardContent>
            </Card>
        </div>
    )
}
