import {useWatch} from "react-hook-form";
import {CropSeasonData} from "../../../lib";
import CaloriesIncomeExpense from "../../shared/CaloriesIncomeExpense";
import {getCropsSumFields} from "../helpers/getCropsSumFields";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";

export function CropsDialogSum() {
    const values: CropSeasonData = useWatch<any>()

    const {
        income,
        calories,
        expenses
    } = getCropsSumFields({
        'id': values
    })
    const cropsUsed: string[] = []
    values.production?.forEach(seasonData => {
        let cropLabel = seasonData.term?.label;
        if (!cropsUsed.includes(cropLabel)) {
            cropsUsed.push(cropLabel)
        }
    })
    return (
        <>
            <TextFieldHiddenInput name={'harvested_crops'} value={cropsUsed.join(', ')}/>
            <TextFieldHiddenInput name={'total_expenses'} value={expenses}/>
            <TextFieldHiddenInput name={'total_cash_income'} value={income}/>
            <TextFieldHiddenInput name={'total_kcal'} value={calories}/>
            <CaloriesIncomeExpense
                income={income ? income : 0}
                calories={calories ? calories.toFixed(1) : 0}
                expenses={expenses ? expenses : 0}/>
        </>
    )
}