import {Control, useFieldArray} from 'react-hook-form'
import {Box, Button, IconButton, Stack} from '@mui/material'
import {Add, Delete} from '@mui/icons-material'
import {DataGridProProps} from '@mui/x-data-grid-pro'
import {useTranslation} from "react-i18next";
import {TermsSelector} from "./TermsSelector";
import {TextFieldNumberFormat} from "./TextFieldNumberFormat";

type KeyValueFormProps = {
    control?: Control<any>
    name: string
    label?: string
    filterCategories?: string[]
}

const GridColums: DataGridProProps['columns'] = [{
    field: 'key',
    headerName: 'Key',
    flex: 1
}, {
    field: 'value',
    headerName: 'Value'
}]

export function KeyValueForm({control, name, label, filterCategories = []}: KeyValueFormProps) {
    const {t} = useTranslation()
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name // unique name for your Field Array
    })

    return (
        <Box marginY={3}>
            <Stack direction={'row'} spacing={2} alignItems={'center'} marginBottom={2}>
                <Button startIcon={<Add/>} variant={'outlined'} onClick={() => {
                    append({key: '', value: ''})
                }}>
                    {t('add')} {label}
                </Button>
            </Stack>

            {fields.map((field, index) => (
                <Stack key={field.id} direction={'row'} spacing={2} marginBottom={2} alignItems={'flex-start'}>
                    <TermsSelector name={`${name}.${index}.key`} label={label}
                                   filterCategories={filterCategories}
                                   required
                                   autocompleteProps={{size: 'small', fullWidth: true}}/>

                    <TextFieldNumberFormat label={t('amount')} size={'small'}
                                           name={`${name}.${index}.value`}/>
                    <IconButton onClick={() => {
                        const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                        if (confirmed) {
                            remove(index)
                        }
                    }}>
                        <Delete/>
                    </IconButton>
                </Stack>
            ))}
        </Box>
    )
}
