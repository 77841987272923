import {FC, PropsWithChildren} from 'react'
import {Box, Typography} from '@mui/material'

const sizeMap = {
    sm: '90px',
    md: '110px',
    lg: '150px'
}
export type FormFieldWithLabelProps = { label: string, size?: 'sm' | 'md' | 'lg' };
export const FormFieldWithLabel: FC<PropsWithChildren<FormFieldWithLabelProps>> =
    ({children, label, size}) => {
        return (
            <Box marginY={2} maxWidth={size ? sizeMap[size] : undefined}>
                <Typography marginBottom={1} variant={'caption'} component={'p'}>
                    {label}
                </Typography>
                {children}
            </Box>
        )
    }

