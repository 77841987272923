import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getNumberFormat} from "../../lib/helpers/numberFormat";
import {useTotalSummaryTable} from "./useTotalSummaryTable";
import {useTranslation} from "react-i18next";

type TotalSummaryTableBtnContentProps = {
    interviewId: string
}

export function TotalSummaryTableBtnContent({interviewId}: TotalSummaryTableBtnContentProps) {
    const totalData = useTotalSummaryTable({interviewId})
    const {t} = useTranslation()
    return (
        <TableContainer sx={{maxHeight: '85vh', position: 'relative', padding: '24px'}}>
            <Table size={'small'} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align={'right'}>
                            #
                        </TableCell>
                        <TableCell>
                            {t('calories')}
                        </TableCell>
                        <TableCell>
                            {t('cashIncome')}
                        </TableCell>
                        <TableCell>
                            {t('expenditure')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {totalData.map((d, i) => d.isGroupHeader ? (
                        <TableRow key={`${d.name}-${i}`}>
                            <TableCell colSpan={4} sx={{fontWeight: 'bold'}}>
                                {d.name}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow key={`${d.name}-${i}`}>
                            <TableCell align={'right'}>{d.name}</TableCell>
                            <TableCell>{d.calories ? d.calories.toFixed(1) + '%' : '-'}</TableCell>
                            <TableCell>{d.income ? getNumberFormat(d.income) : '-'}</TableCell>
                            <TableCell>{d.expenditure ? getNumberFormat(d.expenditure) : '-'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}