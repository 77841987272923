import {useFieldArray} from "react-hook-form";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {SelfEmploymentFormRow} from "./SelfEmploymentFormRow";
import {Add, Delete} from "@mui/icons-material";
import {SelfEmploymentFormRowSum} from "./SelfEmploymentFormRowSum";
import {useTranslation} from "react-i18next";

type SelfEmploymentFormProps = {
    name: string
}

export function SelfEmploymentForm({name}: SelfEmploymentFormProps) {
    const {append, remove, fields} = useFieldArray({name})
    const {t} = useTranslation()
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>
                            {t('cashIncome')}
                        </TableCell>
                        <TableCell align={'right'}>
                            <IconButton onClick={() => {
                                append({})
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, iteration) => (
                        <TableRow key={field.id} selected={!!(iteration % 2)}>
                            <TableCell>
                                <SelfEmploymentFormRow
                                    name={`${name}.${iteration}`}
                                />
                            </TableCell>
                            <TableCell>
                                <SelfEmploymentFormRowSum name={`${name}.${iteration}`}/>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => {
                                    const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                                    if (confirmed) {
                                        remove(iteration)
                                    }
                                }}>
                                    <Delete/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}