import {Alert, Card, CardContent, Collapse, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {SwitchElement} from 'react-hook-form-mui'
import TotalVolumeDay from './TotalVolumeDay'

import {Livestock_Type_Enum, Measure_Unit_Type_Enum} from '../../../../lib'
import {useWatch} from "react-hook-form";
import {LivestockFormData} from "../../../../lib/state/tssTypes/tssLivestockTypes";
import {FormStack} from "../../../../common/form/FormStack";
import {TextFieldLabelInput} from "../../../../common/form/TextFieldLabelInput";
import {FormFieldWithLabel} from "../../../../common/form/FormFieldWithLabel";
import {MeasureSelector} from "../../../../common/form/MeasureSelector";
import {MonthSelectElement} from "../../../../common/form/MonthSelectElement";

type MilkingFormProps = {
    namePrefix: keyof Pick<LivestockFormData, 'milkProductionOffPeakSecondary' | 'milkProductionOffPeak' | 'milkProductionPeakSecondary' | 'milkProductionPeak'>
    livestockType: Livestock_Type_Enum
}

function SkimMilkAlert({namePrefix}: { namePrefix: string }) {
    const {t} = useTranslation()
    const isEnabled = useWatch({name: `${namePrefix}.consumes_skim`})
    return (
        <Collapse in={isEnabled}>
            <Alert
                sx={{
                    maxWidth: '250px'
                }} severity={'warning'}>{t('livestock.skimWarningMessage')}</Alert>
        </Collapse>
    )
}

export default function MilkingForm({namePrefix, livestockType}: MilkingFormProps) {
    const {t} = useTranslation()
    return (
        <Stack justifyContent={'column'} alignItems={'center'}>
            <TotalVolumeDay namePrefix={namePrefix} livestockType={livestockType}/>
            <Stack flexDirection={['column', 'row']} gap={'16px'} justifyContent={'center'} marginTop={'16px'}>
                <Stack>
                    <Typography variant={'h6'}>{t('production')}</Typography>
                    <Card sx={{
                        width: 'fit-content',
                    }} variant={'outlined'}>
                        <CardContent>
                            <FormStack direction={'row'}>
                                <TextFieldLabelInput label={t('livestock.milkedFemale', {ns: 'tss'})}
                                                     name={`${namePrefix}.milked_female`}/>
                                <TextFieldLabelInput label={t('livestock.daysMilked', {ns: 'tss'})}
                                                     name={`${namePrefix}.days_milked`}/>
                                <TextFieldLabelInput label={t('livestock.milkPerAnimal', {ns: 'tss'})}
                                                     name={`${namePrefix}.milk_per_animal`}/>
                                <FormFieldWithLabel label={t('livestock.unit', {ns: 'tss'})}>
                                    <MeasureSelector measureType={Measure_Unit_Type_Enum.Volume}
                                                     name={`${namePrefix}.measure_unit`} size={'small'}/>
                                </FormFieldWithLabel>
                                {(namePrefix === 'milkProductionPeak' || namePrefix === 'milkProductionPeakSecondary') && (
                                    <FormFieldWithLabel label={t('livestock.milkStartMonthPeak', {ns: 'tss'})}>
                                        <MonthSelectElement name={`${namePrefix}.peak_milk_month`} size={'small'}/>
                                    </FormFieldWithLabel>
                                )}
                            </FormStack>
                        </CardContent>
                    </Card>
                </Stack>

                <Stack>
                    <Typography variant={'h6'}>{t('usage')}</Typography>

                    <FormStack direction={'row'} alignItems={'stretch'}>
                        <Card variant={'outlined'}>
                            <CardContent>
                                <FormStack gap={0}>
                                    <TextFieldLabelInput label={t('livestock.consumedPerDay', {ns: 'tss'})}
                                                         name={`${namePrefix}.quantity_consumed_day`}/>
                                    <SwitchElement name={`${namePrefix}.consumes_skim`}
                                                   label={t('livestock.isSkim', {ns: 'tss'})}/>
                                    <SkimMilkAlert namePrefix={namePrefix}/>

                                </FormStack>
                            </CardContent>
                        </Card>
                        <Card variant={'outlined'}>
                            <CardContent>
                                <FormStack gap={0}>
                                    <TextFieldLabelInput isNumberFormat labelSize={'lg'}
                                                         label={t('livestock.soldPerDay', {ns: 'tss'})}
                                                         name={`${namePrefix}.sold_per_day`}/>
                                    <TextFieldLabelInput isNumberFormat labelSize={'lg'}
                                                         label={t('livestock.pricePerLitre', {ns: 'tss'})}
                                                         name={`${namePrefix}.price_per_litre`}/>
                                </FormStack>
                            </CardContent>
                        </Card>
                        <Card variant={'outlined'}>
                            <CardContent>
                                <FormStack gap={0}>
                                    <TextFieldLabelInput isNumberFormat labelSize={'lg'}
                                                         label={t('livestock.quantityGivenAway')}
                                                         name={`${namePrefix}.quantity_given_away`}/>
                                    <TextFieldLabelInput isNumberFormat labelSize={'lg'}
                                                         label={t('livestock.exchangedCereal')}
                                                         name={`${namePrefix}.exchanged_cereal`}/>
                                </FormStack>
                            </CardContent>
                        </Card>
                    </FormStack>
                </Stack>
            </Stack>
        </Stack>
    )
}

