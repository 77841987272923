import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useState} from "react";
import {Tab} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FishingWildFoodForm} from "./FishingWildFoodForm";
import {FishingWildFoodFormExpenditure} from "./FishingWildFoodFormExpenditure";

export function FishingWildFoodTabs() {
    const [tab, setTab] = useState<string>('1')
    const {t} = useTranslation()
    return (
        <TabContext value={tab}>
            <TabList
                sx={{marginBottom: 3}}
                variant={'fullWidth'}
                onChange={(_, value) => setTab(value)} aria-label="fishing lab">
                <Tab label={t('production')} value="1"/>
                <Tab label={t('expenditure')} value="2"/>
            </TabList>
            <TabPanel value={'1'}>
                <FishingWildFoodForm name={'fishing_wild_food'}/>
            </TabPanel>
            <TabPanel value={'2'}>
                <FishingWildFoodFormExpenditure name={'fishing_wild_food_expenses'}/>
            </TabPanel>
        </TabContext>
    )
}