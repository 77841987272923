import {useTranslation} from "react-i18next";
import {SwitchWithLabels} from "../../common/form/SwitchWithLabels";
import {TermsSelector} from "../../common/form/TermsSelector";
import {TextFieldElement} from "react-hook-form-mui";
import {TextFieldNumberFormat} from "../../common/form/TextFieldNumberFormat";
import {FormStack} from "../../common/form/FormStack";
import {MonthMultiSelectElement} from "../../common/form/MonthMultiSelectElement";

type RemittancesCreditFormTableRowProps = {
    name: string
}

export function RemittancesCreditFormTableRow({name}: RemittancesCreditFormTableRowProps) {
    const {t} = useTranslation()
    return (
        <>
            <FormStack direction={'row'}>
                <SwitchWithLabels name={`${name}.is_expenditure`} leftLabel={t('cashIncome')}
                                  rightLabel={t('expenditure')}/>
                <TermsSelector name={`${name}.term`} label={t('type')}
                               autocompleteProps={{size: 'small'}}
                               rules={{required: t('required')}}
                               filterCategories={['3a057993-3cd5-41b8-990a-fabab4dff4b9']}
                />
                <TextFieldElement name={`${name}.description`} label={t('description')} size={'small'}/>
                <TextFieldNumberFormat name={`${name}.amount`} label={t('amountPerMonth')} type={'number'}
                                       size={'small'}/>
                <MonthMultiSelectElement name={`${name}.months`} size={'small'} label={t('months')}/>
            </FormStack>
        </>
    )
}