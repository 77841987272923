import {useWatch} from "react-hook-form";
import {durationCalculator} from "../../../lib/helpers/durationCalculator";
import {SelfEmploymentData} from "../../../lib";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";

type SelfEmploymentFormRowSumProps = {
    name: string
}

export function SelfEmploymentFormRowSum({name}: SelfEmploymentFormRowSumProps) {
    const values: SelfEmploymentData = useWatch({name})
    const {frequency} = durationCalculator({duration: values.duration})
    const sum = Number(values.profit_per_unit) * Number(values.quantity) * Number(values.profit_by_person || 1) * frequency || 0

    return (
        <>
            <TextFieldHiddenInput name={`${name}.total_cash_income`} value={sum}/>
            <span>{getNumberFormat(sum)}</span>
        </>
    )
}