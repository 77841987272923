import {Table, TableBody, TableCell, TableContainer, TableProps, TableRow} from '@mui/material'
import {useTranslation} from "react-i18next";

type LivestockReferenceTableProps = {
    tableProps?: TableProps
}


export function LivestockReferenceTable({tableProps}: LivestockReferenceTableProps) {
    const {t} = useTranslation()
    const bodyRows = [
        ['', t('poor'), t('middle'), t('betterOff'), t('poor'), t('middle'), t('betterOff'), t('poor'), t('middle'), t('betterOff'), t('poor'), t('middle'), t('betterOff')],
        [t('totalStartHerd'), 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [t('livestock.breedingFemale'), 47, 38, 32, 41, 41, 41, 62, 54, 54, 55, 55, 55],
        [t('livestock.newBirth'), 33, 27, 23, 33, 29, 29, 27, 24, 24, 66, 66, 66],
        [t('salesSlaughterBarter'), 31, 32, 11, 31, 16, 16, 23, 11, 11, 63, 31, 31],
        [t('livestock.lossesDeath'), 9, 8, 7, 9, 8, 8, 9, 8, 8, 24, 24, 24],
        [t('purchaseGifts'), 7, 13, 0, 7, 0, 0, 5, 0, 0, 22, 0, 0],
        [t('totalEndYear'), 100, 100, 105, 100, 105, 105, 100, 105, 105, 100, 110, 110]
    ]
    return (
        <TableContainer>
            <Table {...tableProps} sx={{border: '2px solid #ccc'}}>
                <TableBody>
                    <TableRow sx={{
                        '& .MuiTableCell-root': {
                            backgroundColor: '#eee',
                            borderRight: '3px solid #ccc',
                            fontWeight: 'bold'
                        }
                    }}>
                        <TableCell align={'right'}>
                        </TableCell>
                        <TableCell colSpan={3} align={'center'}>
                            {t('livestock.type.cattle')} ({t('herdsPloughOxen')})
                        </TableCell>
                        <TableCell colSpan={3} align={'center'}>
                            {t('livestock.type.cattle')} ({t('herdsWithoutPloughOxen')})
                        </TableCell>
                        <TableCell colSpan={3} align={'center'}>
                            {t('livestock.type.camel')}
                        </TableCell>
                        <TableCell colSpan={3} align={'center'}>
                            {t('livestock.type.goat')}/{t('livestock.type.sheep')}
                        </TableCell>
                    </TableRow>
                    {bodyRows.map((columns, i) => (
                            <TableRow key={i}>
                                {columns.map((col, j) => (
                                    <TableCell key={j} sx={{
                                        borderLeft: j === 0 ? '1px solid #ccc' : 0,
                                        borderRight: [0, 3, 6, 9, 12].includes(j) ? `3px solid #ccc` : `1px solid #ccc`,
                                        fontWeight: (j === 0 || i === 0) ? 'bold' : 'normal'
                                    }} align={j === 0 ? 'right' : 'center'}>
                                        {col}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
