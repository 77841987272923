import {TssLayout} from "../../../theme/layouts/tss";
import TssAdmin from "../../../tss/admin/TssAdmin";
import {PageRoot} from "../../../common/layout/PageRoot";

export default function Admin() {
    return (
        <PageRoot title={'Setup'}>
            <TssLayout title={'Setup'}>
                <TssAdmin/>
            </TssLayout>
        </PageRoot>
    )
}