import {useTranslation} from "react-i18next";
import {PurchaseSaleTableBase} from "../../../common/form/purchaseSaleForm";

type CropsFormExpendituresProps = {
    name: string
}

export function CropsFormExpenditures({name}: CropsFormExpendituresProps) {
    const {t} = useTranslation()
    return (
        <PurchaseSaleTableBase name={name}
                               formStruct={
                                   [{
                                       type: 'terms',
                                       name: 'term',
                                       label: t('item'),
                                       termsFilterCatgories: [
                                           'e5a557a7-a634-437b-b7cb-d784d205b459',
                                           '59152174-75ba-4ea5-ab57-3449a1763fc7',
                                           '6d09a455-17ad-4587-bf96-f1c1ae10c043',
                                           '9d136d10-561c-45d6-bc33-b8d773c6943e'
                                       ]
                                   }, {
                                       label: t('units'),
                                       type: 'number',
                                       name: 'amount'
                                   }, {
                                       label: t('priceUnit'),
                                       type: 'number',
                                       name: 'price'
                                   }, {
                                       type: 'months',
                                       name: 'months',
                                       label: t('monthsG')
                                   }, {
                                       type: "multiply",
                                       name: 'total_expenses',
                                       label: t('expenditure'),
                                       multiply: {
                                           fields: ['amount', 'price']
                                       }
                                   }]
                               }/>
    )
}