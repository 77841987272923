import {NhostProvider} from "@nhost/react";
import {appNhostClient} from "../lib";
import {AppOfflineUserIdProvider} from "../common/layout/AppOfflineUserIdProvider";
import {NhostSimpleReactQueryProvider} from "../lib/nhost/NhostSimpleReactQueryProvider";
import AllTssVariableSetup from "../provider/AllTssVariableSetup";
import {AppSetupContainer} from "../common/layout/AppSetupContainer";
import {Outlet} from "react-router-dom";

export function ViteTssGlobalProvider() {
    // todo ContainerAuth needs to be handled differently
    // todo handle AllTssVariableSetup on per page basis?
    console.log("render Vite Provider")
    return (
        <NhostProvider nhost={appNhostClient}>
            <AppOfflineUserIdProvider>
                <NhostSimpleReactQueryProvider>
                    <AppSetupContainer>
                        <AllTssVariableSetup>
                            <Outlet/>
                        </AllTssVariableSetup>
                    </AppSetupContainer>
                </NhostSimpleReactQueryProvider>
            </AppOfflineUserIdProvider>
        </NhostProvider>
    )
}