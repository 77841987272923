import {PopperContainer} from "../../common/popper/PopperContainer";
import {CloudSync, SyncProblem} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {DialogContent} from "@mui/material";
import {HeaderInterviewSyncContent} from "./HeaderInterviewSyncContent";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {useTssUpdatedAt} from "../../lib";
import {useInterviewContext} from "../InterviewProvider";


export default function HeaderInterviewSyncDialog() {
    const {id: interviewId} = useInterviewContext()
    const {t} = useTranslation()
    const ref = useRef<PopupState>(null)
    const updatedAtDate = useTssUpdatedAt(interviewId)
    return (
        <PopperContainer
            ref={ref}
            dialogTitle={t('interviewSynchronisationHeader')}
            dialogProps={{}}
            trigger={{
                color: 'inherit',
                sx: {
                    marginRight: 2
                },
                startIcon: updatedAtDate ? <SyncProblem/> : <CloudSync/>,
                children: t('sync')
            }}>
            <DialogContent>
                <HeaderInterviewSyncContent popupState={ref.current}/>
            </DialogContent>
        </PopperContainer>
    )
}

