import {IconButton, Stack} from "@mui/material";
import {Measure_Unit_Type_Enum} from "../../../lib";
import {useFieldArray} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Add, Delete} from "@mui/icons-material";
import {TermsSelector} from "../../../common/form/TermsSelector";
import {useEffectOnce} from "usehooks-ts";
import {TextFieldNumberFormat} from "../../../common/form/TextFieldNumberFormat";
import {MeasureSelector} from "../../../common/form/MeasureSelector";

type CropsOtherUsesFormProps = {
    name: string
}

export function CropsOtherUsesForm({name}: CropsOtherUsesFormProps) {
    const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
        // control, // control props comes from useForm (optional: if you are using FormContext)
        name // unique name for your Field Array
    })
    const {t} = useTranslation()
    useEffectOnce(
        () => {
            if (fields?.length === 0) {
                append({})
            }
        }
    )
    return (
        <Stack spacing={1} borderRight="1px solid #eee">
            {fields.map((props, index) => (
                <Stack direction={'row'} key={props.id} spacing={1}>
                    <TermsSelector name={`${name}.${index}.term`}
                                   label={t('type')}
                                   filterCategories={['13a44b47-be6f-40f8-b630-1d78cb0ca0fe']}
                                   autocompleteProps={{
                                       size: 'small',
                                       sx: {
                                           minWidth: '160px'
                                       }
                                   }}/>
                    <MeasureSelector name={`${name}.${index}.given_away_unit`}
                                     size={'small'}
                                     label={t('unit')}
                                     measureType={Measure_Unit_Type_Enum.Mass}/>
                    <TextFieldNumberFormat name={`${name}.${index}.given_away`}
                                           size={'small'}
                                           type={'number'}
                                           label={t('units')}/>
                    {index > 0 && (
                        <div>
                            <IconButton size={'small'}
                                        onClick={() => {
                                            const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                                            if (confirmed) {
                                                remove(index)
                                            }
                                        }}><Delete/></IconButton>
                        </div>
                    )}
                    {fields.length - 1 === index && (
                        <div>
                            <IconButton color={'primary'} size={'small'} onClick={() => append({})}><Add/></IconButton>
                        </div>
                    )}
                </Stack>
            ))}

        </Stack>
    )
}