import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {arrayFieldSum, FoodAidData} from "../../lib";

export function FoodAidDialogSum() {
    const values: FoodAidData[] = useWatch({name: 'food_aid'})
    const kcal = arrayFieldSum(values, 'total_kcal')
    const income = arrayFieldSum(values, 'total_cash_income')
    return (
        <CaloriesIncomeExpense calories={kcal ? kcal.toFixed(1) : 0} income={income}/>
    )
}