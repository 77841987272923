import {Livestock_Type_Enum, TssTotalCalculated, TssVillageData} from "../../lib";

export const livestockCalculateTotals = (livestock: TssVillageData['livestock']): Partial<TssTotalCalculated> => {
    const totals = {
        calories_livestock: 0,
        cash_income_livestock: 0,
        cash_expenses_livestock: 0
    }
    Object.keys(livestock).forEach(key => {
        const data = livestock[key as Livestock_Type_Enum]
        totals.cash_income_livestock += data.total_cash_income
        totals.cash_expenses_livestock += data.total_cash_expenses
        totals.calories_livestock += data.total_calories
    })
    return totals
}