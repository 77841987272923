import {AddComment, Clear, Send} from "@mui/icons-material";
import {Button, Stack} from "@mui/material";
import {useUserId} from "@nhost/react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {LmTipTapRichtextRhf} from "../../common/form/richText/TipTapRhf";
import uuidv4 from "../../theme/utils/uuidv4";
import {
    useTssNotesCurrentMessage,
    useTssNotesInterviewId,
    useTssNotesSetComment,
    useTssNotesSetCurrentMessage
} from "./TssNotesProvider";
import {TssNoteFragment} from "../../lib/gql/__codegen__/react-query";

function TssNotesRichtext() {
    const {t} = useTranslation()
    const currentMessage = useTssNotesCurrentMessage()
    const interviewId = useTssNotesInterviewId()
    const setComment = useTssNotesSetComment()
    const setCurrentMessage = useTssNotesSetCurrentMessage()
    const userId = useUserId()
    const {handleSubmit, control, resetField} = useForm({
        defaultValues: {
            message: currentMessage?.message ?? ''
        }
    })

    return (
        <form onSubmit={handleSubmit(data => {
            if (data.message) {
                const form = {
                    ...currentMessage,
                    message: data.message,
                    user_id: userId,
                    interview_id: interviewId,
                    ...(currentMessage?.id ? {
                        updated_at: new Date().toISOString()
                    } : {
                        id: uuidv4(),
                        created_at: new Date().toISOString()
                    })
                } as TssNoteFragment
                setComment(form)
                setCurrentMessage(null)
            }
        })}>
            <Stack spacing={2}>
                <LmTipTapRichtextRhf name={'message'} control={control} rules={{required: true}}/>
                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                    <Button type={'button'} variant={'outlined'} onClick={() => setCurrentMessage(null)}
                            color={'inherit'}
                            startIcon={<Clear/>}>{t('cancel')}</Button>
                    <Button type={'submit'} variant={'contained'} startIcon={<Send/>}>{t('submit')}</Button>
                </Stack>
            </Stack>
        </form>
    )
}

export function TssNotesContentCreate() {
    const currentMessage = useTssNotesCurrentMessage()
    // const list = useTssNotesList()
    const setMessage = useTssNotesSetCurrentMessage()
    if (!currentMessage) {
        return (
            <Button type={'submit'} fullWidth startIcon={<AddComment/>} variant={'outlined'}
                    onClick={() => setMessage({} as TssNoteFragment)}>Add Comment</Button>

        )
    }
    return <TssNotesRichtext/>
}
