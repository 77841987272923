import {Month_Enum, TssVillageData} from "../../lib";
import {MonthlyBreakdownData} from "../../lib/state/tssTypes/monthlyBreakdownDataTypes";

export function calculateMonthlyBreakdownCrops(data: TssVillageData['crops'], months: {
    [K in Month_Enum]: MonthlyBreakdownData
}, monthsOfPeriod: Month_Enum[]) {
    if (!data) {
        return
    }
    const referencedMonths = monthsOfPeriod.length
    Object.keys(data).forEach(id => {
        const currentData = data[id]
        // kcal root crops
        const calcRootCropsKcal = (m: Month_Enum) => {
            months[m].kcal.crops.root += Number(currentData.root_crops?.total_kcal) / (currentData.root_crops?.months?.length || referencedMonths)
        }
        if (currentData.root_crops?.months?.length) {
            currentData.root_crops.months.forEach(calcRootCropsKcal)
        } else {
            monthsOfPeriod.forEach(calcRootCropsKcal)
        }

        // kcal green crops
        const calcGreenCropsKcal = (m: Month_Enum) => {
            months[m].kcal.crops.green += Number(currentData.green_crops?.total_kcal) / (currentData.green_crops?.months?.length || referencedMonths)
        }
        if (currentData.green_crops?.months?.length) {
            currentData.green_crops.months.forEach(calcGreenCropsKcal)
        } else {
            monthsOfPeriod.forEach(calcGreenCropsKcal)
        }

        // iterate the crops data table
        currentData.production?.forEach(cropData => {
            // dry harvesting
            const calcCropHarvesting = (m: Month_Enum) => {
                months[m].kcal.crops.dryHarvested += Number(cropData.total_kcal) / (cropData.months_consumption?.length || referencedMonths)
            }
            if (cropData.months_consumption?.length) {
                cropData.months_consumption.forEach(calcCropHarvesting)
            } else {
                monthsOfPeriod.forEach(calcCropHarvesting)
            }
            // income
            const calcCropIncome = (m: Month_Enum) => {
                months[m].income.crops += Number(cropData.total_cash_income) / (cropData.when_sold?.length || referencedMonths)
            }
            if (cropData.when_sold?.length) {
                cropData.when_sold.forEach(calcCropIncome)
            } else {
                monthsOfPeriod.forEach(calcCropIncome)
            }
        })

        // iterate crops expenditure
        currentData?.expenditures?.forEach(cropData => {
            const calcExpenditure = (m: Month_Enum) => {
                months[m].expenditure.crops += Number(cropData.total_expenses) / (cropData.months?.length || referencedMonths)
            }
            if (cropData.months?.length) {
                cropData.months.forEach(calcExpenditure)
            } else {
                monthsOfPeriod.forEach(calcExpenditure)
            }
        })
    })
}