import {useWatch} from "react-hook-form";
import {useProportionalKcalById} from "../../../lib";
import {useInterviewContext} from "../../InterviewProvider";
import {useTranslation} from "react-i18next";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import CaloriesIncomeExpense from "../../shared/CaloriesIncomeExpense";

type GreenCropsConsumeSumProps = {
    name: string
}

export function GreenCropsContinuousConsumeSum({name}: GreenCropsConsumeSumProps) {
    const values = useWatch({name})
    const {t} = useTranslation()
    const {id} = useInterviewContext()
    const calculate = useProportionalKcalById(id)
    let sum = 0
    if (values.weeks && values.people) {
        sum = calculate({
            householdMember: Number(values.people),
            numberWeeks: Number(values.weeks),
            isSnack: values.is_snack,
            mealsDay: Number(values.meals_day || 0),
            timesPerWeek: Number(values.days_per_week || 7)
        })
    }
    return (
        <>
            <TextFieldHiddenInput name={`${name}.total_kcal`} value={sum}/>
            <CaloriesIncomeExpense calories={sum?.toFixed(1)}/>
        </>
    )
}