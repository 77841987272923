import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {arrayFieldSum, EmploymentData} from "../../lib";

type EmploymentDialogSumProps = {
    name: string
}

export function EmploymentDialogSum({name}: EmploymentDialogSumProps) {
    const values: EmploymentData[] = useWatch({name})
    const kcal = arrayFieldSum(values || [], 'total_kcal')
    const income = arrayFieldSum(values || [], 'total_cash_income')
    return (
        <CaloriesIncomeExpense calories={kcal ? kcal.toFixed(1) : 0} income={income}/>
    )
}