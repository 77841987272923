import {PopperContainer} from "../../common/popper/PopperContainer";
import {Dataset} from "@mui/icons-material";
import {TotalSummaryTableBtnContent} from "./TotalSummaryTableBtnContent";

type TotalSummaryTableProps = {
    interviewId: string
}

export function TotalSummaryTableBtn({interviewId}: TotalSummaryTableProps) {

    return (
        <PopperContainer variant={'dialog'}
                         dialogTitle={"  "}
                         triggerIcon={{
                             color: 'inherit',
                             children: <Dataset/>
                         }}>
            <TotalSummaryTableBtnContent interviewId={interviewId}/>
        </PopperContainer>
    )
}