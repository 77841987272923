import {useTssNotesUpdateMutation} from "../../lib/gql/__codegen__/react-query";
import {LoadingButton} from "@mui/lab";
import {useTssNotesList, useTssNotesUpdatedAt, useTssNoteUnsetUpdatedAt} from "./TssNotesProvider";
import {Project_Interview_Note_Insert_Input} from "../../lib";
import {CloudUpload} from "@mui/icons-material";
import {useIsOnline} from "../../provider";
import {useTranslation} from "react-i18next";


export function TssNotesSync() {
    const {mutateAsync, isPending} = useTssNotesUpdateMutation()
    const {isOnline} = useIsOnline()
    const unset = useTssNoteUnsetUpdatedAt()
    const {t} = useTranslation()
    const list = useTssNotesList({showAll: true})
    const updatedAt = useTssNotesUpdatedAt()
    const preparedList: Project_Interview_Note_Insert_Input[] = list.map(item => ({
        id: item.id,
        interview_id: item.interview_id,
        message: item.message,
        is_done: item.is_done,
        created_at: item.created_at,
        updated_at: item.updated_at,
        user_id: item.user_id,
        is_deleted: item.is_deleted
    }))

    if (!isOnline || !updatedAt) {
        return null
    }
    return (
        <LoadingButton loading={isPending}
                       startIcon={<CloudUpload/>}
                       variant={'outlined'}
                       onClick={async () => {
                           await mutateAsync({
                               objects: preparedList
                           })
                           unset()
                       }}
        >{t('upload')}</LoadingButton>
    )
}