import {AutocompleteElement, AutocompleteElementProps} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import {FieldValues} from 'react-hook-form'
import {useGetTerms, useGetTermsSimple} from "../../lib/state/allVariablesState";
import {Autocomplete, AutocompleteProps, TextField} from "@mui/material";

type TermsSelectorProps<T extends FieldValues> = Omit<AutocompleteElementProps<T, any, any, any>, 'options'> & {
    filterCategories?: string[]
}

export function TermsSelector<TFieldValues extends FieldValues>({
                                                                    name,
                                                                    multiple,
                                                                    filterCategories,
                                                                    ...rest
                                                                }: TermsSelectorProps<TFieldValues>) {
    const {i18n} = useTranslation()
    const locale = i18n.language || 'en'
    const getTerms = useGetTerms()
    const opts = getTerms(filterCategories, locale);


    return (
        <AutocompleteElement
            {...rest}
            name={name}
            textFieldProps={{
                sx: {
                    minWidth: '250px'
                }
            }}
            options={opts}/>
    )
}

type TermsSelectorSimpleProps = Pick<AutocompleteProps<any, any, any, any>, 'defaultValue' | 'onChange'> & {}

export function TermsSelectorSimple({...rest}: TermsSelectorSimpleProps) {
    const {i18n} = useTranslation()
    const locale = i18n.language || 'en'
    const terms = useGetTermsSimple()
    const opts = terms.map(t => ({
            ...t, id: t.id,
            // @ts-ignore
            label: t[locale as any]
        })
    )
    // const opts = getTerms([], locale);
    return (
        <Autocomplete
            {...rest}
            sx={{
                minWidth: '300px'
            }}
            renderInput={(params) => (
                <TextField {...params} fullWidth size={'small'}/>
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            options={opts}
        />
    )
}
