import {lighten} from '@mui/material/styles'


export const chartColors = {
    var1: '#9e9d24',
    var2: '#689f38',
    var3: '#81c784',
    var4: '#c0ca33',
    var5: '#4dd0e1',
    var6: '#0097a7',
    var7: '#03a9f4',
    var8: '#0277bd',
    var9: '#d32f2f',
    var10: '#ef5350',
    var11: '#e57373',
    var12: '#ffd600',
    var13: '#ffa000',
    var14: '#ef6c00',
    var15: '#d81b60',
    var16: '#f06292',
    var17: '#f8bbd0',
    var18: '#90a4ae',
    var19: '#607d8b',
    var20: '#80cbc4',
    var21: '#009688',
    var22: '#00838f',
    var23: '#455a64',
}


type ChartColorHelper = {
    color: keyof typeof chartColors,
    coefficient?: number
}

export function chartColorHelper({color, coefficient = 0}: ChartColorHelper) {
    return lighten(chartColors[color], coefficient)
}