import {FileUploadMenu} from "../../common/form/fileUpload/FileUpload";
import {useIsOnline} from "../../provider";
import {useInsertProjectInterviewFileMutation, useInterviewFilesQuery} from "../../lib/gql/__codegen__/react-query";
import {FileListItem} from "../../common/form/fileUpload/fileUploadTypes";

export function InterviewOverviewFiles({interviewId}: { interviewId: string }) {
    const {isOnline} = useIsOnline()
    const {mutateAsync, isPending, error} = useInsertProjectInterviewFileMutation()
    const {data, isFetching, refetch} = useInterviewFilesQuery({interviewId}, {
        enabled: isOnline
    })

    if (!isOnline) {
        return null
    }
    const fileList: FileListItem[] = data?.project_interview_file?.map(i => ({
        primaryText: i.file?.name || 'File name missing',
        secondaryText: ((i.file?.size || 0) / 1048576).toFixed(1) + ' MB',
        file: i.file
    })) ?? [];
    return (
        <>
            <FileUploadMenu
                fileList={fileList}
                isLoading={isPending}
                refetchFileQuery={refetch}
                afterUpload={async (files) => {
                    let res
                    try {
                        if (Array.isArray(files) && files.length > 0) {
                            res = await mutateAsync({
                                objects: files.map(i => ({
                                    interview_id: interviewId,
                                    file_id: i
                                }))
                            })
                        } else if (typeof files === 'string') {
                            res = await mutateAsync({
                                objects: [{
                                    interview_id: interviewId,
                                    file_id: files
                                }]
                            })
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }}
                fileInputProps={{
                    multiple: true
                }}/>
        </>
    )
}
