import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Add} from '@mui/icons-material'
import {CropsFormTableRow} from './CropsFormTableRow'
import {useFieldArray} from 'react-hook-form'

type CropsFormTableProps = {
    seasonId: string
    name: string
}

export function CropsFormTable({seasonId, name}: CropsFormTableProps) {
    const {t} = useTranslation()
    const {fields, remove, append} = useFieldArray({
        name
    })
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            {t('crops.name')}
                        </TableCell>
                        <TableCell>
                            {t('crops.produced')}
                        </TableCell>
                        <TableCell>
                            {t('crops.sold')}
                        </TableCell>
                        <TableCell>
                            {t('crops.otherUses')}
                        </TableCell>
                        <TableCell>
                            {t('crops.consumed')}
                        </TableCell>

                        <TableCell align={'right'}>
                            <IconButton onClick={() => {
                                append({season_id: seasonId})
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields
                        .map((value, index) => (
                            <CropsFormTableRow
                                key={value.id}
                                name={name}
                                iteration={index}
                                remove={remove}/>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
