import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import {Livestock_Type_Enum, TssVillageData, useSetVillageData, useTssVillageById} from '../../lib'
import {useTranslation} from 'react-i18next'
import AddLivestockButton from './AddLivestockButton'
import {ArrowRight, Delete, InfoOutlined} from '@mui/icons-material'
import LivestockFormStepperDialog from './LivestockFormStepperDialog'
import {useState} from 'react'
import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {round} from "../../lib/helpers/round";
import {LivestockOverviewRowType} from "./livestockTypes";
import {PopperContainer} from "../../common/popper/PopperContainer";
import {getNumberFormat} from "../../lib/helpers/numberFormat";
import {livestockCalculateTotals} from "./livestockCalculateTotals";
import {ConfirmButton} from "../../common/buttons";

type LivestockOverviewProps = {
    interviewId: string
}

export function LivestockOverview({interviewId}: LivestockOverviewProps) {
    const data = useTssVillageById(interviewId)
    const livestockData = data?.livestock
    const totals = data?.total
    const [selectedLivestock, setSelectedLivestock] = useState<Livestock_Type_Enum | null>(null)
    const setVillageData = useSetVillageData()

    const {t} = useTranslation()

    const preparedData: LivestockOverviewRowType[] = livestockData ? Object.keys(livestockData).map((livestockKey) => {
        // todo move this into the overview itself and save in db 
        const livestock = livestockData[livestockKey as Livestock_Type_Enum]

        const events = [{
            label: t('livestock.born'),
            value: livestock.new_birth,
        }, {
            label: t('purchased'),
            value: livestock.total_purchases
        }, {
            label: t('sold'),
            value: livestock.total_sold,
            isReduced: true
        }, {
            label: t('livestock.slaughtered'),
            value: livestock.slaughtered,
            isReduced: true
        }, {
            label: t('exchanged'),
            value: livestock.total_exchanged,
            isReduced: true
        }, {
            label: t('givenAway'),
            value: livestock.total_given_away,
            isReduced: true
        }, {
            label: t('livestock.losses'),
            value: livestock.losses_death,
            isReduced: true
        }]
        return {
            type: livestock.type,
            totalCalories: livestock.total_calories,
            totalIncome: livestock.total_cash_income,
            totalExpenses: livestock.total_cash_expenses,
            start: (
                <>
                    {livestock.total_start} <br/>
                    {livestock.breeding_female} {t('livestock.breedingFemale')}
                </>
            ),
            events: (
                <TableContainer>
                    <Table size={'small'}>
                        <TableBody>
                            {events.map(ev => (
                                <TableRow key={ev.label}>
                                    <TableCell>
                                        {ev.label}
                                    </TableCell>
                                    <TableCell sx={{color: ev.isReduced ? 'red' : 'green'}}>
                                        {ev.value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ),
            end: (
                <>{livestock.total_end}</>
            )
        }
    }) : []

    return (

        <Stack>

            <TableContainer>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'}>
                                <Typography variant={'h5'}>{t('livestockLabel')}</Typography>
                            </TableCell>
                            <TableCell align={'center'}>
                                <CaloriesIncomeExpense small calories={totals?.calories_livestock?.toFixed(1)}/>
                            </TableCell>
                            <TableCell align={'center'}>
                                <CaloriesIncomeExpense small income={totals?.cash_income_livestock}/>
                            </TableCell>
                            <TableCell align={'center'}>
                                <CaloriesIncomeExpense small expenses={totals?.cash_expenses_livestock}/>
                            </TableCell>
                            <TableCell align={'right'}>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {preparedData.map((livestock) => (
                            <TableRow key={livestock.type}>
                                <TableCell>
                                    <Button size={'small'} color={'primary'}
                                            startIcon={<ArrowRight/>}
                                            onClick={() => setSelectedLivestock(livestock.type)}>
                                        {t(`livestock.type.${livestock.type?.toLowerCase() ?? 'cattle'}`)}
                                    </Button>
                                </TableCell>
                                <TableCell align={'center'}>
                                    {round(livestock.totalCalories, 1)}%
                                </TableCell>
                                <TableCell align={'center'}>
                                    {getNumberFormat(livestock.totalIncome)}
                                </TableCell>
                                <TableCell align={'center'}>
                                    {getNumberFormat(livestock.totalExpenses)}
                                </TableCell>
                                <TableCell align={'right'}>
                                    <PopperContainer
                                        variant={'popover'}
                                        triggerIcon={{
                                            size: 'small',
                                            children: <InfoOutlined/>
                                        }}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component={'th'} sx={{backgroundColor: 'grey.100'}}>
                                                        <strong>
                                                            {t('startReferenceYear')}
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell align={'center'} sx={{backgroundColor: 'grey.100'}}>
                                                        <strong>
                                                            {livestock.start}
                                                        </strong>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component={'th'}>
                                                        {t('eventsDuringReferenceYear')}
                                                    </TableCell>
                                                    <TableCell
                                                        align={'center'}>{livestock.events}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component={'th'} sx={{backgroundColor: 'grey.100'}}>
                                                        <strong>
                                                            {t('endReferenceYear')}
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell
                                                        align={'center'} sx={{backgroundColor: 'grey.100'}}>
                                                        <strong>
                                                            {livestock.end}
                                                        </strong>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </PopperContainer>
                                    <ConfirmButton
                                        iconProps={{
                                            size: 'small',
                                        }}
                                        message={t('removeLivestockConfirm')}
                                        onConfirm={() => {
                                            const updatedData = {
                                                livestock: {
                                                    ...livestockData
                                                }
                                            } as TssVillageData;
                                            delete updatedData.livestock[livestock.type]
                                            const totals = {
                                                total: {
                                                    ...data?.total,
                                                    ...livestockCalculateTotals(updatedData.livestock)
                                                } as TssVillageData['total'],
                                            }
                                            setVillageData(interviewId, {...updatedData, ...totals})
                                        }}>
                                        <Delete fontSize={'small'}/>
                                    </ConfirmButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box marginTop={2}>
                <AddLivestockButton villageId={interviewId} used={preparedData?.map(i => i.type)}/>
            </Box>

            {selectedLivestock && (
                <LivestockFormStepperDialog
                    initialData={livestockData?.[selectedLivestock]}
                    selectedLivestock={selectedLivestock}
                    onClose={() => setSelectedLivestock(null)}
                    villageId={interviewId}/>
            )}
        </Stack>
    )
}
