import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Stack,
    Tab,
    Toolbar,
    Typography
} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Close} from "@mui/icons-material";
import {FormContainer} from "react-hook-form-mui";
import {CropsFormTable} from "./CropsFormTable";
import {CropsFormExpenditures} from "./CropsFormExpenditures";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {CropSeason} from "../cropTypes";
import {TssVillageData} from "../../../lib";
import GreenCropsContinuousConsume from "./GreenCropsContinuousConsume";
import {CropsDialogSum} from "./CropsDialogSum";
import {cleanMissingTermIds} from "../../syncTssDb/cleanMissingTermIds";

export type CropsDialogFormProps = {
    activeSeason: CropSeason,
    setActiveSeason: (activeSeason: CropSeason | null) => void
    formId: string
    initialData?: TssVillageData['crops']
    onSuccess: (seasonId: string, data: any) => Promise<void> | void
}

export function CropsDialogForm({activeSeason, setActiveSeason, formId, initialData, onSuccess}: CropsDialogFormProps) {
    const [tab, setTab] = useState<string>('1')
    const {t} = useTranslation()
    return (
        <FormContainer
            FormProps={{
                id: formId
            }}
            defaultValues={{
                production: initialData?.[activeSeason.id]?.production || [],
                expenditures: initialData?.[activeSeason.id]?.expenditures || [],
                green_crops: initialData?.[activeSeason.id]?.green_crops || {},
                root_crops: initialData?.[activeSeason.id]?.root_crops || {},
            }}
            onSuccess={(data) => {
                // clean empty termId values from data
                const cleanedData: typeof data = {
                    ...data,
                    expenditures: cleanMissingTermIds(data.expenditures),
                    production: cleanMissingTermIds(data.production.map(i => ({
                        ...i,
                        other_uses: cleanMissingTermIds(i.other_uses)
                    }))),
                }
                onSuccess(activeSeason.id, {...cleanedData, season: activeSeason})
                setActiveSeason(null)
            }}
        >
            <Dialog open={true} fullScreen>
                <AppBar position={'relative'}>

                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Typography variant={'h5'}>
                            {activeSeason.name}
                        </Typography>
                        <IconButton size={'small'} onClick={() => setActiveSeason(null)}
                                    color={'inherit'}><Close/></IconButton>
                    </Toolbar>
                </AppBar>
                <TabContext value={tab}>
                    <TabList
                        variant={'fullWidth'}
                        centered={true}
                        onChange={(_, value) => setTab(value)} aria-label="lab API tabs example">
                        <Tab label={t('crops.production')} value="1"/>
                        <Tab label={t('crops.expenditure')} value="2"/>
                        <Tab label={t('crops.greenContinuous')} value="3"/>
                    </TabList>
                    <DialogContent>
                        <TabPanel value={'1'}>
                            <CropsFormTable seasonId={activeSeason.id} name={'production'}/>
                        </TabPanel>
                        <TabPanel value={'2'}>
                            <CropsFormExpenditures name={'expenditures'}/>
                        </TabPanel>
                        <TabPanel value={'3'}>
                            <Stack spacing={2}>
                                <GreenCropsContinuousConsume seasonId={activeSeason.id} name={'green_crops'}
                                                             label={t('crops.greenCrops')}/>
                                <GreenCropsContinuousConsume seasonId={activeSeason.id} name={'root_crops'}
                                                             label={t('crops.continuousHarvest')}/>
                            </Stack>

                        </TabPanel>

                    </DialogContent>
                </TabContext>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <CropsDialogSum/>
                    <Button form={formId} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormContainer>
    )
}