import ReloadPrompt from "./ReloadPrompt";
import {TssReactRouter} from "@feg/ui/src/vite/tss/tssReactRouter";

function App() {
    // replaced dyanmicaly
    // @ts-ignore
    const date = '__DATE__'

    return (
        <>
            <TssReactRouter/>
            <ReloadPrompt/>
        </>
    )
}

export default App