import {useTranslation} from 'react-i18next'
import {Chip, Stack, Typography} from '@mui/material'
import {useWatch} from 'react-hook-form'
import {TextFieldHiddenInput} from "../../../../common/form/TextFieldHiddenInput";

type TotalMeatWeightProps = {}

export default function TotalMeatWeight(_props: TotalMeatWeightProps) {
    const {t} = useTranslation()
    const slaughtered = useWatch({
        name: `slaughtered`
    })
    const meat = useWatch({
        name: 'meat_per_carcass'
    })
    const sold = useWatch({
        name: 'meat_quantity_sold'
    })
    const givenAway = useWatch({
        name: 'meat_quantity_given_away'
    })

    if (!(meat && slaughtered)) {
        return null
    }
    let number = Number(slaughtered) * Number(meat)
    const count = number + ' kg'
    const consumed = number - Number(givenAway || 0) - Number(sold || 0)
    return (
        <Stack direction={'column'} spacing={2}>
            <Stack direction={'column'} alignItems={'center'}>
                <Typography>
                    {t('livestock.totalMeat', {ns: 'tss'})}
                </Typography>
                <Chip label={count} color={'info'} size={'small'}/>
            </Stack>
            <Stack direction={'column'} alignItems={'center'}>
                <TextFieldHiddenInput name={'balance_consumed'} value={consumed}/>
                <Typography>
                    {t('balanceConsumed', {ns: 'tss'})}
                </Typography>
                <Chip label={consumed} color={'primary'} size={'small'}/>
            </Stack>
        </Stack>
    )
}
