import {TssVillageData, useSetVillageData, useTssVillageById} from '../../lib'
import {CropsFormSeasons} from "./CropsFormSeasons";
import {getCropsSumFields} from "./helpers/getCropsSumFields";

type CropsOverviewProps = {
    interviewId: string
}


export function CropsOverview({interviewId}: CropsOverviewProps) {
    const village = useTssVillageById(interviewId)
    const cropsData = village?.crops
    const setVillageData = useSetVillageData()
    return (
        <CropsFormSeasons formId={'crops'}
                          initialData={cropsData || {}}
                          onSuccess={(seasonId, data) => {
                              const updatedData = {
                                  crops: {
                                      ...cropsData,
                                      [seasonId]: data
                                  }
                              };
                              if (!data) {
                                  delete updatedData.crops[seasonId]
                              }


                              const {
                                  income,
                                  calories,
                                  expenses
                              } = getCropsSumFields(updatedData.crops || {})

                              setVillageData(interviewId, {
                                  ...updatedData,
                                  total: {
                                      ...village?.total,
                                      cash_income_crops: income,
                                      calories_crops: calories,
                                      cash_expenses_crops: expenses
                                  }
                              } as TssVillageData)
                          }}/>
    )
}
