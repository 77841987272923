import {LoadingButton} from "@mui/lab";
import {prepareTssVillageDataSyncUp} from "./syncTssDb/prepareTssVillageDataSyncUp";
import {useTranslation} from "react-i18next";
import {useTssSetUploadedTimestamp, useTssUpdatedAt, useTssVillageById, useUnsetTssUpdatedAt} from "../lib";
import {useTssInterviewDetailUpdateMutation, useTssProjectOneQuery} from "../lib/gql/__codegen__/react-query";
import {useSnackbar} from "notistack";
import {Warning} from "@mui/icons-material";
import {useQueryClient} from "@tanstack/react-query";

export function InterviewListItemUpload({interviewId, projectId}: { interviewId: string, projectId: string }) {
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const {mutateAsync: triggerUpload, isPending, error} = useTssInterviewDetailUpdateMutation()
    const unset = useUnsetTssUpdatedAt()
    const updatedAt = useTssUpdatedAt(interviewId)
    const {enqueueSnackbar} = useSnackbar()
    const setUpdatedAt = useTssSetUploadedTimestamp()
    const queryClient = useQueryClient()
    const invalidateQuery = async () => {
        // todo the PROJECT_ID not interview id
        const queryKey = useTssProjectOneQuery.getKey({id: projectId});
        await queryClient.refetchQueries({
            queryKey: queryKey,
            type: "all"
        })
    }
    if (!villageData || !updatedAt) {
        return null
    }
    return (
        <LoadingButton
            color={'error'}
            startIcon={<Warning/>}
            loading={isPending}
            onClick={async () => {
                const conf = window.confirm(t('confirmUpload'))
                if (conf && villageData) {
                    const {
                        projectInterviewInsertInput,
                        projectInterviewBackup
                    } = prepareTssVillageDataSyncUp(villageData)
                    const res = await triggerUpload({
                        id: interviewId,
                        set: projectInterviewInsertInput,
                        backup: projectInterviewBackup
                    })
                    if (res?.insert_project_interview_base_one) {
                        unset(interviewId)
                        setUpdatedAt(interviewId, res.insert_project_interview_base_one.created_at)
                        await invalidateQuery()
                    } else {
                        enqueueSnackbar('Something went wrong. Please contact support team.', {
                            variant: 'error'
                        })
                    }
                }
            }}>
            Upload
        </LoadingButton>
    )
}