import {useState} from "react";
import {useNhostClient} from "@nhost/react";
import {IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Delete, Description, Image} from "@mui/icons-material";
import {FileListItem} from "./fileUploadTypes";

export function FileUploadItem({file, refetchFileQuery}: {
    file: FileListItem,
    refetchFileQuery: () => Promise<void>
}) {
    const [isDeleting, setIsDeleting] = useState(false)
    const {storage} = useNhostClient()
    return (
        <ListItem
            sx={{
                cursor: 'pointer'
            }}
            onClick={async () => {
                const res = await storage.getPresignedUrl({fileId: file.file.id})
                if (res?.presignedUrl?.url) {
                    window.open(res.presignedUrl.url, '_blank')
                }
            }}>
            <ListItemIcon>
                {file.file?.mimeType?.startsWith('image') ? <Image/> : <Description/>}
            </ListItemIcon>
            <ListItemText primary={file.primaryText} secondary={file.secondaryText}/>
            <ListItemSecondaryAction>
                <IconButton color={'error'}
                            disabled={isDeleting}
                            edge={'end'}
                            onClick={async () => {
                                setIsDeleting(true)
                                try {
                                    await storage.delete({
                                        fileId: file.file.id
                                    })
                                    await refetchFileQuery()
                                } catch (e) {
                                    console.error(e)
                                }
                                setIsDeleting(false)
                            }}
                            aria-label={'delete'}>
                    <Delete/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
