import {TextFieldElement, TextFieldElementProps} from 'react-hook-form-mui'
import {FormFieldWithLabel, FormFieldWithLabelProps} from './FormFieldWithLabel'
import {TextFieldNumberFormat} from "./TextFieldNumberFormat";

type FormFieldWithNumberInputProps = TextFieldElementProps & {
    labelSize?: FormFieldWithLabelProps['size']
    isNumberFormat?: boolean
}

export function TextFieldLabelInput({
                                        label,
                                        name,
                                        type,
                                        labelSize,
                                        isNumberFormat,
                                        ...rest
                                    }: FormFieldWithNumberInputProps) {
    return (
        <FormFieldWithLabel label={label as string} size={labelSize || 'md'}>
            {isNumberFormat ? (
                <TextFieldNumberFormat name={name} size={'small'} {...rest}/>
            ) : (
                <TextFieldElement name={name}
                                  type={type || 'text'}
                                  {...(type !== 'text' && {
                                      inputProps: {inputMode: 'numeric', pattern: '[0-9]*'}
                                  })} size={'small'} {...rest}/>
            )}
        </FormFieldWithLabel>
    )
}
