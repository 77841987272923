import {Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {labelFieldPropMap, ManageEmploymentButtonProps} from "./employmentTypes";
import {useFieldArray} from "react-hook-form";
import {Add, Delete} from "@mui/icons-material";
import {LabourPaymentFormTable, PAYMENT_TYPE} from "./LabourPaymentFormTable";
import {useTranslation} from "react-i18next";
import {MigrationFrequencyForm} from "../../common/form/purchaseSaleForm/MigrationFrequencyForm";
import {EmploymentTableFormSum} from "./EmploymentTableFormSum";
import {EmploymentTableMigrationSum} from "./EmploymentTableMigrationSum";


type EmploymentTableFormProps = {
    name: ManageEmploymentButtonProps['name']
}


export function EmploymentTableForm({name}: EmploymentTableFormProps) {
    const fieldName = labelFieldPropMap[name]
    const {t} = useTranslation()
    const {fields, remove, append} = useFieldArray({
        name: fieldName
    })
    return (
        <Box>
            <TableContainer>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            {name !== 'labourExchange' && (
                                <>
                                    <TableCell>
                                        {t('cashIncome')}
                                    </TableCell>
                                </>
                            )}
                            {name === 'labourExchange' && (
                                <TableCell>
                                    {t('Kg')}
                                </TableCell>
                            )}
                            <TableCell>
                                {t('calories')}
                            </TableCell>
                            <TableCell align={'right'}>
                                <IconButton
                                    onClick={() => {
                                        append({title: ''})
                                    }}>
                                    <Add/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, i) => (
                            <TableRow key={field.id} selected={!!(i % 2)}>
                                <TableCell>
                                    {(name !== 'migration') ? (
                                        <LabourPaymentFormTable name={`${fieldName}.${i}`}
                                                                type={name === 'labourExchange' ? PAYMENT_TYPE.FOOD : PAYMENT_TYPE.SALARY}/>
                                    ) : (
                                        <MigrationFrequencyForm name={`${fieldName}.${i}`}/>
                                    )}
                                </TableCell>
                                {name === 'migration' ? (
                                    <EmploymentTableMigrationSum name={`${fieldName}.${i}`}/>
                                ) : (
                                    <EmploymentTableFormSum name={`${fieldName}.${i}`} type={name}/>
                                )}
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                                            if (confirmed) {
                                                remove(i)
                                            }
                                        }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}