import {useTssState} from "../../lib";
import {useTssProjectOneQuery} from "../../lib/gql/__codegen__/react-query";
import {useShallow} from "zustand/react/shallow";


export function useTssProjectRefetch({id}: { id: string }) {
    // const [trigger] = useTssProjectOneLazyQuery()

    const setProjectData = useTssState(useShallow(state => state.setProjectData))

    const execute = async () => {
        const data = await useTssProjectOneQuery.fetcher({id})()
        const projectByPk = data?.project_by_pk;
        if (projectByPk) {
            setProjectData(projectByPk)
        } else {
            console.error('project not found', data)
        }
    }


    return {
        execute
    }
}