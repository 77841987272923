import {useTssVillageById} from "../../../lib";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import BoxCentered from "../../../common/form/BoxCentered";
import {useTranslation} from "react-i18next";

type BusinessEfficiencyProps = {
    interviewId: string
}

export function BusinessEfficiency({interviewId}: BusinessEfficiencyProps) {
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const businessEfficiency = villageData?.businessEfficiency;
    let cropsProfit = Number(businessEfficiency?.crops?.sellOffValue) - Number(villageData?.total?.cash_expenses_crops);
    let livestockProfit = Number(businessEfficiency?.livestock?.sellOffValue) - Number(villageData?.total?.cash_expenses_livestock);
    let cropsAsset = Number(villageData?.baseData?.land_unit_conversion || 1) * (Number(villageData?.baseData?.land_cultivated_cash) + Number(villageData?.baseData?.land_cultivated_food));
    let livestockAsset = businessEfficiency?.livestock?.tlu ?? 0;
    return (
        <BoxCentered>

            <TableContainer sx={{maxWidth: 600}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>
                                {t('livestockLabel')}
                            </TableCell>
                            <TableCell>
                                {t('crops.name')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('assets')}
                            </TableCell>
                            <TableCell>
                                {livestockAsset?.toFixed(1)} TLU
                            </TableCell>
                            <TableCell>
                                {cropsAsset?.toFixed(1)} Ha
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('totalIncome')}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.livestock?.sellOffValue ? getNumberFormat(businessEfficiency.livestock.sellOffValue) : 0}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.crops?.sellOffValue ? getNumberFormat(businessEfficiency.crops.sellOffValue) : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('totalExpenditure')}
                            </TableCell>
                            <TableCell>
                                {villageData?.total?.cash_expenses_livestock ? getNumberFormat(villageData.total.cash_expenses_livestock) : 0}
                            </TableCell>
                            <TableCell>
                                {villageData?.total?.cash_expenses_crops ? getNumberFormat(villageData.total.cash_expenses_crops) : 0}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('profit')}
                            </TableCell>
                            <TableCell>
                                {getNumberFormat(livestockProfit)}
                            </TableCell>
                            <TableCell>
                                {getNumberFormat(cropsProfit)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('businessEfficiencyRatio')}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.livestock?.efficiencyRatio?.toFixed(1) || 'N.A.'}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.crops?.efficiencyRatio?.toFixed(1) || 'N.A.'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('incomePerAsset')}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.livestock?.incomeAssetRatio && getNumberFormat(businessEfficiency.livestock.incomeAssetRatio)}
                            </TableCell>
                            <TableCell>
                                {businessEfficiency?.crops?.incomeAssetRatio && getNumberFormat(businessEfficiency.crops.incomeAssetRatio)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant={'head'}>
                                {t('profitPerAsset')}
                            </TableCell>
                            <TableCell>
                                {getNumberFormat(livestockProfit / livestockAsset)}
                            </TableCell>
                            <TableCell>
                                {getNumberFormat(cropsProfit / cropsAsset)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </BoxCentered>
    )
}