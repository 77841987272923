import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {arrayFieldSum, FishingWildFoodData, FishingWildFoodExpenses} from "../../lib";

export function FishingWildFoodDialogSum() {
    const values: FishingWildFoodData[] = useWatch({name: 'fishing_wild_food'})
    const valuesExpenses: FishingWildFoodExpenses[] = useWatch({name: 'fishing_wild_food_expenses'})
    const kcal = arrayFieldSum(values, 'total_kcal')
    return (
        <CaloriesIncomeExpense expenses={arrayFieldSum(valuesExpenses, 'total_expenses')}
                               income={arrayFieldSum(values, 'total_cash_income')}
                               calories={kcal ? kcal.toFixed(1) : 0}
        />
    )
}