import {MonthlyBreakdownData} from "../../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {chartColors} from "./chartColorHelper";
import {useTranslation} from "react-i18next";

type ChartOptions = {
    stackId?: string
    yAxisId?: string
    dataKey?: string
}
export type ChartValueType = ChartOptions & {
    name: string
    value: number
    color: string
    key: string
    sort: number
}

export function useChartValueHelper() {
    const {t} = useTranslation()
    const getIncome = (data: MonthlyBreakdownData, options?: ChartOptions): ChartValueType[] => {
        const income = data.income
        return [{
            ...options,
            name: t('otherIncome'),
            value: income?.metaOtherIncome,
            color: chartColors.var1,
            key: 'income.metaOtherIncome',
            sort: 1
        }, {
            ...options,
            name: t('fishingWildFood'),
            value: income?.wildFood,
            key: 'income.wildFood',
            color: chartColors.var2,
            sort: 2
        }, {
            ...options,
            name: t('migration'),
            value: income?.migration,
            key: 'income.migration',
            color: chartColors.var3,
            sort: 3
        }, {
            ...options,
            name: t('selfEmployment'),
            value: income?.selfEmployment,
            key: 'income.selfEmployment',
            color: chartColors.var4,
            sort: 4
        }, {
            ...options,
            name: t('labourJobs'),
            value: income?.labourAndJob,
            key: 'income.labourAndJob',
            color: chartColors.var5,
            sort: 5
        }, {
            ...options,
            name: t('livestockProducts'),
            value: income?.livestock_totals?.metaLivestockProducts,
            key: 'income.livestock_totals.metaLivestockProducts',
            color: chartColors.var6,
            sort: 6
        }, {
            ...options,
            name: t('livestockLabel'),
            value: income?.livestock_totals?.animalSales,
            key: 'income.livestock_totals.animalSales',
            color: chartColors.var7,
            sort: 7
        }, {
            ...options,
            name: t('crops.name'),
            value: income?.crops,
            key: 'income.crops',
            color: chartColors.var8,
            sort: 8
        }].filter(i => i.value)
    }
    const getExpenditure = (data: MonthlyBreakdownData, options?: ChartOptions): ChartValueType[] => {
        const expenditure = data.expenditure
        return [{
            ...options,
            name: t('otherExpenses'),
            value: expenditure?.metaOtherExpenses,
            key: 'expenditure.metaOtherExpenses',
            color: chartColors.var9,
            sort: 1
        }, {
            ...options,
            name: t('healthEducation'),
            value: expenditure?.metaHealthEducation,
            key: 'expenditure.metaHealthEducation',
            color: chartColors.var10,
            sort: 2
        }, {
            ...options,
            name: t('inputBusinessCost'),
            value: expenditure?.metaInputsAndBusinessCost,
            key: 'expenditure.metaInputsAndBusinessCost',
            color: chartColors.var11,
            sort: 3
        }, {
            ...options,
            name: t('essentialHouseholdItems'),
            value: expenditure?.purchasedNonFood?.essentialHouseholdItems,
            color: chartColors.var12,
            key: 'expenditure.purchasedNonFood.essentialHouseholdItems',
            sort: 4
        }, {
            ...options,
            name: t('purchasedNonStaples'),
            value: expenditure?.purchasedFood?.nonStaples,
            color: chartColors.var13,
            key: 'expenditure.purchasedFood.nonStaples',
            sort: 5
        }, {
            ...options,
            name: t('purchasedStaples'),
            value: expenditure?.purchasedFood?.staples,
            color: chartColors.var14,
            key: 'expenditure.purchasedFood.staples',
            sort: 6
        }].filter(i => i.value)
    }
    const getKcal = (data: MonthlyBreakdownData, options?: ChartOptions): ChartValueType[] => {
        const kcal = data.kcal
        return [{
            ...options,
            name: t('foodAid'),
            value: kcal?.foodAid,
            key: 'kcal.foodAid',
            color: chartColors.var15,
            sort: 1
        }, {
            ...options,
            name: t('fishingWildFood'),
            value: kcal?.wildFood,
            key: 'kcal.wildFood',
            color: chartColors.var16,
            sort: 2
        }, {
            ...options,
            name: t('mealsFoodPayment'),
            value: kcal?.metaMealsFoodPayment,
            key: 'kcal.metaMealsFoodPayment',
            color: chartColors.var17,
            sort: 3
        }, {
            ...options,
            name: t('purchasedNonStaples'),
            value: kcal?.purchasedFood?.nonStaples,
            key: 'kcal.purchasedFood.nonStaples',
            color: chartColors.var18,
            sort: 4
        }, {
            ...options,
            name: t('purchasedStaples'),
            value: kcal?.purchasedFood?.staples,
            key: 'kcal.purchasedFood.staples',
            color: chartColors.var19,
            sort: 5
        }, {
            ...options,
            name: t('barteredLivestock'),
            value: kcal?.metaBarteredLivestock,
            key: 'kcal.metaBarteredLivestock',
            color: chartColors.var20,
            sort: 6
        }, {
            ...options,
            name: t('livestockProducts'),
            value: kcal?.metaLivestockProduct,
            key: 'kcal.metaLivestockProduct',
            color: chartColors.var21,
            sort: 7
        }, {
            ...options,
            name: t('crops.name'),
            value: kcal?.metaCrops,
            key: 'kcal.metaCrops',
            color: chartColors.var22,
            sort: 8
        }, {
            ...options,
            name: t('surplusPreviousYear'),
            value: kcal?.crops?.leftoverCerealReserves,
            key: 'kcal.crops.leftoverCerealReserves',
            color: chartColors.var23,
            sort: 9
        }].filter(i => i.value)
    }
    return {
        getIncome, getExpenditure, getKcal
    }
}