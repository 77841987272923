import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import {ProjectListTssQuery} from "../../lib";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type ProjectListItemProps = {
    project: ProjectListTssQuery['project'][number]
    isDownloaded: boolean
};

export function ProjectListItem({project, isDownloaded}: ProjectListItemProps) {
    const {t} = useTranslation()
    return (
        <Card variant={'outlined'}>
            <CardActionArea component={Link}
                            to={`/baseline?id=${project.id}`}>
                <CardContent>
                    <Typography variant={'subtitle2'}>
                        {project.project_interviews?.map(i => i.location.country_region.country.en)
                            .filter((v, i, s) => s.indexOf(v) === i)
                            .join(', ')}
                    </Typography>
                    <Typography variant={'h5'}>
                        {project.project_interviews?.map(i => i.location.zone.name)
                            .filter((v, i, s) => s.indexOf(v) === i)
                            .join(', ')}
                    </Typography>
                    <Typography variant={'subtitle2'}>
                        {project.name}
                    </Typography>
                    <Typography variant={'body1'}>
                        {t('supervisor')}: {project.user?.displayName}<br/>
                        {t('teamleader')}: {project.userTeamleader?.displayName}<br/>
                        {t('interviews')}: {project.project_interviews?.length}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}