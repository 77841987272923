import {createRoot} from 'react-dom/client'
import "./i18next.ts"
import '@fontsource-variable/inter';
import {StrictMode} from "react";
import App from "./App";
import {LicenseInfo} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUIX as string)

createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <App/>
    </StrictMode>
    // <RouterProvider
    // <RouterProvider router={router}/>
)