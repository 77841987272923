import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Add} from "@mui/icons-material";
import {useFieldArray} from "react-hook-form";
import {ExpensesTableFormRow} from "./ExpensesTableFormRow";
import {EXPENSES_TYPE} from "./shared";


type ExpensesTableFormProps = {
    name: string
    expensesType: EXPENSES_TYPE
}

export function ExpensesTableForm({name, expensesType}: ExpensesTableFormProps) {
    const {t} = useTranslation()
    const {fields, remove, append} = useFieldArray({
        name
    })

    return (
        <Stack>
            <TableContainer sx={{marginTop: 3}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t('item')}
                            </TableCell>
                            {expensesType === EXPENSES_TYPE.FOOD && (
                                <TableCell>{t('quantity')}</TableCell>
                            )}
                            <TableCell>{t('expenditure')}</TableCell>
                            {expensesType === EXPENSES_TYPE.FOOD && (
                                <TableCell>{t('kcals')}</TableCell>
                            )}
                            <TableCell align={'right'}>
                                <IconButton onClick={() => {
                                    append({title: '', is_food: expensesType === EXPENSES_TYPE.FOOD})
                                }}>
                                    <Add/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, iteration) => (
                            <ExpensesTableFormRow key={field.id} value={field}
                                                  remove={remove}
                                                  expensesType={expensesType}
                                                  iteration={iteration}
                                                  name={name}/>
                        ))}
                    </TableBody>
                    {fields.length > 5 && (
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}/>
                                <TableCell align={'right'}>
                                    <IconButton onClick={() => {
                                        append({title: '', is_food: expensesType === EXPENSES_TYPE.FOOD})
                                    }}>
                                        <Add/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
        </Stack>
    )
}