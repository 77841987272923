import {Card, CardContent, Chip, Divider, Stack, Typography, Unstable_Grid2 as Grid} from '@mui/material'
import {useTranslation} from 'react-i18next'
import TotalMeatWeight from './partials/TotalMeatWeight'
import {TextFieldElement} from 'react-hook-form-mui'
import {FormStack} from "../../../common/form/FormStack";
import {TextFieldLabelInput} from "../../../common/form/TextFieldLabelInput";

export default function SlaughterAndLossesForm() {
    const {t} = useTranslation()
    return (
        <Grid container spacing={3}>
            <Grid>
                <Typography variant={'h6'}>
                    {t('livestock.slaughters')}
                </Typography>
                <Card sx={{marginTop: 2}} variant={'outlined'}>
                    <CardContent>
                        <Stack divider={<Divider orientation={'vertical'} flexItem={true}/>} direction={'row'}
                               spacing={3}>
                            <div>
                                <FormStack direction={'row'}>
                                    <Chip color={'primary'} label={1} sx={{alignSelf: 'center'}}/>
                                    <TextFieldLabelInput label={t('livestock.slaughtered')}
                                                         name={'slaughtered'}/>
                                    <TextFieldLabelInput isNumberFormat label={t('livestock.meatPerCarcass')}
                                                         name={'meat_per_carcass'}/>
                                </FormStack>
                                <FormStack direction={'row'}>
                                    <Chip color={'primary'} label={2} sx={{alignSelf: 'center'}}/>
                                    <TextFieldLabelInput isNumberFormat label={t('quantitySold')}
                                                         name={'meat_quantity_sold'}/>
                                    <TextFieldLabelInput isNumberFormat label={t('pricePerKg')}
                                                         name={'meat_price_per_kg'}/>
                                </FormStack>
                                <FormStack direction={'row'}>
                                    <Chip color={'primary'} label={3} sx={{alignSelf: 'center'}}/>
                                    <TextFieldLabelInput label={t('quantityGivenAway')}
                                                         name={'meat_quantity_given_away'}/>
                                </FormStack>
                            </div>

                            <Stack justifyContent={'center'} spacing={3} alignSelf={'center'}>
                                <TotalMeatWeight/>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid>
                <Typography variant={'h6'}>
                    {t('livestock.losses')}
                </Typography>
                <Card sx={{marginTop: 2}} variant={'outlined'}>
                    <CardContent>
                        <TextFieldLabelInput label={t('livestock.lossesDeath')} name={'losses_death'}/>
                        <TextFieldElement name={'losses_reason'} multiline rows={3} label={t('reason')}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
