import {TssVillageData} from "./tssVillageDataTypes";

export const emptyVillageData: TssVillageData = {
    crops: {},
    // @ts-ignore
    livestock: {},
    purchasesFood: [],
    purchasesNonFood: [],
    fishing_wild_food: [],
    fishing_wild_food_expenses: [],
    baseData: {},
    total: {},
    food_aid: [],
    labour_jobs: [],
    remittances_credit: [],
    migration: [],
    labour_exchange: [],
    self_employment: [],
    // @ts-ignore
    totalOnMonthlyCalculation: {},
    // @ts-ignore
    monthBasedCalculation: {},
    // @ts-ignore
    businessEfficiency: {}
}