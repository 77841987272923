import {useWatch} from "react-hook-form";
import {TableCell} from "@mui/material";
import {durationCalculator} from "../../lib/helpers/durationCalculator";
import {useInterviewContext} from "../InterviewProvider";
import {FishingWildFoodData, useCalculateHouseholdNeedsById} from "../../lib";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {getNumberFormat} from "../../lib/helpers/numberFormat";

type FishingWildFoodFormRowSumProps = {
    name: string
}

export function FishingWildFoodFormRowSum({name}: FishingWildFoodFormRowSumProps) {
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)
    const values: FishingWildFoodData = useWatch({name})
    const {frequency} = durationCalculator({duration: values.duration})
    const sum = Number(values.quantity_unit || 1) * Number(values.quantity || 0) * frequency
    const amountSold = Number(values.sold_unit || 1) * Number(values.sold_quantity || 0)
    const cashIncome = Number(values.price || 0) * Number(values.sold_quantity || 0)
    const kcal = values.term?.kcal
    const hhNeed = kcal ? calculate(kcal * (sum - amountSold)) : {percent: 0}
    return (
        <>
            <TableCell>
                {values?.term?.label}
            </TableCell>
            <TableCell>
                {getNumberFormat(sum)} kg
            </TableCell>
            <TableCell>
                {getNumberFormat(amountSold)} kg
            </TableCell>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={cashIncome}/>
                {getNumberFormat(cashIncome)}
            </TableCell>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_kcal`} value={hhNeed.percent}/>
                {hhNeed.percent?.toFixed(1)} %
            </TableCell>
        </>
    )
}