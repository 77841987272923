import {FormContainer} from "react-hook-form-mui";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {EmploymentTableForm} from "./EmploymentTableForm";
import {useTranslation} from "react-i18next";
import {labelFieldPropMap, ManageEmploymentButtonProps} from "./employmentTypes";
import {arrayFieldSum, TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {EmploymentDialogSum} from "./EmploymentDialogSum";
import {formDialogFlexStyle} from "../../common/form/formFlexStyles";

const FORM_ID = 'employment-form'


export function ManageEmploymentButton({name, interviewId, calories, income, icon}: ManageEmploymentButtonProps) {
    const {t} = useTranslation()
    const popperRef = useRef<PopupState>(null)
    const village = useTssVillageById(interviewId)
    const setVillageData = useSetVillageData()
    const fieldName = labelFieldPropMap[name]

    return (

        <CardDialogAction income={income}
                          icon={icon}
                          calories={calories}
                          category={t(name)}
                          ref={popperRef}>
            <FormContainer
                FormProps={{
                    id: FORM_ID,
                    style: formDialogFlexStyle
                }}
                defaultValues={{
                    // @ts-ignore
                    [fieldName]: village?.[fieldName]
                }}
                onSuccess={(data) => {
                    const updatedValues = {
                        ...village,
                        ...data,
                        total: {
                            ...village?.total
                        }
                    } as TssVillageData;
                    // @ts-ignore
                    updatedValues.total[`calories_${fieldName}`] = arrayFieldSum(data[fieldName], 'total_kcal')
                    // @ts-ignore
                    updatedValues.total[`cash_income_${fieldName}`] = arrayFieldSum(data[fieldName], 'total_cash_income')

                    setVillageData(interviewId, updatedValues)
                    popperRef.current?.close()
                }}>
                <DialogContent>
                    <EmploymentTableForm name={name}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <EmploymentDialogSum name={fieldName}/>
                    <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </FormContainer>
        </CardDialogAction>
    )
}