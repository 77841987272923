import {MigrationData, useHouseholdSizeById} from "../../lib";
import {useWatch} from "react-hook-form";
import {TableCell} from "@mui/material";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {useInterviewContext} from "../InterviewProvider";
import {getNumberFormat} from "../../lib/helpers/numberFormat";

type EmploymentTableMigrationSumProps = {
    name: string
}

export function EmploymentTableMigrationSum({name}: EmploymentTableMigrationSumProps) {
    const values: MigrationData = useWatch({name})
    const {id} = useInterviewContext()
    const hhSize = useHouseholdSizeById(id)
    const income = Number(values.amount || 0) * Number(values.times_money_sent || 0)
    const kcal = (Number(values.people || 0) / hhSize) * (Number(values.months?.length ?? 0) / 12) * 100
    return (
        <>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={income}/>
                {getNumberFormat(income)}
            </TableCell>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_kcal`} value={kcal}/>
                {kcal.toFixed(1)}%
            </TableCell>
        </>
    )
}