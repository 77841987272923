import {SelectElement, TextFieldElement, ToggleButtonGroupElement} from "react-hook-form-mui";
import {useWatch} from "react-hook-form";
import {Frequency} from "../../../lib";
import {MonthMultiSelectElement} from "../MonthMultiSelectElement";
import {useTranslation} from "react-i18next";
import {ThirtyFpsSelect} from "@mui/icons-material";
import {PopperContainer} from "../../popper/PopperContainer";
import {Stack} from "@mui/material";

type PurchasePerDurationFormProps = {
    name: string
    showMonthPicker?: boolean
    hideYear?: boolean
    label?: string
}
let monthsOptions = [{id: 1, label: '1'}, {id: 2, label: '2'}, {id: 3, label: '3'}, {
    id: 4.3452381,
    label: '4'
}];


export function LabourDurationFrequencyForm({name, showMonthPicker, hideYear, label}: PurchasePerDurationFormProps) {
    const filterOptions = [Frequency.OTHER];
    if (hideYear) {
        filterOptions.push(Frequency.YEAR)
    }
    const options = Object.values(Frequency).filter(i => !filterOptions.includes(i)).map(i => ({
        id: i,
        label: i
    }))
    const {t} = useTranslation()
    const typeName = `${name}.frequency_type`;
    const type: Frequency = useWatch({name: typeName})
    const daysName = `${name}.frequency_days`;
    const weeksName = `${name}.frequency_weeks`;
    const monthsName = `${name}.frequency_months`;
    const monthsNamePick = `${name}.frequency_months_multi`;
    const showPerDays = type === Frequency.DAY;
    const showPerWeeks = type === Frequency.WEEK;
    const showMonths = type !== Frequency.YEAR;
    return (
        <PopperContainer
            variant={'popover'}
            trigger={{
                children: label || t('paid'),
                endIcon: <ThirtyFpsSelect/>
            }} menuProps={{keepMounted: true}}>
            <Stack direction={'row'} spacing={2} alignItems={'center'} padding={2}>

                <ToggleButtonGroupElement size={'small'} name={typeName} options={options}
                                          exclusive/>
                {showPerDays && (
                    <TextFieldElement name={daysName} label={t('nrDaysPerMonth')} size={'small'}
                                      sx={{maxWidth: '120px'}}
                                      type={'number'}/>
                )}
                {showPerWeeks && (
                    <SelectElement name={weeksName}
                                   options={monthsOptions}
                                   label={t('nrWeeksPerMonth')} size={'small'}
                                   sx={{minWidth: '90px'}}
                                   type={'number'}/>
                )}
                {showMonths && (
                    <>
                        {showMonthPicker ? (
                            <MonthMultiSelectElement name={monthsNamePick} label={'Months'} size={'small'}/>
                        ) : (
                            <TextFieldElement name={monthsName} label={'Months'} size={'small'}
                                              sx={{maxWidth: '80px'}}
                                              type={'number'}/>
                        )}
                    </>
                )}
            </Stack>
        </PopperContainer>
    )
}