import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useFieldArray} from "react-hook-form";
import {Add, Delete} from "@mui/icons-material";
import {FoodAidFormTableRow} from "./FoodAidFormTableRow";
import {FoodAidFormTableRowSum} from "./FoodAidFormTableRowSum";

type RemittancesCreditFormTableProps = {
    name: string
}

export function FoodAidFormTable({name}: RemittancesCreditFormTableProps) {
    const {t} = useTranslation()
    const {fields, remove, append} = useFieldArray({
        name
    })
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>
                            {t('calories')}
                        </TableCell>
                        <TableCell>
                            {t('cashIncome')}
                        </TableCell>
                        <TableCell align={'right'}>
                            <IconButton onClick={() => {
                                append({description: ''})
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, iteration) => (
                        <TableRow key={field.id} selected={!!(iteration % 2)}>
                            <TableCell>
                                <FoodAidFormTableRow
                                    name={`${name}.${iteration}`}
                                />
                            </TableCell>
                            <FoodAidFormTableRowSum name={`${name}.${iteration}`}/>
                            <TableCell align={'right'}>
                                <IconButton
                                    onClick={() => {
                                        const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                                        if (confirmed) {
                                            remove(iteration)
                                        }
                                    }}>
                                    <Delete/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}