import {Alert, MenuItem, Typography} from '@mui/material'
import {PopupState} from 'material-ui-popup-state/hooks'
import {CropSeasonListQuery, useInterviewCountryId} from '../../../lib'
import {useTranslation} from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {PopperContainer} from "../../../common/popper/PopperContainer";
import {useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWheatAwn} from "@fortawesome/free-solid-svg-icons";
import {useAllCropSeasons} from "../../../lib/state/allVariablesState";

type AddSeasonButtonProps = {
    selectedSeasons: string[]
    onAddSeason: (season: CropSeasonListQuery['crop_season'][number]) => void
}

export function AddSeasonButton({onAddSeason, selectedSeasons}: AddSeasonButtonProps) {
    const popperRef = useRef<PopupState | null>(null)
    const countryId = useInterviewCountryId()
    const seasons = useAllCropSeasons()
    const data = countryId ?
        seasons.filter(i => !i.country_id || i.country_id === countryId) : seasons

    const {t} = useTranslation()
    let cropSeasons = data.filter(season => !selectedSeasons.some(s => s === season.id)) ?? []
    if (!cropSeasons.length && !selectedSeasons.length) {
        return (
            <Alert severity={'warning'}>
                <Typography>{t('noCropSeasonsMessage')}</Typography>
            </Alert>
        )
    }
    if (selectedSeasons?.length && !cropSeasons.length) {
        return null
    }

    return (
        <PopperContainer
            ref={popperRef}
            variant={'popover'}
            popupId={'add-crop-season-button'}
            trigger={{
                variant: 'outlined',
                startIcon: <FontAwesomeIcon icon={faWheatAwn}/>,
                endIcon: <ArrowDropDownIcon/>,
                children: t('crops.list.add')
            }}
            menuColumnCount={cropSeasons.length ? 3 : undefined}>
            {cropSeasons?.map(season => (
                <MenuItem key={season.id}
                          onClick={() => {
                              popperRef.current?.close()
                              onAddSeason(season)
                          }}>
                    {season.name}
                </MenuItem>
            ))}
        </PopperContainer>
    )
}
