import {Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Month_Enum, useTssVillageById} from "../../../lib";
import {MonthlyBreakdownData} from "../../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {useTranslation} from "react-i18next";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownChartsProps = {
    interviewId: string
}

type PreparedMonthlyData = (MonthlyBreakdownData & {
    name: Month_Enum
    stack: string
})[];

const toPercent = (decimal = 0, fixed = 0) => `${(decimal * 100)}%`;


export function BreakdownExpenditureChartsMonthly({interviewId}: BreakdownChartsProps) {
    const villageData = useTssVillageById(interviewId)
    const {t} = useTranslation()
    const {getExpenditure} = useChartValueHelper()

    const monthlyCalculation = villageData?.monthBasedCalculation
    const totalExpenditure = villageData?.totalOnMonthlyCalculation ? getExpenditure(villageData.totalOnMonthlyCalculation) : []
    const calc: PreparedMonthlyData = []
    if (monthlyCalculation) {
        Object.keys(monthlyCalculation).forEach((cId) => {
            const current = monthlyCalculation[cId as Month_Enum]
            calc.push({
                name: t(`month.${cId}.short`),
                stack: 'income',
                ...current,
            })
        })
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width={500}
                height={300}
                data={calc}
                // margin={{
                //     top: 20,
                //     right: 30,
                //     left: 20,
                //     bottom: 5,
                // }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis tickFormatter={v => getNumberFormat(v)}/>
                <Tooltip
                    itemSorter={(props) =>
                        // @ts-ignore
                        Number(props.format)
                    }
                    formatter={(value) => typeof value === 'number' ? getNumberFormat(value) : value}
                />

                {totalExpenditure.reverse().map((d, v) => (
                    <Bar key={`bar-${v}`} dataKey={d.key} fill={d.color} stroke={d.color} name={d.name} stackId={'1'}
                         format={d.sort}/>
                ))}

                <Line type={'monotone'} dataKey={'total_income'} stroke={'green'} fill={'rgba(0,255,0,0.5)'}
                      strokeWidth={2}
                      name={t('cashIncome')}/>

            </ComposedChart>
        </ResponsiveContainer>
    )
}