import {PopperContainer} from "../../../common/popper/PopperContainer";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormContainer} from "react-hook-form-mui";
import {InterviewBaseForm} from "./InterviewBaseForm";
import {TssVillageData} from "../../../lib";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {ArrowRight} from "@mui/icons-material";

const FORM_ID = 'interview-base-questionaire'

type ManageInterviewBaseButtonProps = {
    onSuccess: (data: TssVillageData['baseData']) => void
    initialData: TssVillageData['baseData']
}

export function ManageInterviewBaseButton({onSuccess, initialData}: ManageInterviewBaseButtonProps) {
    const {t} = useTranslation()
    const bind = useRef<PopupState>(null)
    return (
        <PopperContainer
            dialogProps={{
                fullScreen: true
            }}
            trigger={{
                variant: 'outlined',
                startIcon: <ArrowRight/>,
                children: t('manage')
            }}
            ref={bind}
            variant={'dialog'}
            dialogTitle={t('householdProfile')}
        >
            <DialogContent>
                <FormContainer
                    FormProps={{
                        id: FORM_ID
                    }}
                    defaultValues={{...initialData}}
                    onSuccess={data => {
                        onSuccess(data)
                        bind.current?.close()
                    }}>
                    <InterviewBaseForm/>
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                    {t('saveClose')}
                </Button>
            </DialogActions>
        </PopperContainer>
    )
}