import CaloriesIncomeExpense from "./shared/CaloriesIncomeExpense";
import {sumObjectFields, TssTotalCalculated, useTssVillageById} from "../lib";
import {StackProps} from "@mui/material";

type InterviewOverviewTotalSumProps = {
    id: string
    showDiffIncomeExpenses?: boolean
    showDiffCalories?: boolean
    small?: boolean
    stackProps?: StackProps
}

export function InterviewOverviewTotalSum({
                                              id,
                                              showDiffCalories,
                                              showDiffIncomeExpenses,
                                              small,
                                              stackProps
                                          }: InterviewOverviewTotalSumProps) {
    const village = useTssVillageById(id)
    const total: TssTotalCalculated = village?.total ?? {}
    const kcal = sumObjectFields(total, [
        'calories_labour_exchange',
        'calories_labour_jobs',
        'calories_migration',
        'calories_livestock',
        'calories_crops',
        'calories_food_aid',
        'calories_fishing_wild_food',
        'calories_purchases'
    ])
    const income = sumObjectFields(total, [
        'cash_income_livestock',
        'cash_income_migration',
        'cash_income_food_aid',
        'cash_income_crops',
        'cash_income_fishing_wild_food',
        'cash_income_remittance_credit',
        'cash_income_self_employment',
        'cash_income_labour_jobs',
        'cash_income_labour_exchange'])
    const expenses = sumObjectFields(total, [
        'cash_expenses_purchases_non_food',
        'cash_expenses_purchases_food',
        'cash_expenses_livestock',
        'cash_expenses_wild_food',
        'cash_expenses_food_aid',
        'cash_expenses_remittance_credit',
        'cash_expenses_crops'
    ])
    const kcalSum = (kcal || 0) + Number(village?.baseData?.leftover_cereal_total_calories ?? 0)

    return (
        <CaloriesIncomeExpense calories={kcalSum ? kcalSum.toFixed(1) : 0}
                               filled={true}
                               stackProps={stackProps}
                               income={income}
                               expenses={expenses}
                               small={small}
                               showDiffIncomeExpenses={showDiffIncomeExpenses} showDiffCalories={showDiffCalories}/>
    )
}