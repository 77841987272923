import {Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {arrayFieldSum, TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {FormContainer} from "react-hook-form-mui";
import {ExpensesTableForm} from "./partials/ExpensesTableForm";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {ExpensesDialogSum} from "./partials/ExpensesDialogSum";
import {formDialogFlexStyle} from "../../common/form/formFlexStyles";
import {EXPENSES_TYPE} from "./partials/shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faMoneyBillWheat} from "@fortawesome/free-solid-svg-icons";

type ExpensesOverviewProps = {
    interviewId: string
    expensesType: EXPENSES_TYPE
}

const FORM_ID = 'expenses-form'

export function ExpensesOverview({interviewId, expensesType}: ExpensesOverviewProps) {
    const {t} = useTranslation()
    const popperRef = useRef<PopupState | null>()

    const villageData = useTssVillageById(interviewId)
    const fieldName = expensesType === EXPENSES_TYPE.FOOD ? 'purchasesFood' : 'purchasesNonFood'
    const purchasesData = villageData?.[fieldName] ?? []

    const totals = villageData?.total
    const setVillageData = useSetVillageData()
    return (
        <CardDialogAction
            icon={<FontAwesomeIcon icon={expensesType === EXPENSES_TYPE.FOOD ? faMoneyBillWheat : faBagShopping}/>}
            calories={expensesType === EXPENSES_TYPE.FOOD ? totals?.calories_purchases : undefined}
            expenses={expensesType === EXPENSES_TYPE.FOOD ? totals?.cash_expenses_purchases_food : totals?.cash_expenses_purchases_non_food}
            category={expensesType === EXPENSES_TYPE.FOOD ? t('purchasesFood') : t('purchasesNonFood')}
            ref={popperRef}>
            <FormContainer FormProps={{
                id: FORM_ID,
                style: formDialogFlexStyle
            }}
                           defaultValues={{[fieldName]: purchasesData}}
                           onSuccess={(data) => {
                               const fieldData = data[fieldName] || [];
                               const updatedData = {
                                   [fieldName]: fieldData,
                                   total: {
                                       ...villageData?.total,
                                   }
                               };
                               if (expensesType === EXPENSES_TYPE.FOOD) {
                                   updatedData.total.cash_expenses_purchases_food = arrayFieldSum(fieldData, 'total_expenses')
                                   updatedData.total.calories_purchases = arrayFieldSum(fieldData, 'total_kcal')
                               } else {
                                   updatedData.total.cash_expenses_purchases_non_food = arrayFieldSum(fieldData, 'total_expenses')
                               }
                               setVillageData(interviewId, updatedData as unknown as TssVillageData)
                               popperRef.current?.close()
                           }}>
                <DialogContent>
                    <ExpensesTableForm name={fieldName} expensesType={expensesType}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <ExpensesDialogSum name={fieldName}/>
                    <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </FormContainer>
        </CardDialogAction>
    )
}