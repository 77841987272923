import {FormStack} from "../FormStack";
import {TermsSelector} from "../TermsSelector";
import {PurchasePerDurationForm} from "./PurchasePerDurationForm";
import {SwitchElement, TextFieldElement} from "react-hook-form-mui";
import {MeasureSelector} from "../MeasureSelector";
import {Measure_Unit_Type_Enum} from "../../../lib";
import {useTranslation} from "react-i18next";
import {EXPENSES_TYPE} from "../../../tss/expenses/partials/shared";
import {TableCell} from "@mui/material";
import {TextFieldNumberFormat} from "../TextFieldNumberFormat";
import {TERM_CATEGORIES_FILTER_FIXTURES} from "../../../tss/fixtures";

type PurchaseFrequentFormProps = {
    name: string
    expensesType: EXPENSES_TYPE
}


export function PurchaseFrequentForm({name, expensesType}: PurchaseFrequentFormProps) {
    const {t} = useTranslation()
    let isFood = expensesType === EXPENSES_TYPE.FOOD;
    return (
        <TableCell>
            <FormStack direction={'row'} alignItems={'center'}>
                <SwitchElement label={'Is Food?'} name={`${name}.is_food`} value={isFood}
                               sx={{display: 'none'}}/>
                <TermsSelector name={`${name}.term`}
                               rules={{required: t('required')}}
                               autocompleteProps={{size: 'small'}}
                               filterCategories={isFood ?
                                   TERM_CATEGORIES_FILTER_FIXTURES.PURCHASES_FOOD :
                                   TERM_CATEGORIES_FILTER_FIXTURES.PURCHASES_NON_FOOD}
                />
                {!isFood && (
                    <TextFieldElement name={`${name}.description`} label={t('description')} size={'small'}/>
                )}
                {isFood && (
                    <MeasureSelector size={'small'}
                                     label={t('unitOfMeasure')}
                                     measureType={Measure_Unit_Type_Enum.MassPurchases}
                                     name={`${name}.quantity_unit`}
                    />
                )}
                <TextFieldNumberFormat name={`${name}.price`} label={t('pricePerUnit')} size={'small'}/>
                <TextFieldNumberFormat name={`${name}.quantity`} label={t('amountPurchased')} size={'small'}/>

                <PurchasePerDurationForm name={`${name}.duration`} showMonthPicker label={t('per')}/>
            </FormStack>
        </TableCell>
    )
}
