import {Chip} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useWatch} from 'react-hook-form'

export default function TotalAnimalsLabel() {
    const {t} = useTranslation()
    const first = useWatch({
        name: 'animals_other'
    })
    const second = useWatch({
        name: 'breeding_female'
    })
    const count = Number(first || 0) + Number(second || 0)
    if (!count) {
        return null
    }
    return (
        <>
            {t('livestock.totalAnimals', {ns: 'tss'})}
            <br/>
            <Chip label={count} color={'primary'} size={'medium'} sx={{fontSize: 30}}/>
        </>
    )
}
