import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {Alert} from "@mui/material";
import {useTssProjectOneQuery} from "../../../lib/gql/__codegen__/react-query";
import {LoadingScreen} from "../../../theme/components/LoadingScreen";
import {TssLayout} from "../../../theme/layouts/tss";
import TssInterviewList from "../../../tss/InterviewList";
import {PageRoot} from "../../../common/layout/PageRoot";
import {useSearchParams} from "react-router-dom";
import {useIsOnline} from "../../../provider";
import {useTssProjects, useTssState} from "../../../lib";
import {useShallow} from "zustand/react/shallow";

export default function Baseline() {
    const {t} = useTranslation()
    const {isOnline} = useIsOnline()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const {data, isLoading, isFetching} = useTssProjectOneQuery({
        id
    }, {
        enabled: !!id && isOnline,
        gcTime: 0,
        staleTime: 0
    })
    const projectByPk = data?.project_by_pk;
    const setProjectData = useTssState(useShallow(state => state.setProjectData))

    useEffect(() => {
        if (projectByPk && !isFetching && !isLoading) {
            console.log()
            setProjectData(projectByPk)
        }
    }, [projectByPk, setProjectData, isFetching, isLoading]);

    const projects = useTssProjects()
    const project = projects.find(i => i.id === id)
    if ((isLoading || isFetching) && isOnline) {
        return <LoadingScreen/>
    }
    if (!project) {
        return (
            <PageRoot title={t('projectInterviewList')}>
                <TssLayout title={t('projectInterviewList')}>
                    <Alert severity={'warning'}>
                        Your project is not found. You either never fetched it or you are not allowed to access it.
                    </Alert>
                </TssLayout>
            </PageRoot>
        )
    }
    return (
        <PageRoot title={t('projectInterviewList')}>
            {/*<TssLayout headerEndSection={<TssSyncProjectData projectId={project.id}/>}>*/}
            <TssLayout title={t('projectInterviewList')}>
                {project?.project_interviews?.length && (
                    <TssInterviewList projectId={project.id}/>
                )}
            </TssLayout>
        </PageRoot>
    )
}