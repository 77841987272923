import {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
import {LoadingScreen} from "../theme/components/LoadingScreen";
import {useAllVariablesQuery} from "../lib/gql/__codegen__/react-query";
import {Order_By} from "../lib";
import {useIsOnline} from "./OnlineProvider";
import {useAllVariablesState} from "../lib/state/allVariablesState";


export default function AllTssVariableSetup({children}: PropsWithChildren) {
    const {i18n} = useTranslation()
    const locale = i18n.language || 'en'
    const {isOnline} = useIsOnline()

    const ctx = useAllVariablesQuery({
        termOrderBy: {
            [locale]: Order_By.Asc
        },
        measureUnitOrderBy: {
            name: Order_By.Asc
        },
        cropSeasonOrderBy: {
            name: Order_By.Asc
        }
    }, {
        enabled: isOnline
    })


    const {data, isLoading, ...context} = ctx

    if (data?.term && data?.crop_season && data?.measure_unit) {
        useAllVariablesState.getState().initState({
            terms: data.term,
            cropSeasons: data.crop_season,
            measureUnits: data.measure_unit
        })
    }
    if (isLoading && isOnline) {
        return (
            <LoadingScreen/>
        )
    }

    return (
        <>{children}</>
    )
}