import {Avatar, Box, Chip, Stack} from "@mui/material";
import {useWatch} from "react-hook-form";
import {livestockFixtures} from "../../livestockTypes";
import {arrayFieldSum, useCalculateHouseholdNeedsById} from "../../../../lib";
import {useInterviewContext} from "../../../InterviewProvider";
import {useTranslation} from "react-i18next";
import {PopperContainer} from "../../../../common/popper/PopperContainer";
import {TextFieldHiddenInput} from "../../../../common/form/TextFieldHiddenInput";
import {getNumberFormat} from "../../../../lib/helpers/numberFormat";
import {ArrowDropDown} from "@mui/icons-material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBowlRice, faMoneyBillWave} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@mui/material/styles";
import {checkLivestockSalesCategory} from "../../../monthlyCalculations/calculateMonthlyBreakdownLivestock";
import {LivestockFormData} from "../../../../lib/state/tssTypes/tssLivestockTypes";


export function LivestockKcalSummary() {
    const theme = useTheme()
    const {t} = useTranslation()
    const values: LivestockFormData = useWatch<any>()
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)


    if (!values) {
        return null
    }
    const calculateMilk = (key: keyof Pick<LivestockFormData, 'milkProductionOffPeakSecondary' | 'milkProductionPeakSecondary' | 'milkProductionOffPeak' | 'milkProductionPeak'>) => {
        const milking = values[key];
        const income = Number(milking?.total_cash_income || 0)
        const needs = Number(milking?.total_kcal || 0)
        const exchanged = Number(milking?.total_exchanged_kcal || 0)
        return {
            income,
            needs: needs,
            exchanged: exchanged
        }
    }

    const reference = livestockFixtures[values.type]
    const peakMilking = calculateMilk('milkProductionPeak')
    const offPeakMilking = calculateMilk('milkProductionOffPeak')
    const secondaryPeakMilking = calculateMilk('milkProductionPeakSecondary')
    const secondaryOffPeakMilking = calculateMilk('milkProductionOffPeakSecondary')

    const cashIncomeMilk = peakMilking.income + offPeakMilking.income + secondaryPeakMilking.income + secondaryOffPeakMilking.income
    const caloriesMilk = peakMilking.needs + offPeakMilking.needs + secondaryPeakMilking.needs + secondaryOffPeakMilking.needs
    const sales = arrayFieldSum(values.sales, 'total_cash_income')
    const salesSlaughtered = Number(values.meat_quantity_sold || 0) * Number(values.meat_price_per_kg || 1)
    const purchases = arrayFieldSum(values.purchases, 'total_expenses')
    let exchangedForFoodKcal = arrayFieldSum(values.exchanged_for_food?.filter(i => !i.is_resold), 'total_kcal')
    const eggsKcal = values.total_eggs_consumed ? calculate(Number(values.total_eggs_consumed) * 87.8) : {percent: 0}
    const consumed = calculate(reference.meatKcal * Number(values.balance_consumed || 0))
    exchangedForFoodKcal += peakMilking.exchanged + offPeakMilking.exchanged + secondaryPeakMilking.exchanged + secondaryOffPeakMilking.exchanged
    let totalCalories = caloriesMilk + exchangedForFoodKcal + consumed.percent + eggsKcal.percent
    let totalCashIncome = cashIncomeMilk + sales + salesSlaughtered;

    let animalSales = 0
    let animalProductSales = 0
    values.sales?.forEach(item => {
        if (checkLivestockSalesCategory(item.term?.categoryIds)) {
            animalSales += item.total_cash_income
        } else {
            animalProductSales += item.total_cash_income
        }
    })

    return (
        <>

            <TextFieldHiddenInput name={'total_peak_milk_exchanged_calories'}
                                  value={peakMilking.exchanged + secondaryPeakMilking.exchanged}/>
            <TextFieldHiddenInput name={'total_off_peak_milk_exchanged_calories'}
                                  value={offPeakMilking.exchanged + secondaryOffPeakMilking.exchanged}/>
            <TextFieldHiddenInput name={'total_peak_milk_calories'}
                                  value={peakMilking.needs + secondaryPeakMilking.needs}/>
            <TextFieldHiddenInput name={'total_off_peak_milk_calories'}
                                  value={offPeakMilking.needs + secondaryOffPeakMilking.needs}/>
            <TextFieldHiddenInput name={'total_cash_income_milk'} value={cashIncomeMilk}/>
            <TextFieldHiddenInput name={'total_bartered_calories'} value={exchangedForFoodKcal}/>
            <TextFieldHiddenInput name={'total_calories'} value={totalCalories}/>

            <TextFieldHiddenInput name={'total_kcal_calories_consumed_eggs'}
                                  value={consumed.percent + eggsKcal.percent}/>
            <TextFieldHiddenInput name={'total_cash_income'} value={totalCashIncome}/>
            <TextFieldHiddenInput name={'total_cash_expenses'} value={purchases}/>
            <Stack direction={'row'} spacing={2}>
                <Chip color={'info'} variant={'outlined'}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faBowlRice}
                                                                                     color={theme.palette.info.main}/></Avatar>}
                      label={<PopperContainer variant={'popover'}
                                              trigger={{
                                                  variant: 'text',
                                                  color: 'inherit',
                                                  endIcon: <ArrowDropDown/>,
                                                  children: `${totalCalories.toFixed(1)}%`
                                              }}>
                          <Box padding={2}>
                              {`${t('milk')}: ${(caloriesMilk).toFixed(1)}% / ${t('exchanged')}: ${exchangedForFoodKcal.toFixed(1)}% / ${t('other')}: ${(consumed.percent + eggsKcal.percent).toFixed(1)}%`}
                          </Box>
                      </PopperContainer>}/>
                <Chip color={'success'}
                      variant={'outlined'}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faMoneyBillWave}
                                                                                     color={theme.palette.success.dark}/></Avatar>}
                      label={<PopperContainer variant={'popover'}
                                              trigger={{
                                                  variant: 'text',
                                                  color: 'inherit',
                                                  children: `${getNumberFormat(totalCashIncome)}`,
                                                  endIcon: <ArrowDropDown/>
                                              }}>
                          <Box padding={2}>
                              {`${t('milk')}: ${getNumberFormat(cashIncomeMilk)} / ${t('livestockLabel')}: ${getNumberFormat(animalSales)} / ${t('livestockProducts')}: ${getNumberFormat(animalProductSales)} / ${t('other')}: ${getNumberFormat(salesSlaughtered)}`}
                          </Box>
                      </PopperContainer>}/>
                <Chip color={'error'}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faMoneyBillWave}
                                                                                     color={theme.palette.error.main}/></Avatar>}
                      variant={'outlined'}
                      label={getNumberFormat(purchases)}/>

            </Stack>
        </>
    )
}