import {useTranslation} from 'react-i18next'
import {Chip, Stack, Typography} from '@mui/material'
import {useWatch} from 'react-hook-form'
import {DoneAll, WarningAmber} from "@mui/icons-material";
import {TextFieldHiddenInput} from "../../../../common/form/TextFieldHiddenInput";
import {Livestock_Type_Enum, useCalculateHouseholdNeedsById} from "../../../../lib";
import {useInterviewContext} from "../../../InterviewProvider";
import {livestockFixtures} from "../../livestockTypes";

type TotalVolumeDayProps = {
    namePrefix: string
    livestockType: Livestock_Type_Enum
}

export default function TotalVolumeDay({namePrefix, livestockType}: TotalVolumeDayProps) {
    const {t} = useTranslation()
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)

    const milkPerAnimal = useWatch({
        name: `${namePrefix}.milk_per_animal`
    })
    const countOfAnimals = useWatch({
        name: `${namePrefix}.milked_female`
    })
    const unit = useWatch({
        name: `${namePrefix}.measure_unit`
    })

    const consumed = useWatch({
        name: `${namePrefix}.quantity_consumed_day`
    })
    const sold = useWatch({
        name: `${namePrefix}.sold_per_day`
    })
    const givenAway = useWatch({
        name: `${namePrefix}.quantity_given_away`
    })
    const price = useWatch({
        name: `${namePrefix}.price_per_litre`
    })
    const daysMilked = useWatch({
        name: `${namePrefix}.days_milked`
    })
    const isSkim = useWatch({
        name: `${namePrefix}.consumes_skim`
    })

    const exchangedCereal = useWatch({
        name: `${namePrefix}.exchanged_cereal`
    })
    const reference = livestockFixtures[livestockType]


    if (!(milkPerAnimal && countOfAnimals)) {
        return null
    }
    const totalMilkProduced = Number(milkPerAnimal) * Number(countOfAnimals) * Number(unit || 1)
    let totalConsumed = Number(consumed || 0) + Number(sold || 0) + Number(givenAway || 0)
    let unequal = totalMilkProduced.toFixed(1) != totalConsumed.toFixed(1);
    const totalCashIncome = Number(price || 0) * Number(sold || 0) * Number(daysMilked || 0)
    const needs = calculate(
        Number(consumed || 0) * Number(daysMilked || 0) * (isSkim ? reference.milkKcalSkim : reference.milkKcalFull)
    )
    const exchangedKcal = exchangedCereal ? calculate(Number(exchangedCereal || 0) * 3600) : {percent: 0}
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography>{t('livestock.totalMilkVolume', {ns: 'tss'})}</Typography>
            <Chip label={totalMilkProduced.toFixed(1)} color={'primary'} size={'medium'} sx={{fontSize: 30}}/>
            {unequal ? (
                <WarningAmber/>
            ) : (
                <DoneAll/>
            )}
            <Chip label={totalConsumed.toFixed(1)} color={unequal ? 'error' : 'success'}
                  size={'medium'} sx={{fontSize: 30, color: 'white'}}/>
            <TextFieldHiddenInput name={`${namePrefix}.total_consumed`} value={totalConsumed}/>
            <TextFieldHiddenInput name={`${namePrefix}.total_cash_income`} value={totalCashIncome}/>
            <TextFieldHiddenInput name={`${namePrefix}.total_produced`} value={totalMilkProduced}/>
            <TextFieldHiddenInput name={`${namePrefix}.total_kcal`} value={needs.percent}/>
            <TextFieldHiddenInput name={`${namePrefix}.total_exchanged_kcal`} value={exchangedKcal.percent}/>
        </Stack>
    )
}
