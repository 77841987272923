import {Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {FishingWildFoodFormRow} from "./FishingWildFoodFormRow";
import {FishingWildFoodFormRowSum} from "./FishingWildFoodFormRowSum";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {useState} from "react";
import {Delete} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

type FishingWildFoodFormCollapseProps = {
    name: string
    remove: () => void
    iteration: number
}

export function FishingWildFoodFormCollapse({name, remove, iteration}: FishingWildFoodFormCollapseProps) {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation()

    return (
        <>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}} onClick={() => setOpen(o => !o)}
                      selected={!!(iteration % 2)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(event) => {
                            event.preventDefault()
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <FishingWildFoodFormRowSum name={name}/>
                <TableCell align={'right'}>
                    <IconButton
                        onClick={() => {
                            const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                            if (confirmed) {
                                remove()
                            }
                        }}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={6}>
                    <Collapse in={open} timeout={'auto'} unmountOnExit>
                        <FishingWildFoodFormRow name={name}/>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}