import {Month_Enum, TssVillageData} from "../../lib";
import {MonthlyBreakdownData} from "../../lib/state/tssTypes/monthlyBreakdownDataTypes";

export function calculateMonthlyBreakdownNumericBreakdowns(data: TssVillageData, months: {
    [K in Month_Enum]: MonthlyBreakdownData
}, monthsOfPeriod: Month_Enum[]) {
    const referencedMonths = monthsOfPeriod.length
    data?.labour_jobs?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].income.labourAndJob += current.total_cash_income / (current?.duration?.frequency_months_multi?.length || referencedMonths)
            months[m].kcal.labourAndJob += current.total_kcal / (current?.duration?.frequency_months_multi?.length || referencedMonths)
        }
        if (current.duration?.frequency_months_multi?.length) {
            current.duration?.frequency_months_multi?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })

    data.self_employment?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].income.selfEmployment += current.total_cash_income / (current?.duration?.frequency_months_multi?.length || referencedMonths)
        }
        if (current.duration?.frequency_months_multi?.length) {
            current.duration?.frequency_months_multi?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.fishing_wild_food?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].income.wildFood += current.total_cash_income / (current?.duration?.frequency_months_multi?.length || referencedMonths)
            months[m].kcal.wildFood += current.total_kcal / (current?.duration?.frequency_months_multi?.length || referencedMonths)
        }
        if (current.duration?.frequency_months_multi?.length) {
            current.duration?.frequency_months_multi?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.fishing_wild_food_expenses?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].expenditure.wildFood += current.total_expenses / (current?.months?.length || referencedMonths)
        }
        if (current.months?.length) {
            current.months?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.labour_exchange?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].kcal.labourExchange += current.total_kcal / (current?.duration?.frequency_months_multi?.length || referencedMonths)
        }
        if (current.duration?.frequency_months_multi?.length) {
            current.duration?.frequency_months_multi?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.migration?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].kcal.migration += current.total_kcal / (current?.months?.length || referencedMonths)
            months[m].income.migration += current.total_cash_income / (current?.months?.length || referencedMonths)
        }
        if (current.months?.length) {
            current.months?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.remittances_credit?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].income.remittanceAndCredit += current.total_cash_income / (current?.months?.length || referencedMonths)
            months[m].expenditure.remittanceAndCredit += current.total_expenses / (current?.months?.length || referencedMonths)
        }
        if (current.months?.length) {
            current.months?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })
    data.food_aid?.forEach(current => {
        const calculate = (m: Month_Enum) => {
            months[m].income.foodAid += current.total_cash_income / (current?.duration?.frequency_months_multi?.length || referencedMonths)
            months[m].kcal.foodAid += current.total_kcal / (current?.duration?.frequency_months_multi?.length || referencedMonths)
        }
        if (current.duration?.frequency_months_multi?.length) {
            current.duration?.frequency_months_multi?.forEach(calculate)
        } else {
            monthsOfPeriod.forEach(calculate)
        }
    })

    // cereal leftovers
    const cerealLeftoverCalc = (m: Month_Enum) => {
        months[m].kcal.crops.leftoverCerealReserves += Number(data.baseData?.leftover_cereal_total_calories) / (data.baseData?.leftover_cereal_months?.length || referencedMonths)
    }
    if (data.baseData?.leftover_cereal_months?.length) {
        data?.baseData?.leftover_cereal_months?.forEach(cerealLeftoverCalc)
    } else {
        monthsOfPeriod.forEach(cerealLeftoverCalc)
    }
}