import {useWatch} from "react-hook-form";
import {FoodAidData, useCalculateHouseholdNeedsById, useSchoolFeedKcal} from "../../lib";
import {TableCell} from "@mui/material";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {getNumberFormat} from "../../lib/helpers/numberFormat";
import {durationDefaultedCalculation} from "../../lib/helpers/durationCalculator";
import {useInterviewContext} from "../InterviewProvider";

type RemittancesCreditFormTableRowSumProps = {
    name: string
}

export function FoodAidFormTableRowSum({name}: RemittancesCreditFormTableRowSumProps) {
    const values: FoodAidData = useWatch({name})
    const {id} = useInterviewContext()
    const calculateHh = useCalculateHouseholdNeedsById(id)
    const calculate = useSchoolFeedKcal()
    const income = values.total_cash_income
    let kcal = 0
    if (!values.is_school) {
        let aidKcal = values.term?.kcal ?? 0;
        const amountKcal = aidKcal * durationDefaultedCalculation(Number(values.amount) - Number(values.sold_quantity), {duration: values.duration})
        kcal = calculateHh(amountKcal)?.percent || 0
    } else {
        kcal = values.number_children ? calculate(values.number_children) : 0;
    }
    return (
        <>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_kcal`} value={kcal || 0}/>
                {kcal?.toFixed(1) || '-'}%
            </TableCell>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={income || 0}/>
                {income ? getNumberFormat(income) : '-'}
            </TableCell>
        </>
    )
}