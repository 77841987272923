import {DialogContent} from '@mui/material'
import {LivestockReferenceTable} from './LivestockReferenceTable'
import {useTranslation} from 'react-i18next'
import {PopperContainer} from "../../common/popper/PopperContainer";

export function LivestockReferenceTableDialog() {
    const {t} = useTranslation()

    return (
        <PopperContainer popupId={'show-livestock-reference'}
                         dialogProps={{
                             fullWidth: true,
                             maxWidth: false
                         }}
                         trigger={{children: t('showLivestockReference')}}>
            <DialogContent sx={{paddingTop: 0}}>
                <LivestockReferenceTable tableProps={{size: 'small'}}/>
            </DialogContent>
        </PopperContainer>
    )
}
