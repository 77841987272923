import {TextFieldElement, ToggleButtonGroupElement} from "react-hook-form-mui";
import {useFormContext, useWatch} from "react-hook-form";
import {Frequency} from "../../../lib";
import {MonthMultiSelectElement} from "../MonthMultiSelectElement";
import {PopperContainer} from "../../popper/PopperContainer";
import {ThirtyFpsSelect} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import {useEffect} from "react";

type PurchasePerDurationFormProps = {
    name: string
    showMonthPicker?: boolean
    label?: string
}


export function PurchasePerDurationForm({name, showMonthPicker, label}: PurchasePerDurationFormProps) {
    const options = Object.values(Frequency).map(i => ({
        id: i,
        label: i
    }))
    const {setValue} = useFormContext()
    const {t} = useTranslation()
    const typeName = `${name}.frequency_type`;
    const type: Frequency = useWatch({name: typeName})
    const daysName = `${name}.frequency_days`;
    const monthsName = `${name}.frequency_months`;
    const monthsNamePick = `${name}.frequency_months_multi`;
    const showPerDays = type === Frequency.OTHER;
    const showMonths = type !== Frequency.YEAR;
    useEffect(
        () => {
            if (type === Frequency.YEAR) {
                setValue(monthsNamePick, []) // reset months multi if year is selected
            }
        }, [type, setValue, monthsNamePick, monthsName]
    )
    return (
        <PopperContainer variant={'popover'}
                         trigger={{
                             children: label || t('paid'),
                             endIcon: <ThirtyFpsSelect/>
                         }} menuProps={{keepMounted: true}}>
            <Stack spacing={2} padding={2} alignItems={'center'} direction={'row'}>

                <ToggleButtonGroupElement size={'small'} name={typeName} options={options}
                                          exclusive/>
                {showPerDays && (
                    <TextFieldElement name={daysName} label={'Per # days'} size={'small'} sx={{maxWidth: '120px'}}
                                      type={'number'}/>
                )}
                {showMonths && (
                    <>
                        {showMonthPicker ? (
                            <MonthMultiSelectElement name={monthsNamePick} label={'Months'} size={'small'}/>
                        ) : (
                            <TextFieldElement name={monthsName} label={'Months'} size={'small'} sx={{maxWidth: '80px'}}
                                              type={'number'}/>
                        )}
                    </>
                )}
            </Stack>
        </PopperContainer>
    )
}