import {Box, Divider, Stack, Unstable_Grid2 as Grid} from '@mui/material'
import {useTssVillageById} from '../lib'
import {LivestockOverview} from './livestock/LivestockOverview'
import {CropsOverview} from './crops/CropsOverview'
import {ExpensesOverview} from "./expenses/ExpensesOverview";
import {BaseOverview} from "./base/BaseOverview";
import {SelfEmploymentOverview} from "./selfEmployment/SelfEmploymentOverview";
import {FishingWildFoodOverview} from "./fishingWildFood/FishingWildFoodOverview";
import {ManageEmploymentButton} from "./employment/ManageEmploymentButton";
import {RemittancesCreditOverview} from "./remittancesCredit/RemittancesCreditOverview";
import {FoodAidOverview} from "./foodAid/FoodAidOverview";
import {InterviewOverviewTotalSum} from "./InterviewOverviewTotalSum";
import {EXPENSES_TYPE} from "./expenses/partials/shared";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonDigging, faPersonWalkingDashedLineArrowRight} from "@fortawesome/free-solid-svg-icons";
import {LabourExchangeIcon} from "./employment/LabourExchangeIcon";

type InterviewOverviewProps = {
    id: string
    projectId: string
}

export default function InterviewOverview({id, projectId}: InterviewOverviewProps) {
    let village = useTssVillageById(id)
    const total = village?.total

    const hasHhSize = village?.baseData?.people_in_household
    return !hasHhSize ? (
        <Grid xs sm={2}>
            <BaseOverview interviewId={id}/>
        </Grid>
    ) : (
        <>
            <Grid xs sm={2}>
                <Stack direction={'row'} mb={2} justifyContent={'space-between'}>
                    <BaseOverview interviewId={id}/>
                    <InterviewOverviewTotalSum id={id} showDiffCalories showDiffIncomeExpenses/>
                </Stack>
            </Grid>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem/>}
                spacing={2}
            >
                <Box flexGrow={1}>
                    <LivestockOverview interviewId={id}/>
                </Box>
                <Box flexGrow={1}>
                    <CropsOverview interviewId={id}/>
                </Box>
            </Stack>

            <Grid container spacing={2} mt={4}>
                <Grid xs={4}>
                    <ExpensesOverview interviewId={id} expensesType={EXPENSES_TYPE.FOOD}/>
                </Grid>
                <Grid xs={4}>
                    <ExpensesOverview interviewId={id} expensesType={EXPENSES_TYPE.NON_FOOD}/>
                </Grid>
                <Grid xs={4}>
                    <ManageEmploymentButton
                        name={'labourJobs'}
                        icon={<FontAwesomeIcon icon={faPersonDigging}/>}
                        interviewId={id}
                        calories={total?.calories_labour_jobs}
                        income={total?.cash_income_labour_jobs || 0}
                    />
                </Grid>
                <Grid xs={4}>
                    <SelfEmploymentOverview interviewId={id}/>
                </Grid>
                <Grid xs={4}>
                    <FishingWildFoodOverview interviewId={id}/>
                </Grid>

                <Grid xs={4}>
                    <ManageEmploymentButton name={'labourExchange'}
                                            icon={<LabourExchangeIcon/>}
                                            interviewId={id}
                                            calories={total?.calories_labour_exchange}
                    />
                </Grid>
                <Grid xs={4}>
                    <ManageEmploymentButton name={'migration'} interviewId={id}
                                            icon={<FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight}/>}
                                            calories={total?.calories_migration}
                                            income={total?.cash_income_migration}
                    />
                </Grid>
                <Grid xs={4}>
                    <RemittancesCreditOverview interviewId={id}/>
                </Grid>
                <Grid xs={4}>
                    <FoodAidOverview interviewId={id}/>
                </Grid>
            </Grid>
        </>
    )
}
