import {Month_Enum, TssVillageData} from "../../lib";
import {MonthlyBreakdownData} from "../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {EXPENDITURE_CATEGORIES_FILTER_FIXTURES} from "../fixtures";


export function calculateMonthlyBreakdownPurchases(data: {
    food: TssVillageData['purchasesFood'],
    nonFood: TssVillageData['purchasesNonFood']
}, months: {
    [K in Month_Enum]: MonthlyBreakdownData
}, monthsOfPeriod: Month_Enum[]) {
    const referencedMonths = monthsOfPeriod.length

    // todo change how to get the categories out of each value!!!
    const checkStaplesCategory = (categories: string[] | undefined, needle: string) => !!categories?.includes(needle)


    data.food?.forEach(purchased => {
        const calculate = (m: Month_Enum) => {
            if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.staplesFood)) {
                // staples
                months[m].kcal.purchasedFood.staples += purchased.total_kcal / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
                months[m].expenditure.purchasedFood.staples += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else {
                months[m].kcal.purchasedFood.nonStaples += purchased.total_kcal / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
                months[m].expenditure.purchasedFood.nonStaples += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            }
        }
        if (purchased.duration?.frequency_months_multi?.length) {
            purchased.duration?.frequency_months_multi?.forEach(m => calculate(m))
        } else {
            monthsOfPeriod.forEach(m => calculate(m))
        }
    })
    data.nonFood?.forEach(purchased => {
        const calculate = (m: Month_Enum) => {
            if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.clothing)) {
                // staples
                months[m].expenditure.purchasedNonFood.clothing += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.health)) {
                months[m].expenditure.purchasedNonFood.health += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.other)) {
                months[m].expenditure.purchasedNonFood.other += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.education)) {
                months[m].expenditure.purchasedNonFood.education += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.essentialHouseholdItems)) {
                months[m].expenditure.purchasedNonFood.essentialHouseholdItems += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.otherProduction)) {
                months[m].expenditure.purchasedNonFood.otherProduction += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            } else if (checkStaplesCategory(purchased.term?.categoryIds, EXPENDITURE_CATEGORIES_FILTER_FIXTURES.transport)) {
                months[m].expenditure.purchasedNonFood.transport += purchased.total_expenses / (purchased?.duration?.frequency_months_multi?.length || referencedMonths)
            }
        }
        if (purchased.duration?.frequency_months_multi?.length) {
            purchased.duration?.frequency_months_multi?.forEach(m => calculate(m))
        } else {
            monthsOfPeriod.forEach(m => calculate(m))
        }
    })
}