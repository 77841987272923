import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useState} from "react";
import {Tab, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {BreakdownIncomeChartsMonthly} from "./BreakdownIncomeChartsMonthly";
import {BreakdownExpenditureChartsMonthly} from "./BreakdownExpenditureChartsMonthly";
import {BreakdownYearlyTotalChartKcal} from "./BreakdownYearlyTotalChartKcal";
import {BreakdownIncomePieChart} from "./BreakdownIncomePieChart";
import {BreakdownKcalPieChart} from "./BreakdownKcalPieChart";
import {BreakdownKcalMonthly} from "./BreakdownKcalMonthly";
import {BreakdownIncomeExpenditurePieChart} from "./BreakdownExpenditurePieChart";
import {useTranslation} from "react-i18next";
import {BreakdownYearlyTotalChartInocmeExpenditure} from "./BreakdownYearlyTotalChartIncomeExpenditure";
import {BusinessEfficiency} from "./BusinessEfficiency";

type ChartsBreakdownTabsProps = {
    interviewId: string
}

export function ChartsBreakdownTabs({interviewId}: ChartsBreakdownTabsProps) {
    const [value, setValue] = useState('as')
    const {t} = useTranslation()
    return (
        <TabContext value={value}>
            <TabList variant={'fullWidth'}
                     onChange={(_, value) => setValue(value)}
                     aria-label="summary tabs">
                <Tab value={'as'} label={t('annualSummaries')}/>
                <Tab value={'mbc'} label={t('monthlyBreakdownCash')}/>
                <Tab value={'mca'} label={t('monthlyBreakdownCalories')}/>
                <Tab value={'pe'} label={t('crossChecks')}/>
            </TabList>
            <TabPanel value={'as'}>
                <Grid container flex={1} spacing={3} height={'100%'}>
                    <Grid xs={12} sm={6} maxHeight={500}>
                        <Typography variant={'h4'}>{t('calories')}</Typography>
                        <BreakdownYearlyTotalChartKcal interviewId={interviewId}/>
                    </Grid>
                    <Grid xs={12} sm={6} maxHeight={500}>
                        <Typography variant={'h4'}>{t('cashIncome')}/{t('expenditure')}</Typography>
                        <BreakdownYearlyTotalChartInocmeExpenditure interviewId={interviewId}/>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid xs={12} maxHeight={300} mt={4}>
                            <Typography variant={'h4'}>{t('calories')}</Typography>
                            <BreakdownKcalPieChart interviewId={interviewId}/>
                        </Grid>
                        <Grid xs={12} maxHeight={300}>
                            <Typography variant={'h4'}>{t('cashIncome')}</Typography>
                            <BreakdownIncomePieChart interviewId={interviewId}/>
                        </Grid>
                        <Grid xs={12} maxHeight={300}>
                            <Typography variant={'h4'}>{t('expenditure')}</Typography>
                            <BreakdownIncomeExpenditurePieChart interviewId={interviewId}/>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={'mbc'}>
                <Grid container flex={1} height={'100%'} spacing={2}>
                    <Grid xs={12} sm={6} maxHeight={500}>
                        <Typography variant={'h4'}>{t('cashIncome')}</Typography>
                        <BreakdownIncomeChartsMonthly interviewId={interviewId}/>
                    </Grid>
                    <Grid xs={12} sm={6} maxHeight={500}>
                        <Typography variant={'h4'}>{t('expenditure')}</Typography>
                        <BreakdownExpenditureChartsMonthly interviewId={interviewId}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={'mca'}>
                <Grid container flex={1} height={'100%'} spacing={2}>
                    <Grid xs={12} sm={12} maxHeight={500}>
                        <BreakdownKcalMonthly interviewId={interviewId}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={'pe'}>
                <BusinessEfficiency interviewId={interviewId}/>
            </TabPanel>
        </TabContext>
    )
}