import {Badge} from "@mui/material";
import {Message} from "@mui/icons-material";
import {useTssNotesCount} from "./notes/TssNotesProvider";

export function InterviewListItemComments({onlineCount, interviewId}: { onlineCount: number, interviewId: string }) {
    const localCount = useTssNotesCount(interviewId)
    const currentCount = onlineCount < localCount ? localCount : onlineCount
    if (!currentCount) {
        return null
    }
    return (
        <Badge badgeContent={currentCount} color={'warning'}>
            <Message/>
        </Badge>
    )
}