import {TssLayout} from "../theme/layouts/tss";
import {Stack, Typography} from "@mui/material";
import {TotalSummaryTableBtn} from "./monthlyCalculations/TotalSummaryTableBtn";
import {HeaderInterviewMonthlyBreakdownBtn} from "./monthlyCalculations/HeaderInterviewMonthlyBreakdownBtn";
import HeaderInterviewSyncDialog from "./admin/HeaderInterviewSyncDialog";
import {useInterviewContext} from "./InterviewProvider";
import InterviewOverview from "./InterviewOverview";
import {TssVillageData, useOnlineInterviewData, useSetVillageData, useTssProjects, useTssVillageById} from "../lib";
import {useEffect} from "react";
import {InterviewStatusIcon} from "./InterviewStatusIcon";
import TssNotesContainer from "./notes/TssNotesProvider";
import {PageRoot} from "../common/layout/PageRoot";

export default function InterviewOverviewLayout() {
    const {projectId, id} = useInterviewContext()
    return (
        <PageRoot title={'Interview'}>
            <TssLayout
                projectId={projectId}
                title={<InterviewTitle/>}
                headerEndSection={
                    <Stack direction={'row'} spacing={2}>
                        <TssNotesContainer interviewId={id}/>
                        <TotalSummaryTableBtn interviewId={id}/>
                        <HeaderInterviewMonthlyBreakdownBtn interviewId={id}/>
                        <HeaderInterviewSyncDialog/>
                    </Stack>
                }
            >
                <InterviewOverview id={id} projectId={projectId}/>
            </TssLayout>
        </PageRoot>
    )
}

function InterviewTitle() {
    const {projectId, id: interviewId} = useInterviewContext()
    const village = useTssVillageById(interviewId)
    const set = useSetVillageData()
    const projects = useTssProjects()
    const onlineData = useOnlineInterviewData()
    const isTssSetup = village?.id
    const tssDataPartial = projects.find(p => p.id === projectId)
        ?.project_interviews.find(i => i.id === interviewId)

    useEffect(
        () => {
            if (!isTssSetup && tssDataPartial) {
                set(interviewId, tssDataPartial as TssVillageData, true)
            }
        }, [interviewId, isTssSetup, set, tssDataPartial]
    )
    const interviewStatus = onlineData?.interviewStatus
    return (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Typography
                variant={'h4'}>{onlineData.interview?.location?.name + '-' + onlineData.interview?.wealth_group?.en}</Typography>
            <InterviewStatusIcon status={interviewStatus}
                                 is_done={onlineData?.is_done}
                                 hasUploadId={onlineData?.id}/>
        </Stack>
    )
}