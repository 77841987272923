import {useState} from 'react'
import {Alert, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import {AddSeasonButton} from './partials/AddSeasonButton'
import {TssVillageData, useTssVillageById} from '../../lib'
import {CropsDialogForm} from "./partials/CropsDialogForm";
import {CropSeason} from "./cropTypes";
import CropsOverviewSeasonRow from "./partials/CropsOverviewSeasonRow";
import {useTranslation} from "react-i18next";
import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {useInterviewContext} from "../InterviewProvider";
import {useAllCropSeasons} from "../../lib/state/allVariablesState";

type CropsFormContainerProps = CropsFormSeasonsProps & {
    formId: string
    onSuccess: (seasonId: string, data: any) => Promise<void> | void
}

type CropsFormSeasonsProps = {
    initialData?: TssVillageData['crops']
}


export function CropsFormSeasons({formId, initialData, onSuccess}: CropsFormContainerProps) {
    const {id} = useInterviewContext()
    const village = useTssVillageById(id)
    const [activeSeason, setActiveSeason] = useState<CropSeason | null>(null)

    const cropSeasons = useAllCropSeasons()
    const {t} = useTranslation()

    let seasonIds = Object.keys(initialData || {});
    const seasons = seasonIds.map(key => ({
        ...cropSeasons.find(i => i.id === key),
        ...initialData?.[key]
    })) || []
    const seasonsHasCorruptedData = seasons.find(season => !season?.id)

    return (
        <>
            <TableContainer>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'}>
                                <Typography variant={'h5'}>{t('crops.name')}</Typography>
                            </TableCell>
                            <TableCell align={'center'}>
                                <CaloriesIncomeExpense small
                                                       calories={village?.total?.calories_crops?.toFixed(1) ?? 0}/>
                            </TableCell>
                            <TableCell align={'center'}>
                                <CaloriesIncomeExpense small income={village?.total?.cash_income_crops}/>
                            </TableCell>
                            <TableCell>
                                <CaloriesIncomeExpense small expenses={village?.total?.cash_expenses_crops}/>
                            </TableCell>
                            <TableCell align={'right'}>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {seasons.map(season => season ? (
                            <CropsOverviewSeasonRow season={season as any}
                                                    onSuccess={onSuccess}
                                                    key={season.id || season.season?.id}
                                                    setActiveSeason={setActiveSeason}/>
                        ) : null)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box marginTop={2}>
                {seasonsHasCorruptedData && (
                    <Alert severity={'error'} sx={{marginBottom: 2}}>
                        {t('seasonRemoveBrokenSeasonData')}
                    </Alert>
                )}
                <AddSeasonButton selectedSeasons={seasonIds}
                                 onAddSeason={(season) => {
                                     setActiveSeason(season)
                                 }}/>
            </Box>
            {activeSeason?.id && (
                <CropsDialogForm activeSeason={activeSeason}
                                 formId={formId}
                                 initialData={initialData}
                                 onSuccess={onSuccess}
                                 setActiveSeason={setActiveSeason}/>
            )}
        </>
    )
}
