import {useTssResetVillageData, useTssUpdatedAt, useTssVillageById, useUnsetTssUpdatedAt} from "../../lib";
import {Alert, Button, Divider, Stack, Typography} from "@mui/material";
import {CloudDownload, Delete, FileDownload, OfflineBolt} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {TssUploadInterviewCard} from "./TssUploadInterviewCard";
import {useAuthenticationStatus} from "@nhost/react";
import {TssDownloadInterviewCard} from "./TssDownloadInterviewCard";
import {useIsOnline} from "../../provider";
import {TssSelectFileForInterview} from "./TssSelectFileForInterview";
import {PopupState} from "material-ui-popup-state/hooks";
import {useDate} from "../../hooks/useDate";
import {useInterviewContext} from "../InterviewProvider";
import {Link} from "react-router-dom";

type HeaderInterviewSyncContentProps = {
    popupState: PopupState | null
}

export function HeaderInterviewSyncContent({popupState}: HeaderInterviewSyncContentProps) {
    const {id: interviewId} = useInterviewContext()
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const resetVillageData = useTssResetVillageData()
    const {isOnline} = useIsOnline()
    const {isAuthenticated} = useAuthenticationStatus()
    const updatedAtDate = useTssUpdatedAt(interviewId)
    const unset = useUnsetTssUpdatedAt()

    const {getDate} = useDate()
    return (
        <Stack spacing={3}>
            {updatedAtDate && (
                <Typography>
                    {t('lastUpdated')} {getDate(updatedAtDate, {
                    timeStyle: 'short',
                    dateStyle: 'medium',
                })}
                </Typography>
            )}
            {!isOnline ? (
                <Alert icon={<OfflineBolt/>} severity={'info'}>
                    {t('offlineMessage')}
                </Alert>
            ) : (
                <>
                    {isAuthenticated ? (
                        <>
                            <TssUploadInterviewCard popupState={popupState}/>
                            <TssDownloadInterviewCard popupState={popupState}/>
                        </>
                    ) : (
                        <Alert icon={<CloudDownload/>} severity={'info'}>
                            {t('notLoggedInMessageLong')}<br/>
                            <Button component={Link} to={'/auth/login'}>{t('login')}</Button>
                        </Alert>
                    )}
                </>
            )}

            <Divider/>

            <TssSelectFileForInterview/>

            <Alert icon={<FileDownload/>} severity={'warning'}>
                {t('downloadInterviewData')}<br/>
                <Button sx={{marginTop: 2}} startIcon={<FileDownload/>} variant={'outlined'}
                        onClick={() => {
                            downloadObjectAsJson(villageData, `tss_${villageData?.id}`)
                        }}>download</Button>
            </Alert>

            <Alert icon={<Delete/>} severity={'error'}>
                {t('resetInterviewData')}<br/>
                <Button sx={{marginTop: 2}} startIcon={<Delete/>} variant={'outlined'}
                        onClick={() => {
                            const conf = window.confirm(t('resetInterviewData'))
                            if (conf) {
                                if (villageData?.id) {
                                    resetVillageData(villageData.id)
                                    unset(villageData.id)
                                }
                            }
                        }}>delete</Button>
            </Alert>
        </Stack>
    )
}


function downloadObjectAsJson(exportObj: any, exportName: string) {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}