import {Stack, Unstable_Grid2 as Grid} from "@mui/material";
import {useTssState} from "../../lib";
import {useTranslation} from "react-i18next";
import {useUserId} from "@nhost/react";
import {useProjectListTssQuery} from "../../lib/gql/__codegen__/react-query";
import {useIsOnline} from "../../provider";
import {ProjectListItem} from "./ProjectListItem";
import {useShallow} from "zustand/react/shallow";


export default function ProjectList() {
    const {t} = useTranslation()
    const userId = useUserId()
    const {isOnline} = useIsOnline()
    const {data, isLoading, isStale, error} = useProjectListTssQuery({
        where: {
            _or: [
                {
                    teamleader_user_id: {
                        _eq: userId
                    }
                },
                {
                    supervisor_user_id: {
                        _eq: userId
                    }
                },
                {
                    project_interviews: {
                        project_interview_researcher_mns: {
                            user_id: {
                                _eq: userId
                            }
                        }
                    }
                }
            ]
        }
    }, {
        enabled: !!userId
    })
    // console.log({userId})
    const [projects, setProjectData, resetState] = useTssState(useShallow(state => [state.projects, state.setProjectData, state.resetState]))
    if (isLoading && isOnline) {
        return null
        // return <LoadingScreen/>
    }
    const projectIds = projects.map(p => p.id)
    if (error) {
        console.error(error)
    }
    const list = isOnline ? data?.project : projects

    return (
        <Stack spacing={3}>
            <Grid container spacing={2}>
                {(list || []).map(project => {
                    return (
                        <Grid key={project.id} xs sm={4}>
                            <ProjectListItem project={project} isDownloaded={projectIds.includes(project.id)}/>
                        </Grid>
                    )
                })}
            </Grid>
        </Stack>
    )
}