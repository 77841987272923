import {useTranslation} from "react-i18next";
import {TextFieldElement} from "react-hook-form-mui";
import {FormStack} from "../FormStack";
import {MonthMultiSelectElement} from "../MonthMultiSelectElement";
import {TextFieldNumberFormat} from "../TextFieldNumberFormat";

type MigrationFrequencyFormProps = {
    name: string
}

export function MigrationFrequencyForm({name}: MigrationFrequencyFormProps) {
    const {t} = useTranslation()
    return (
        <>
            <FormStack direction={'row'}>
                <TextFieldElement name={`${name}.activity`} label={t('activity')} size={'small'}/>
                <TextFieldElement name={`${name}.location`} label={t('location')} size={'small'}/>
                <TextFieldElement name={`${name}.people`} label={t('numberPeopleHousehold')} size={'small'}/>
                <MonthMultiSelectElement name={`${name}.months`} label={t('months')} size={'small'}/>
                <TextFieldElement name={`${name}.times_money_sent`} label={t('timesMoneySent')} size={'small'}/>
                <TextFieldNumberFormat name={`${name}.amount`} label={t('amountTimes')} size={'small'}/>
            </FormStack>
        </>
    )
}