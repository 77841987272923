import svg from './noun-work-for-food-97466-2.svg'

export function LabourExchangeIcon() {
    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img src={svg}
             alt={'labour exchange'}
             width={36}
             height={36}
        />
    )
}