import {AppBar, Dialog, DialogContent, DialogTitle, IconButton, Toolbar} from "@mui/material";
import {useState} from "react";
import {useProjectAndInterview} from "../../lib";
import {calculateMonthlyBreakdown} from "./calculateMonthlyBreakdown";
import {Close, QueryStats} from "@mui/icons-material";
import {InterviewOverviewTotalSum} from "../InterviewOverviewTotalSum";
import {ChartsBreakdownTabs} from "./charts/ChartsBreakdownTabs";

type HeaderInterviewMonthlyBreakdownBtnProps = {
    interviewId: string
}

export function HeaderInterviewMonthlyBreakdownBtn({interviewId}: HeaderInterviewMonthlyBreakdownBtnProps) {
    const [open, setOpen] = useState(false)
    const {village, project} = useProjectAndInterview(interviewId)
    return (
        <>
            <IconButton color={'inherit'} onClick={() => {
                if (village) {
                    calculateMonthlyBreakdown(village)
                    setOpen(true)
                }
            }}>
                <QueryStats/>
            </IconButton>
            <Dialog open={open}
                    onClose={(_, reason) => {
                        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                            return
                        }
                        setOpen(false)
                    }}
                    fullScreen
            >
                <AppBar position={'relative'} color={'inherit'}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <DialogTitle>Visualize</DialogTitle>
                        <InterviewOverviewTotalSum id={interviewId}/>
                        <IconButton onClick={() => setOpen(false)} color={'inherit'}><Close/></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ChartsBreakdownTabs interviewId={interviewId}/>
                </DialogContent>
            </Dialog>
        </>
    )
}