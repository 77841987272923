import {Box, Collapse, IconButton, Stack, TableCell, TableRow, Typography} from '@mui/material'
import {useState} from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {useTranslation} from 'react-i18next'
import {TextFieldElement} from 'react-hook-form-mui'
import {CropsFormTableRowSum} from './CropsFormTableRowSum'
import {Measure_Unit_Type_Enum} from '../../../lib'
import {UseFieldArrayReturn} from 'react-hook-form'
import {TermsSelector} from "../../../common/form/TermsSelector";
import {CropsOtherUsesForm} from "./CropsOtherUsesForm";
import {Delete} from "@mui/icons-material";
import {TextFieldNumberFormat} from "../../../common/form/TextFieldNumberFormat";
import {MeasureSelector} from "../../../common/form/MeasureSelector";
import {MonthMultiSelectElement} from "../../../common/form/MonthMultiSelectElement";


type CropsFormTableRowProps = {
    name: string
    iteration: number
    remove: UseFieldArrayReturn['remove']
}

export function CropsFormTableRow({name, iteration, remove}: CropsFormTableRowProps) {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    return (
        <>
            <TableRow
                selected={!!(iteration % 2)}
                onClick={() => setOpen(!open)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <CropsFormTableRowSum name={`${name}.${iteration}`}/>
                <TableCell align={'right'}>
                    <IconButton
                        aria-label="delete row"
                        size="small"
                        onClick={() => {
                            const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                            if (confirmed) {
                                remove(iteration)
                            }
                        }}
                    >
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8} sx={{backgroundColor: 'grey.100'}}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{padding: 1}}>
                            <Stack>
                                <Stack padding={2} direction={'row'} spacing={2} alignContent={'center'}
                                       alignItems={'center'}>
                                    <Typography width={160}
                                                align={'right'}
                                                variant={'h6'}>{t('crops.produced')}</Typography>
                                    <TextFieldElement name={`${name}.${iteration}.season_id`} size={'small'}
                                                      sx={{display: 'none'}}/>
                                    <TermsSelector name={`${name}.${iteration}.term`}
                                                   label={t('type')}
                                                   filterCategories={['f50792d4-25cd-4bbd-b7a4-19f096957299', '666d34f2-7982-4ee6-be96-36a01c327f66', '072a7df3-a495-4924-862e-25346a4e2d86']}
                                                   autocompleteProps={{size: 'small', sx: {minWidth: 160}}}/>
                                    <MeasureSelector name={`${name}.${iteration}.produced_unit_measure`}
                                                     size={'small'}
                                                     label={t('unit')}
                                                     measureType={Measure_Unit_Type_Enum.Mass}/>
                                    <TextFieldNumberFormat name={`${name}.${iteration}.produced_quantity`}
                                                           type={'number'}
                                                           label={t('quantityProduced')}
                                                           size={'small'}
                                    />
                                    <MonthMultiSelectElement name={`${name}.${iteration}.months_consumption`}
                                                             size={'small'}
                                                             minWidth={200}
                                                             label={t('monthsOfConsumption')}/>

                                </Stack>
                                <Stack padding={2} direction={'row'} spacing={2} alignContent={'center'}
                                       alignItems={'center'} bgcolor={'white'}>
                                    <Typography width={160}
                                                align={'right'}
                                                variant={'h6'}>{t('crops.sold')}</Typography>
                                    <MeasureSelector name={`${name}.${iteration}.sold_unit_measure`}
                                                     size={'small'}
                                                     label={t('unit')}
                                                     measureType={Measure_Unit_Type_Enum.Mass}/>
                                    <TextFieldNumberFormat name={`${name}.${iteration}.sold_quantity`}
                                                           size={'small'}
                                                           type={'number'}
                                                           label={t('unitsSold')}/>
                                    <TextFieldNumberFormat name={`${name}.${iteration}.sold_price_per_unit`}
                                                           size={'small'}
                                                           type={'number'}
                                                           label={t('pricePerUnit')}/>
                                    <MonthMultiSelectElement name={`${name}.${iteration}.when_sold`} size={'small'}
                                                             label={t('monthsSold')}/>
                                </Stack>
                                <Stack padding={2} direction={'row'} spacing={2}
                                       alignItems={'flex-start'}>
                                    <Typography width={160}
                                                align={'right'}
                                                variant={'h6'}>{t('crops.otherUses')}</Typography>

                                    <CropsOtherUsesForm name={`${name}.${iteration}.other_uses`}/>
                                    <TextFieldElement name={`${name}.${iteration}.losses`}
                                                      size={'small'}
                                                      type={'number'}
                                                      label={t('crops.losses')}/>
                                </Stack>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}
