import {useWatch} from "react-hook-form";
import {RemittancesCreditData} from "../../lib";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {TableCell} from "@mui/material";
import {getNumberFormat} from "../../lib/helpers/numberFormat";

type RemittancesCreditFormTableRowSumProps = {
    name: string
}

export function RemittancesCreditFormTableRowSum({name}: RemittancesCreditFormTableRowSumProps) {
    const values: RemittancesCreditData = useWatch({name})
    const total = Number(values.amount || 0) * (values.months?.length ?? 0)
    const income = values.is_expenditure ? 0 : total
    const expenses = values.is_expenditure ? total : 0
    return (
        <>
            <TableCell>
                {expenses ? getNumberFormat(total) : '-'}
                <TextFieldHiddenInput name={`${name}.total_expenses`} value={expenses}/>
            </TableCell>
            <TableCell>
                {income ? getNumberFormat(total) : '-'}
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={income}/>
            </TableCell>
        </>
    )
}