import {
    BusinessEfficiency,
    getHouseholdNeedOnInterviewId,
    InterviewLivestockMilkFragment,
    Livestock_Type_Enum,
    TssVillageData,
    useTssState
} from "../../lib";
import {livestockFixtures} from "../livestock/livestockTypes";
import {TERM_ID_FIXTURES, TERM_LIVESTOCK_MEAT, TROPICAL_LIVESTOCK_UNIT} from "../fixtures";


export function calculateBusinessEfficiency(data: TssVillageData) {
    const totalHhKcalNeed = getHouseholdNeedOnInterviewId(data.id)
    const project = useTssState.getState().projects.find(i => i.id === data.project_id)
    const mainStaple = project?.term
    const mainStapleKcal = mainStaple?.kcal ?? 3600;

    const totalEfficiency: BusinessEfficiency = {
        crops: {
            sellOffValue: 0,
            efficiencyRatio: 0,
            incomeAssetRatio: 0
        },
        livestock: {
            sellOffValue: 0,
            efficiencyRatio: 0,
            incomeAssetRatio: 0,
            tlu: 0
        }
    }


    const lookupPriceOnId = (id: string) => {
        const foundPurchased = data.purchasesFood?.find(row => row.term?.id === id)
        if (foundPurchased) {
            return foundPurchased.total_expenses / foundPurchased.total_quantity
        }
    }
    const calculateMainStaple = (totalKcalConsumed: number) => totalKcalConsumed / mainStapleKcal

    const mainStaplePrice = lookupPriceOnId(mainStaple?.id) || project?.avg_price_main_staple_kg || 0
    // crops
    if (data?.crops) {
        Object.keys(data?.crops).forEach(id => {
            const currentData = data.crops[id]

            // root and green crop calculation
            const greenCropConsumed = (Number(currentData?.green_crops?.total_kcal ?? 0) / 100) * totalHhKcalNeed
            const rootCropConsumed = (Number(currentData?.root_crops?.total_kcal ?? 0) / 100) * totalHhKcalNeed
            if (mainStaplePrice && greenCropConsumed) {
                totalEfficiency.crops.sellOffValue += (calculateMainStaple(greenCropConsumed) * mainStaplePrice)
            }
            if (mainStaplePrice && rootCropConsumed) {
                totalEfficiency.crops.sellOffValue += (calculateMainStaple(rootCropConsumed) * mainStaplePrice)
            }

            // crop production
            currentData.production.forEach(row => {
                if (row.total_consumed) {
                    if (row.total_sold) {
                        if ((row.total_produced - row.total_sold) > 0) {
                            // add selloff consumed
                            const soldPrice = (row.total_cash_income / row.total_sold) * row.total_consumed
                            totalEfficiency.crops.sellOffValue += soldPrice
                        }
                    } else {
                        const lookupPrice = lookupPriceOnId(row.term?.id)
                        if (lookupPrice) {
                            // add selloff consumed
                            totalEfficiency.crops.sellOffValue += lookupPrice * row.total_consumed
                        } else {
                            const staple = calculateMainStaple(row.total_kcal_consumed)
                            if (mainStaple?.id) {
                                if (mainStaplePrice) {
                                    totalEfficiency.crops.sellOffValue += staple * mainStaplePrice
                                } else {
                                    console.warn('there is no fallback main staple price per KG set.')
                                }
                            } else {
                                console.warn('the baseline has no main staple set up.')
                            }
                        }
                    }
                }
                if (row.total_sold) {
                    totalEfficiency.crops.sellOffValue += row.total_cash_income
                }
            })
        })
    }
    if (data.total?.cash_expenses_crops) {
        totalEfficiency.crops.efficiencyRatio = totalEfficiency.crops.sellOffValue / data.total.cash_expenses_crops
    }
    if (data.baseData?.land_cultivated_cash && data.baseData?.land_cultivated_food) {
        //
        totalEfficiency.crops.incomeAssetRatio = totalEfficiency.crops.sellOffValue / (data.baseData.land_unit_conversion * (Number(data.baseData.land_cultivated_food) + Number(data.baseData.land_cultivated_cash)))
    } else {
        console.warn('there is no land cultivated')
    }

    const getTotalMilkValue = (data: InterviewLivestockMilkFragment, livestockType: Livestock_Type_Enum) => {
        let totalConsumed = Number(data.quantity_consumed_day) * Number(data.days_milked)
        const fixture = livestockFixtures[livestockType];
        if (data.consumes_skim) {
            // skim exception
            totalConsumed = (fixture.milkKcalSkim / fixture.milkKcalFull) * totalConsumed
        }
        const price = Number(data.price_per_litre) || lookupPriceOnId(TERM_ID_FIXTURES.MILK) || 0 // lookup in purchases
        if (price) {
            const totalPrice = totalConsumed * price;
            console.log('inside get total', totalPrice)
            return totalPrice
        }
        const totalPrice = ((totalConsumed * fixture.milkKcalFull) / mainStapleKcal) * mainStaplePrice;
        console.log('inside get total', totalPrice, totalConsumed, fixture.milkKcalFull, mainStapleKcal, mainStaplePrice)
        return totalPrice || 0
    }

    // start livestock
    if (data.livestock) {
        Object.keys(data.livestock).forEach((id) => {
            const currentData = data.livestock[id as Livestock_Type_Enum]
            // total sales
            if (currentData.total_cash_income) {
                totalEfficiency.livestock.sellOffValue += currentData.total_cash_income
                console.log('cash income milk', currentData.total_cash_income)
            }
            // sell of consumed off peak
            if (currentData.milkProductionOffPeak) {
                totalEfficiency.livestock.sellOffValue += getTotalMilkValue(currentData.milkProductionOffPeak, currentData.type)
            }
            // sell of consumed peak
            if (currentData.milkProductionPeak) {
                totalEfficiency.livestock.sellOffValue += getTotalMilkValue(currentData.milkProductionPeak, currentData.type)
            }
            // sell off bartered
            if (currentData.total_bartered_calories) {
                const barteredSellOffValue = (((currentData.total_bartered_calories / 100) * totalHhKcalNeed) / mainStapleKcal) * mainStaplePrice;
                console.log('bartered sell off', barteredSellOffValue)
                totalEfficiency.livestock.sellOffValue += barteredSellOffValue
            }

            const lookupPrice = currentData.meat_price_per_kg || lookupPriceOnId(TERM_LIVESTOCK_MEAT[currentData.type])
            if (currentData.balance_consumed && lookupPrice) {
                // known sell off price
                totalEfficiency.livestock.sellOffValue += Number(currentData.balance_consumed) * Number(lookupPrice)
            } else {
                // we ignore the fact that eggs are part of the calculation
                // fallback to use main staples price
                totalEfficiency.livestock.sellOffValue += (((currentData.total_kcal_calories_consumed_eggs / 100) * totalHhKcalNeed) / mainStapleKcal) * mainStaplePrice
            }

            // land ratio calculation
            totalEfficiency.livestock.tlu += ((currentData.total_start + currentData.total_end) / 2) * TROPICAL_LIVESTOCK_UNIT[currentData.type]
        })
    }

    if (data.total?.cash_expenses_livestock) {
        totalEfficiency.livestock.efficiencyRatio = totalEfficiency.livestock.sellOffValue / data.total.cash_expenses_livestock
    }
    totalEfficiency.livestock.incomeAssetRatio = totalEfficiency.livestock.sellOffValue / totalEfficiency.livestock.tlu

    useTssState.getState().setVillageData(data.id, {
        businessEfficiency: totalEfficiency
    } as TssVillageData)
}