import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ManageInterviewBaseButton} from "./partials/ManageInterviewBaseButton";
import {TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {InterviewOverviewFiles} from "../fileUpload/InterviewOverviewFiles";
import {useSnackbar} from "notistack";

type BaseOverviewProps = {
    interviewId: string
}

export function BaseOverview({interviewId}: BaseOverviewProps) {
    const {t} = useTranslation()
    const village = useTssVillageById(interviewId)
    const baseData = village?.baseData ?? ({} as TssVillageData['baseData'])
    const setVillageData = useSetVillageData()
    const {enqueueSnackbar} = useSnackbar()

    return (
        <Stack spacing={2} width={'fit-content'} marginBottom={3} alignItems={'center'}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={2} alignItems={'center'}>
                <Typography variant={'h5'}>{t('householdProfile')}</Typography>
                <ManageInterviewBaseButton
                    initialData={baseData}
                    onSuccess={(data) => {
                        // @ts-ignore
                        setVillageData(interviewId, {
                            ...village,
                            baseData: data
                        })
                        if (baseData?.people_in_household && (baseData?.people_in_household !== data.people_in_household)) {
                            enqueueSnackbar(t('householdSizeChanged'), {
                                variant: 'warning',
                                persist: true
                            })
                        }
                    }}/>
                <InterviewOverviewFiles interviewId={interviewId}/>

            </Stack>
        </Stack>
    )
}