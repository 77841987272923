import {SelectElement, SelectElementProps} from 'react-hook-form-mui'
import {FieldValues} from 'react-hook-form'
import {useMonthOptions} from "../../hooks/useMonthOptions";


type MonthSelectElementProps<T extends FieldValues> = SelectElementProps<T> & {
    ns?: string
}

export function MonthSelectElement<TFieldValues extends FieldValues>({
                                                                         ns,
                                                                         ...props
                                                                     }: MonthSelectElementProps<TFieldValues>) {
    const options = useMonthOptions(ns)
    return (
        <SelectElement {...props}
                       sx={{
                           minWidth: '120px',

                       }}
                       SelectProps={{
                           MenuProps: {
                               PaperProps: {
                                   sx: {
                                       '& .MuiList-root': {
                                           display: 'grid',
                                           gridTemplateColumns: 'repeat(3, 1fr)',
                                           gap: '4px'
                                       }
                                   }
                               }
                           }
                       }}
                       options={options}/>
    )
}
