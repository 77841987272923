import {Box, SxProps, TableCell} from '@mui/material'
import {useWatch} from 'react-hook-form'
import {CropsInsertInput, useCalculateHouseholdNeedsById} from '../../../lib'
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import {useInterviewContext} from "../../InterviewProvider";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {PopperContainer} from "../../../common/popper/PopperContainer";
import {Warning} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {red} from "@mui/material/colors";

type CropsFormTableRowSumProps = {
    name: string
}

export function CropsFormTableRowSum({name}: CropsFormTableRowSumProps) {
    const {t} = useTranslation()
    const value: CropsInsertInput = useWatch({
        name
    })
    const {id} = useInterviewContext()
    const calculateHousehold = useCalculateHouseholdNeedsById(id)

    if (!value?.term?.label) {
        return (
            <TableCell colSpan={5}>
                Please fill out the form below..
            </TableCell>
        )
    }
    const totalProduced = Number(value.produced_unit_measure || 1) * Number(value.produced_quantity || 0)
    const totalSold = Number(value.sold_unit_measure || 1) * Number(value.sold_quantity || 0)
    const totalGivenAway = value.other_uses?.reduce((previousValue, currentValue) =>
        previousValue + Number(currentValue.given_away_unit || 1) * Number(currentValue.given_away || 0), 0)
    const consumedTemp = totalProduced - totalSold - totalGivenAway
    const currentLoss = consumedTemp * (Number(value.losses || 0) / 100);
    const consumed = consumedTemp - currentLoss
    const totalIncome = Number(value.sold_quantity || 0) * Number(value.sold_price_per_unit || 0);
    const termKcal = Number(value.term?.kcal || 0)
    const kcalConsumed = consumed * termKcal
    const calculatedKcalSum = calculateHousehold(kcalConsumed)
    const calculatedKcal = calculatedKcalSum?.percent || 0
    const calculatedTooHigh = (calculatedKcal / (value.months_consumption?.length ?? 12)) > 8
    const isNegative = consumed < 0 || kcalConsumed < 0
    const sx: SxProps = {
        bgcolor: isNegative ? red[100] : undefined
    }
    return (
        <>
            <TableCell sx={{...sx}}>
                {value.term?.label}
            </TableCell>
            <TableCell sx={{...sx}}>
                <TextFieldHiddenInput name={`${name}.total_produced`} value={totalProduced}/>
                {getNumberFormat(totalProduced)} kg
            </TableCell>
            <TableCell sx={{...sx}}>
                <TextFieldHiddenInput name={`${name}.total_sold`} value={totalSold}/>
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={totalIncome}/>
                {getNumberFormat(totalIncome)} / {getNumberFormat(totalSold)}kg
            </TableCell>
            <TableCell sx={{...sx}}>
                <TextFieldHiddenInput name={`${name}.total_given_away`} value={totalGivenAway}/>
                {getNumberFormat(totalGivenAway)}kg
            </TableCell>
            <TableCell sx={{...sx}}>
                <TextFieldHiddenInput name={`${name}.total_consumed`} value={consumed}/>
                <TextFieldHiddenInput name={`${name}.total_kcal`} value={calculatedKcal}/>
                <TextFieldHiddenInput name={`${name}.total_kcal_consumed`} value={kcalConsumed}/>
                {isNegative && (
                    <Warning sx={{marginRight: 2}} color={'error'}/>
                )}
                {calculatedKcal?.toFixed(1)}% / {getNumberFormat(consumed)}kg
                {calculatedTooHigh && (
                    <PopperContainer variant={'popover'}
                                     triggerIcon={{
                                         color: 'warning',
                                         children: <Warning/>
                                     }}>
                        <Box padding={2}>
                            {t('monthsConsumptionErrorMessage')}
                        </Box>
                    </PopperContainer>
                )}
            </TableCell>
        </>
    )
}
