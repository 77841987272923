import {Box, Button, TableCell, TableRow} from "@mui/material";
import {CropSeason} from "../cropTypes";
import {ArrowRight, Delete, InfoOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {CropSeasonData} from "../../../lib";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {PopperContainer} from "../../../common/popper/PopperContainer";
import {ConfirmButton} from "../../../common/buttons";

type CropsOverviewSeasonRowProps = {
    season: CropSeason & CropSeasonData
    onSuccess: (seasonId: string, data: any) => void | Promise<void>
    setActiveSeason: (season: CropSeason | null) => void
}

export default function CropsOverviewSeasonRow({season, onSuccess, setActiveSeason}: CropsOverviewSeasonRowProps) {
    const {t} = useTranslation()
    const {harvested_crops, total_expenses, total_kcal, total_cash_income} = season

    return (
        <TableRow>
            <TableCell align={'left'}>
                <Button color={'primary'} size={'small'} onClick={() => {
                    setActiveSeason(season as CropSeason)
                }} startIcon={<ArrowRight/>}>
                    {season.name}
                </Button>
            </TableCell>
            <TableCell align={'center'}>
                {((total_kcal || 0)).toFixed(1)}%
            </TableCell>
            <TableCell align={'center'}>
                {getNumberFormat(total_cash_income || 0)}
            </TableCell>
            <TableCell align={'center'}>
                {getNumberFormat(total_expenses || 0)}
            </TableCell>
            <TableCell align={'right'}>
                <PopperContainer
                    variant={'popover'}
                    triggerIcon={{
                        size: 'small',
                        children: <InfoOutlined/>
                    }}>
                    <Box padding={2}>
                        <strong>{t('harvested')}:</strong> {harvested_crops}
                    </Box>
                </PopperContainer>
                <ConfirmButton
                    iconProps={{
                        size: 'small'
                    }}
                    message={t('removeLivestockConfirm')}
                    onConfirm={() => {
                        // either season or season.id because the season data is corrupted due to deleted season
                        onSuccess(season.id || season.season?.id, null)
                    }}>
                    <Delete fontSize={'small'}/>
                </ConfirmButton>
            </TableCell>
        </TableRow>
    )
}