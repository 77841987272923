import {Badge, Button, Drawer, FormControlLabel, FormGroup, Stack, Switch} from "@mui/material";
import {Message} from "@mui/icons-material";
import {PropsWithChildren, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTssNotesList, useTssNotesStore} from "./TssNotesProvider";
import {TssNotesContentCreate,} from "./TssNotesRichtext";
import {TssContentList} from "./TssNotesList";
import {TssNotesSync} from "./TssNotesSync";
import {useShallow} from "zustand/react/shallow";

function TssNotesDrawer({children}: PropsWithChildren) {
    const [open, setOpen] = useState(false)
    // const [showResolved, setShowResolved] = useState(false)
    const {t} = useTranslation()
    const [showResolved, setShowResolved] = useTssNotesStore(useShallow(state => [state.showResolved, state.setShowResolved]))
    const list = useTssNotesList()
    return (
        <>
            <Button color={'inherit'} startIcon={<Message/>}
                    onClick={() => setOpen(val => !val)}>
                <Badge badgeContent={list?.length} color={'warning'}>
                    {t('bssNotesGeneral')}
                </Badge>
            </Button>
            <Drawer anchor={'bottom'}
                    open={open}
                    sx={{
                        '& .MuiPaper-root ul, & .MuiPaper-root ol': {
                            marginLeft: 3
                        }
                    }}
                    onClose={() => setOpen(false)}
            >
                <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} paddingRight={2}
                       paddingTop={1} spacing={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            checked={showResolved}
                            onChange={(_, checked) => {
                                setShowResolved(checked)
                            }}/>} label={t('showResolved')} labelPlacement={'start'}/>
                    </FormGroup>
                    <TssNotesSync/>
                </Stack>
                <Stack padding={2}>
                    {children}
                </Stack>
            </Drawer>
        </>
    )
}


export function TssNotesElement() {
    return (
        <TssNotesDrawer>
            <TssContentList/>
            <TssNotesContentCreate/>
        </TssNotesDrawer>
    )
}