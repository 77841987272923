import {SystemStyleObject, Theme} from '@mui/system'
import {useFieldArray} from 'react-hook-form'
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {Add, Delete} from '@mui/icons-material'
import {TextFieldElement} from 'react-hook-form-mui'
import {FormFieldSummarized} from './FormFieldSummarized'
import {QuantityStepper} from '../QuantityStepper'
import {MonthMultiSelectElement} from '../MonthMultiSelectElement'
import {MeasureSelector} from '../MeasureSelector'
import {TextFieldHiddenInput} from '../TextFieldHiddenInput'
import {Measure_Unit_Type_Enum} from '../../../lib'
import {TermsSelector} from '../TermsSelector'
import {useTranslation} from "react-i18next";
import {TextFieldNumberFormat} from "../TextFieldNumberFormat";

type FormFieldStruct = {
    label?: string
    name: string // important for key of array. should be unique for each formField
    position?: 'left' | 'right' | 'center'
    type?: 'number' | 'terms' | 'stepper' | 'months' | 'multiply' | 'unitVolume' | 'unitWeight' | 'hidden' | 'text'
    multiply?: {
        fields: string[] // name of form fields to multiply
    }
    termsFilterCatgories?: string[] // filter terms
    value?: string | number
}

export type PurchaseSaleTableBaseProps = {
    name: string
    header?: {
        bgcolor?: SystemStyleObject<Theme> | string
    }
    formStruct: FormFieldStruct[]
}

export function PurchaseSaleTableBase({
                                          name,
                                          formStruct,
                                          header
                                      }: PurchaseSaleTableBaseProps) {
    const {t} = useTranslation()
    const {fields, append, remove} = useFieldArray({
        name
    })

    return (
        <TableContainer>
            <Table
                size={'small'} sx={{
                '& .MuiTableCell-root': {
                    borderBottom: 'none'
                }
            }}>
                <TableHead>
                    <TableRow>
                        {formStruct.map(struct => (
                            <TableCell key={struct.name} align={struct.position}>
                                {struct.label}
                            </TableCell>
                        ))}
                        <TableCell align={'right'}>
                            <IconButton onClick={() => {
                                append({title: ''})
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, i) => {
                        return (
                            <TableRow key={field.id} selected={!!(i % 2)}>
                                {formStruct.map(struct => {
                                    return (
                                        <TableCell key={struct.name} align={struct.position}>
                                            {struct.type === 'terms' && (
                                                <TermsSelector name={`${name}.${i}.${struct.name}`}
                                                               rules={{required: t('required')}}
                                                               filterCategories={struct.termsFilterCatgories}
                                                               autocompleteProps={{size: 'small'}}/>
                                            )}
                                            {struct.type === 'number' && (
                                                <TextFieldNumberFormat name={`${name}.${i}.${struct.name}`}
                                                                       size={'small'}
                                                                       type={'number'}/>
                                            )}
                                            {struct.type === 'text' && (
                                                <TextFieldElement name={`${name}.${i}.${struct.name}`}
                                                                  size={'small'}
                                                                  type={'text'} fullWidth/>
                                            )}
                                            {struct.type === 'stepper' && (
                                                <QuantityStepper name={`${name}.${i}.${struct.name}`}/>
                                            )}
                                            {struct.type === 'months' && (
                                                <MonthMultiSelectElement name={`${name}.${i}.${struct.name}`}
                                                                         size={'small'}/>
                                            )}
                                            {struct.type === 'multiply' && (
                                                <FormFieldSummarized
                                                    dependingOnFieldName={`${name}.${i}.term`}
                                                    name={`${name}.${i}.${struct.name}`}
                                                    fieldA={`${name}.${i}.${struct.multiply?.fields[0]}`}
                                                    fieldB={`${name}.${i}.${struct.multiply?.fields[1]}`}/>
                                            )}
                                            {struct.type === 'hidden' && (
                                                <TextFieldHiddenInput name={`${name}.${i}.${struct.name}`}
                                                                      value={struct.value}/>
                                            )}
                                            {['unitWeight', 'unitVolume'].includes(struct.type as string) && (
                                                <MeasureSelector name={`${name}.${i}.${struct.name}`}
                                                                 size={'small'}
                                                                 measureType={struct.type === 'unitWeight' ? Measure_Unit_Type_Enum.Mass : Measure_Unit_Type_Enum.Volume}/>
                                            )}
                                        </TableCell>
                                    )
                                })}
                                <TableCell align={'right'}>
                                    <IconButton onClick={() => {
                                        const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                                        if (confirmed) {
                                            remove(i)
                                        }
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
