import {SwitchElement} from "react-hook-form-mui";
import {IconButton, TableCell, TableRow} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {Measure_Unit_Type_Enum} from "../../../../lib";
import {TermsSelector} from "../../../../common/form/TermsSelector";
import {useTranslation} from "react-i18next";
import {ExchangedFoodTableFormRowSum} from "./ExchangedFoodTableFormRowSum";
import {useWatch} from "react-hook-form";
import {TextFieldNumberFormat} from "../../../../common/form/TextFieldNumberFormat";
import {TERM_CATEGORIES_FILTER_FIXTURES} from "../../../fixtures";
import {MonthMultiSelectElement} from "../../../../common/form/MonthMultiSelectElement";
import {MeasureSelector} from "../../../../common/form/MeasureSelector";
import {QuantityStepper} from "../../../../common/form/QuantityStepper";

type ExchangedFoodTableFormRowProps = {
    name: string
    remove: (i: number) => void
    iteration: number
}

export function ExchangedFoodTableFormRow({name, remove, iteration}: ExchangedFoodTableFormRowProps) {
    const {t} = useTranslation()
    const resold = useWatch({name: `${name}.${iteration}.is_resold`})
    return (
        <TableRow selected={!!(iteration % 2)}>
            <TableCell>
                <QuantityStepper size={'small'} name={`${name}.${iteration}.amount`}/>
            </TableCell>
            <TableCell>
                <MeasureSelector name={`${name}.${iteration}.quantity_unit`} measureType={Measure_Unit_Type_Enum.Mass}
                                 size={'small'}/>
            </TableCell>
            <TableCell>
                <TextFieldNumberFormat size={'small'} name={`${name}.${iteration}.quantity`}/>
            </TableCell>
            <TableCell>
                <TermsSelector name={`${name}.${iteration}.term`}
                               rules={{required: t('required')}}
                               autocompleteProps={{size: 'small'}}
                               filterCategories={TERM_CATEGORIES_FILTER_FIXTURES.LIVESTOCK_EXCHANGED_FOOD}/>
            </TableCell>
            <TableCell>
                {!resold && (
                    <MonthMultiSelectElement name={`${name}.${iteration}.months`} size={'small'}/>
                )}
            </TableCell>
            <TableCell>
                <SwitchElement label={t('resold')} name={`${name}.${iteration}.is_resold`}/>
            </TableCell>
            <TableCell>
                <ExchangedFoodTableFormRowSum name={`${name}.${iteration}`}/>
            </TableCell>
            <TableCell>
                <IconButton
                    onClick={() => {
                        const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                        if (confirmed) {
                            remove(iteration)
                        }
                    }}>
                    <Delete/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}