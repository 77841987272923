import {useTranslation} from 'react-i18next'
import {PurchaseSaleTableBase, PurchaseSaleTableBaseProps} from './PurchaseSaleTableBase'
import {Sale_Purchase_Type_Enum} from "../../../lib";
import {TERM_CATEGORIES_FILTER_FIXTURES} from "../../../tss/fixtures";

type PurchaseSaleTableFormProps = {
    type?: Sale_Purchase_Type_Enum,
    name: string
    enableMonths?: boolean
}

export function PurchaseSaleTableForm({
                                          type = Sale_Purchase_Type_Enum.Purchase,
                                          name,
                                          enableMonths
                                      }: PurchaseSaleTableFormProps) {
    const {t} = useTranslation()

    let isPurchases = type === Sale_Purchase_Type_Enum.Purchase;
    const formStruct: PurchaseSaleTableBaseProps['formStruct'] = [{
        label: isPurchases ? t('purchased', {ns: 'tss'}) : t('sold', {ns: 'tss'}),
        name: 'term',
        type: 'terms',
        termsFilterCatgories: isPurchases ? TERM_CATEGORIES_FILTER_FIXTURES.LIVESTOCK_PURCHASE : TERM_CATEGORIES_FILTER_FIXTURES.LIVESTOCK_SALE
    }, {
        label: t('pricePerUnit', {ns: 'tss'}),
        name: 'price',
        type: 'number'
    }, {
        label: t('quantity', {ns: 'tss'}),
        name: 'quantity',
        type: 'number'
    }, {
        name: 'type',
        type: 'hidden',
        value: type
    }]
    if (enableMonths) {
        formStruct.push({
            name: 'months',
            type: 'months',
            label: t('monthsG', {ns: 'tss'})
        })
    }
    formStruct.push({
        name: isPurchases ? 'total_expenses' : 'total_cash_income',
        label: t('total', {ns: 'tss'}),
        type: 'multiply',
        multiply: {
            fields: ['price', 'quantity']
        }
    })
    return (
        <PurchaseSaleTableBase name={name}
                               formStruct={formStruct}
                               header={{
                                   bgcolor: isPurchases ? 'error.lighter' : 'success.lighter'
                               }}/>
    )
}
