import {AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography} from '@mui/material'
import {Close} from '@mui/icons-material'
import {LivestockFormStepper} from './LivestockFormStepper'
import {useTranslation} from 'react-i18next'
import {arrayFieldSum, Livestock_Type_Enum, TssVillageData, useSetVillageData, useTssVillageById} from '../../lib'
import {LivestockReferenceTableDialog} from './LivestockReferenceTableDialog'
import {LivestockKcalSummary} from "./steps/partials/LivestockKcalSummary";
import {FormProvider, useForm} from "react-hook-form";
import {livestockCalculateTotals} from "./livestockCalculateTotals";
import {LivestockFormData} from "../../lib/state/tssTypes/tssLivestockTypes";
import {LivestockIsAnimalTermId} from "./livestockHelper";

type LivestockFormStepperDialogProps = {
    selectedLivestock: Livestock_Type_Enum
    onClose: () => void
    villageId: string
    initialData?: LivestockFormData
}
const FORM_ID = 'livestock-stepper';


export default function LivestockFormStepperDialog({
                                                       selectedLivestock,
                                                       onClose,
                                                       villageId,
                                                       initialData
                                                   }: LivestockFormStepperDialogProps) {
    const {t} = useTranslation()
    const village = useTssVillageById(villageId)
    const livestockData = village?.livestock
    const setVillageData = useSetVillageData()

    const form = useForm<LivestockFormData>({
        defaultValues: {
            ...initialData,
            type: selectedLivestock
        }
    })
    const {handleSubmit} = form
    const onSubmit = handleSubmit((d) => {
        const purchases = arrayFieldSum(d.purchases.filter(i => LivestockIsAnimalTermId.includes(i.term?.id ?? '')), 'quantity') // filter by ID
        const sold = arrayFieldSum(d.sales.filter(i => LivestockIsAnimalTermId.includes(i.term?.id ?? '')), 'quantity') // filter by ID
        const exchanged = arrayFieldSum(d.exchanged_for_food, 'amount')
        const givenAway = arrayFieldSum(d.animals_given_away, 'amount')
        const losses = Number(d.losses_death || 0)
        const start = Number(d.breeding_female || 0) + Number(d.animals_other || 0)
        const newBirth = Number(d.new_birth || 0) + Number(d.new_birth_secondary || 0);
        const preparedData = {
            ...d,
            total_purchases: purchases, // must filter by ID
            total_sold: sold, //must filter by ID
            total_given_away: givenAway,
            total_exchanged: exchanged,
            total_start: start,
            losses_death: losses,
            new_birth: newBirth,
            total_end: start + purchases + newBirth - givenAway - exchanged - sold - Number(d.slaughtered || 0) - losses
        } as LivestockFormData
        const updatedData = {
            livestock: {
                ...livestockData,
                [selectedLivestock]: preparedData
            }
        } as TssVillageData;
        const totals = {
            total: {
                ...village?.total,
                ...livestockCalculateTotals(updatedData.livestock)
            } as TssVillageData['total'],
        }
        setVillageData(villageId, {...updatedData, ...totals})
        onClose()
    })
    return (
        <Dialog open={!!selectedLivestock}
                fullScreen
                maxWidth={'xl'}>
            <FormProvider {...form}>
                <AppBar position={'relative'}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Typography variant={'h5'}>
                            {selectedLivestock && t(`livestock.type.${selectedLivestock.toLowerCase()}`, {ns: 'tss'})}
                        </Typography>
                        <IconButton onClick={() => onClose()} color={'inherit'}><Close/></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <LivestockFormStepper
                        livestockType={selectedLivestock}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <LivestockKcalSummary/>
                    <LivestockReferenceTableDialog/>
                    <Button form={FORM_ID} onClick={onSubmit} variant={'contained'}>
                        {t('saveClose', {ns: 'tss'})}
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}
