import {useTssVillageById} from "../../../lib";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownIncomeExpenditurePieChartProps = {
    interviewId: string
}

export function BreakdownIncomeExpenditurePieChart({interviewId}: BreakdownIncomeExpenditurePieChartProps) {
    const villageData = useTssVillageById(interviewId)
    const {getExpenditure} = useChartValueHelper()
    const data2 = villageData?.totalOnMonthlyCalculation ? getExpenditure(villageData.totalOnMonthlyCalculation) : []
    return (

        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Tooltip formatter={v => getNumberFormat(v as number)}/>
                <Pie data={data2} dataKey="value" cx="50%" cy="50%"
                     paddingAngle={3}
                     innerRadius={60}
                     outerRadius={80}
                     label={props => {
                         return props.name
                     }}
                     fill="#82ca9d">
                    {data2.map((value, index) => (
                        <Cell key={`cell2-${index}`} fill={value.color}/>
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}