import {useTssVillageById} from "../../../lib";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useTranslation} from "react-i18next";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownYearlyTotalChartProps = {
    interviewId: string
}

export function BreakdownYearlyTotalChartKcal({interviewId}: BreakdownYearlyTotalChartProps) {
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const {getKcal} = useChartValueHelper()

    const kcalData = villageData?.totalOnMonthlyCalculation ? getKcal(villageData.totalOnMonthlyCalculation) : []
    const data = villageData?.totalOnMonthlyCalculation ? [{
        name: t('calories'),
        kcal: villageData.totalOnMonthlyCalculation.kcal
    }] : []
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis stroke="#82ca9d" tickFormatter={v => `${v}%`}/>
                <Tooltip
                    itemSorter={(props) =>
                        // @ts-ignore // is not properly typed
                        Number(props.format)
                    }
                    formatter={(value, name, item) => {
                        if ((item.dataKey as string)?.includes('kcal')) {
                            return (value as number).toFixed(1) + '%'
                        }
                        return getNumberFormat(value as number)
                    }} cursor={{fill: 'transparent'}}/>
                {kcalData.reverse().map((d, i) => (
                    <Bar dataKey={d.key} key={`data3-${i}`}
                         format={d.sort}
                         fill={d.color}
                         stackId={'0'}
                         name={d.name}/>
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}