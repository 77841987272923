import {TextFieldElement, TextFieldElementProps} from 'react-hook-form-mui'
import {IconButton, InputAdornment} from '@mui/material'
import {Add, Remove} from '@mui/icons-material'
import {FieldValues, useFormContext} from 'react-hook-form'

type QuantityStepperProps<T extends FieldValues> = TextFieldElementProps<T>

export function QuantityStepper<TFieldValues extends FieldValues>(props: QuantityStepperProps<TFieldValues>) {
    const {setValue, watch} = useFormContext<TFieldValues>()
    const currentValue: number = Number(watch(props.name)) || 0

    return (
        <TextFieldElement
            {...props}
            size={props.size || 'small'}
            sx={{
                ...props.sx,
                '& .MuiInputBase-root > input': {
                    textAlign: 'center',
                    width: '50px'
                }
            }}
            type={'number'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton
                            aria-label="count quantity down"
                            size={props.size || 'small'}
                            onClick={() => {
                                if (currentValue > 0) {
                                    // @ts-ignore
                                    setValue(props.name, currentValue - 1)
                                }
                            }}
                            edge="end"
                        >
                            <Remove/>
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            size={props.size || 'small'}
                            aria-label="count quantity up"
                            onClick={() => {
                                // @ts-ignore
                                setValue(props.name, currentValue + 1)
                            }}
                            edge="end"
                        >
                            <Add/>
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}
