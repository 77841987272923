import CaloriesIncomeExpense from "../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {arrayFieldSum, RemittancesCreditData} from "../../lib";

export function RemittancesDialogSum() {
    const values: RemittancesCreditData[] = useWatch({name: 'remittances_credit'})
    const expenses = arrayFieldSum(values || [], 'total_expenses')
    const income = arrayFieldSum(values || [], 'total_cash_income')

    return (
        <CaloriesIncomeExpense income={income} expenses={expenses}/>
    )
}