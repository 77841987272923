import {useTssVillageById} from "../../../lib";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useTranslation} from "react-i18next";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownYearlyTotalChartProps = {
    interviewId: string
}

export function BreakdownYearlyTotalChartInocmeExpenditure({interviewId}: BreakdownYearlyTotalChartProps) {
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const {getIncome, getExpenditure} = useChartValueHelper()

    const incomeData = villageData?.totalOnMonthlyCalculation ? getIncome(villageData.totalOnMonthlyCalculation) : []
    const expenditureData = villageData?.totalOnMonthlyCalculation ? getExpenditure(villageData.totalOnMonthlyCalculation) : []
    const data = villageData?.totalOnMonthlyCalculation ? [{
        name: t('cashIncome'),
        income: villageData.totalOnMonthlyCalculation.income
    }, {
        name: t('expenditure'),
        expenditure: villageData?.totalOnMonthlyCalculation.expenditure
    }] : []
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis stroke="#82ca9d" tickFormatter={v => getNumberFormat(v)}/>
                <Tooltip
                    itemSorter={(props) =>
                        // @ts-ignore // is not properly typed
                        Number(props.format)}
                    formatter={(value, name, item) => {
                        if ((item.dataKey as string)?.includes('kcal')) {
                            return (value as number).toFixed(1) + '%'
                        }
                        return getNumberFormat(value as number)
                    }} cursor={{fill: 'transparent'}}/>
                {incomeData.reverse().map((d, i) => (
                    <Bar dataKey={d.key} key={`data2-${i}`} fill={d.color} stackId={'0'} name={d.name} format={d.sort}/>
                ))}
                {expenditureData.reverse().map((d, i) => (
                    <Bar dataKey={d.key} key={`data3-${i}`} fill={d.color} stackId={'0'} name={d.name} format={d.sort}/>
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}