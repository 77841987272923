import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {FoodAidFormTable} from "./FoodAidFormTable";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormContainer} from "react-hook-form-mui";
import {arrayFieldSum, TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {FoodAidDialogSum} from "./FoodAidDialogSum";
import {formDialogFlexStyle} from "../../common/form/formFlexStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuildingNgo} from "@fortawesome/free-solid-svg-icons";

const FORM_ID = 'remittances-credit'

type RemittancesCreditOverviewProps = {
    interviewId: string
}

export function FoodAidOverview({interviewId}: RemittancesCreditOverviewProps) {
    const {t} = useTranslation()
    const popperRef = useRef<PopupState | null>()

    const data = useTssVillageById(interviewId)
    const foodAidData = data?.food_aid
    const totals = data?.total
    const setVillageData = useSetVillageData()

    return (

        <CardDialogAction income={totals?.cash_income_food_aid}
                          calories={totals?.calories_food_aid}
                          category={t('foodAid')}
                          icon={<FontAwesomeIcon icon={faBuildingNgo}/>}
                          ref={popperRef}>
            <FormContainer FormProps={{
                id: FORM_ID,
                style: formDialogFlexStyle
            }}
                           defaultValues={{
                               food_aid: foodAidData
                           }}
                           onSuccess={formData => {
                               const updatedData = {
                                   food_aid: formData.food_aid,
                                   total: {
                                       ...totals,
                                       calories_food_aid: arrayFieldSum(formData.food_aid || [], 'total_kcal'),
                                       cash_income_food_aid: arrayFieldSum(formData.food_aid || [], 'total_cash_income'),
                                   }
                               } as TssVillageData
                               setVillageData(interviewId, updatedData)
                               popperRef.current?.close()
                           }}
            >
                <DialogContent>
                    <FoodAidFormTable name={'food_aid'}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <FoodAidDialogSum/>
                    <Button type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </FormContainer>
        </CardDialogAction>
    )
}