import omit from "lodash/omit";
import {findTermOnId} from "../../lib/state/allVariablesState";
import cleanDeep from "clean-deep";
import {emptyVillageData} from "../../lib/state/tssTypes/emptyVillageData";

import {
    CropSeasonData,
    InterviewDataFragment,
    Project_Interview_Data_Type_Enum,
    TssInterviewDetailQuery,
    TssVillageData
} from "../../lib";

const prepareDataRow = (item: Partial<InterviewDataFragment>) => {
    return {
        ...item,
        ...(item.term_id && ({
            term: findTermOnId(item.term_id),
        })),
        ...(item.food_term_id && ({
            food_term: findTermOnId(item.food_term_id),
        })),
        ...(!item.quantity_unit_id && ({
            quantity_unit_id: ''
        }))
    }
}


export function prepareTssVillageDataSyncDown(data: TssInterviewDetailQuery['project_interview_by_pk']) {

    if (!data) {
        return null
    }

    const tssData: TssVillageData = {
        ...emptyVillageData,
        id: data.id,
        created_at: data.project_interview_base?.created_at,
    }

    if (data.project_interview_base) {
        tssData.baseData = {
            ...omit(data.project_interview_base, ['project_interview_crops_seasons', 'project_interview_livestocks', 'project_interview_total', 'project_interview_data', 'created_at', 'other_productive_assets', 'project_interview_productive_assets']), // remove relations
            leftover_cereal_term: findTermOnId(data.project_interview_base.leftover_cereal_term_id),
            other_productive_assets: data.project_interview_base.project_interview_productive_assets?.map(item => ({
                key: findTermOnId(item.term_id),
                value: item.amount
            })),
        }
        if (data.project_interview_base.project_interview_livestocks?.length) {
            const livestockData: TssVillageData['livestock'] = {} as TssVillageData['livestock']
            data.project_interview_base.project_interview_livestocks.forEach(livestock => {
                const currentInterviewData = livestock.project_interview_data.map(prepareDataRow)

                livestockData[livestock.type] = {
                    ...omit(livestock, ['project_interview_data', 'off_peak_milk', 'peak_milk', 'off_peak_milk_secondary', 'peak_milk_secondary']),
                    sales: currentInterviewData?.filter(item => item.type === Project_Interview_Data_Type_Enum.LivestockSale),
                    purchases: currentInterviewData?.filter(item => item.type === Project_Interview_Data_Type_Enum.LivestockExpenditure),
                    exchanged_for_food: currentInterviewData?.filter(item => item.type === Project_Interview_Data_Type_Enum.LivestockExchanged),
                    animals_given_away: currentInterviewData?.filter(item => item.type === Project_Interview_Data_Type_Enum.LivestockGivenAway),
                    milkProductionPeak: livestock.peak_milk ? {
                        ...livestock.peak_milk,
                        measure_unit_id: livestock.peak_milk?.measure_unit_id || ''
                    } : {},
                    milkProductionOffPeak: livestock.off_peak_milk ? {
                        ...livestock.off_peak_milk,
                        measure_unit_id: livestock.off_peak_milk?.measure_unit_id || ''
                    } : {},
                    milkProductionPeakSecondary: livestock.peak_milk_secondary ? {
                        ...livestock.peak_milk_secondary,
                        measure_unit_id: livestock.peak_milk_secondary?.measure_unit_id || ''
                    } : {},
                    milkProductionOffPeakSecondary: livestock.off_peak_milk_secondary ? {
                        ...livestock.off_peak_milk_secondary,
                        measure_unit_id: livestock.off_peak_milk_secondary?.measure_unit_id || ''
                    } : {}
                }
            })
            tssData.livestock = livestockData
        }
        if (data.project_interview_base.project_interview_crops_seasons?.length) {
            const cropsData: TssVillageData['crops'] = {} as TssVillageData['crops']
            data.project_interview_base.project_interview_crops_seasons.forEach(crops => {
                cropsData[crops.crop_season_id] = {
                    ...omit(crops, ['interview_id', 'project_interview_crops', 'project_interview_data']),
                    production: crops.project_interview_crops.map(item => ({
                        ...item,
                        ...(item.term_id && ({
                            term: findTermOnId(item.term_id),
                        })),
                        sold_unit_measure_id: item.sold_unit_measure_id || '',
                        produced_unit_measure_id: item.produced_unit_measure_id || '',
                    })),
                    expenditures: crops.project_interview_data.map(prepareDataRow),
                    id: crops.crop_season_id,
                } as CropSeasonData
            })
            tssData.crops = cropsData
        }

        if (data.project_interview_base.project_interview_total) {
            tssData.total = {
                ...data.project_interview_base.project_interview_total
            }
        }

        const interviewData = data.project_interview_base.project_interview_data || []
        tssData.purchasesFood = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.ExpenditureFood)
            .map(prepareDataRow)
        tssData.purchasesNonFood = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.ExpenditureNonFood)
            .map(prepareDataRow)
        tssData.labour_exchange = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.LabourExchange)
            .map(prepareDataRow)
        tssData.labour_jobs = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.Employment)
            .map(prepareDataRow)
        tssData.migration = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.Migration)
            .map(prepareDataRow)
        tssData.food_aid = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.FoodAid)
            .map(prepareDataRow)
        tssData.self_employment = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.SelfEmployment)
            .map(prepareDataRow)
        tssData.fishing_wild_food = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.FishingWildFood)
            .map(prepareDataRow)
        tssData.fishing_wild_food_expenses = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.FishingWildFoodExpenses)
            .map(prepareDataRow)
        tssData.remittances_credit = interviewData
            .filter(item => item.type === Project_Interview_Data_Type_Enum.RemittancesCredit)
            .map(prepareDataRow)
    }
    return cleanDeep(tssData, {
        cleanKeys: ['__typename']
    })
}