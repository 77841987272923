import {useTranslation} from "react-i18next";
import {PurchaseSaleTableBase} from "../../common/form/purchaseSaleForm";

type FishingWildFoodFormExpenditureProps = {
    name: string
}

export function FishingWildFoodFormExpenditure({name}: FishingWildFoodFormExpenditureProps) {
    const {t} = useTranslation()
    return (
        <PurchaseSaleTableBase name={name}
                               formStruct={
                                   [{
                                       type: 'terms',
                                       name: 'term',
                                       label: t('item'),
                                       termsFilterCatgories: ['916f8c4f-7131-40ee-b5f7-4b48fbcf5077']
                                   }, {
                                       label: t('units'),
                                       type: 'number',
                                       name: 'amount'
                                   }, {
                                       label: t('priceUnit'),
                                       type: 'number',
                                       name: 'price'
                                   }, {
                                       type: 'months',
                                       name: 'months',
                                       label: t('monthsG')
                                   }, {
                                       type: "multiply",
                                       name: 'total_expenses',
                                       label: t('expenditure'),
                                       multiply: {
                                           fields: ['amount', 'price']
                                       }
                                   }]
                               }/>
    )
}