import {IconButton, TableCell} from "@mui/material";
import {useWatch} from "react-hook-form";
import {ExpenditureData, useCalculateHouseholdNeedsById} from "../../../lib";
import {EXPENSES_TYPE} from "./shared";
import {Delete} from "@mui/icons-material";
import {useInterviewContext} from "../../InterviewProvider";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import {durationDefaultedCalculation} from "../../../lib/helpers/durationCalculator";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";

type ExpensesTableFormRowSumProps = {
    name: string
    expensesType: EXPENSES_TYPE
    remove: () => void
}

export function ExpensesTableFormRowSum({name, expensesType, remove}: ExpensesTableFormRowSumProps) {
    const values: ExpenditureData = useWatch({
        name
    })
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)

    const unitConversion = Number(values.quantity_unit || 0)
    const priceUnit = Number(values.price || 0)
    const amount = durationDefaultedCalculation(Number(values.quantity || 0), {duration: values.duration})
    const fullKg = amount * unitConversion
    const kcal = calculate((values.term?.kcal || 0) * fullKg)
    const expenses = Math.round(amount * priceUnit)
    return (
        <>
            {expensesType === EXPENSES_TYPE.FOOD && (
                <TableCell>
                    <TextFieldHiddenInput name={`${name}.total_quantity`} value={fullKg}/>
                    {getNumberFormat(fullKg)}
                </TableCell>
            )}
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_expenses`} value={expenses}/>
                {getNumberFormat(expenses)}
            </TableCell>

            {expensesType === EXPENSES_TYPE.FOOD && (
                <TableCell>
                    <TextFieldHiddenInput name={`${name}.total_kcal`} value={kcal.percent}/>
                    {kcal.percent.toFixed(1)}%
                </TableCell>
            )}
            <TableCell>
                <IconButton onClick={remove}>
                    <Delete/>
                </IconButton>
            </TableCell>
        </>
    )
}