import {TableRow} from "@mui/material";
import {UseFieldArrayReturn} from "react-hook-form";
import {PurchaseFrequentForm} from "../../../common/form/purchaseSaleForm/PurchaseFrequentForm";
import {ExpensesTableFormRowSum} from "./ExpensesTableFormRowSum";
import {EXPENSES_TYPE} from "./shared";
import {useTranslation} from "react-i18next";


type ExpensesTableFormRowProps = {
    value: any
    remove: UseFieldArrayReturn['remove']
    iteration: number
    name: string
    expensesType: EXPENSES_TYPE
}

export function ExpensesTableFormRow({value, remove, name, iteration, expensesType}: ExpensesTableFormRowProps) {
    const {t} = useTranslation()
    const rowName = `${name}.${iteration}`;
    
    return (
        <TableRow selected={!(iteration % 2)}>
            <PurchaseFrequentForm name={rowName} expensesType={expensesType}/>
            <ExpensesTableFormRowSum
                remove={() => {
                    const confirmed = window.confirm(t('Are you sure you want to delete the row?'))
                    if (confirmed) {
                        remove(iteration)
                    }
                }}
                name={rowName}
                expensesType={expensesType}/>
        </TableRow>
    )
}