import {Typography, TypographyProps} from '@mui/material'
import {useWatch} from 'react-hook-form'
import {TextFieldHiddenInput} from '../TextFieldHiddenInput'
import {getNumberFormat} from "../../../lib/helpers/numberFormat";

type FormFieldSummarizedProps = {
    fieldA: string
    fieldB: string
    isSummary?: boolean
    typography?: TypographyProps
    name?: string
    dependingOnFieldName?: string
}

export function FormFieldSummarized({
                                        fieldA,
                                        fieldB,
                                        isSummary,
                                        typography,
                                        name,
                                        dependingOnFieldName = 'xxx'
                                    }: FormFieldSummarizedProps) {
    const a = useWatch({name: fieldA})
    const b = useWatch({name: fieldB})
    const c = useWatch({name: dependingOnFieldName})
    if (!(a && b)) {
        return null
    }
    let sum = isSummary ? Number(a) + Number(b) : Number(a) * Number(b)
    if (dependingOnFieldName && !c?.id) {
        sum = 0
    }
    return (
        <>
            {name && (
                <TextFieldHiddenInput name={name} value={sum}/>
            )}
            <Typography {...typography}>
                {getNumberFormat(sum)}
            </Typography>
        </>
    )
}
