import {Stack, Typography} from "@mui/material";
import {SwitchElement} from "react-hook-form-mui";

type SwitchWithLabelsProps = {
    leftLabel: string
    rightLabel: string
    name: string
}

export function SwitchWithLabels({rightLabel, leftLabel, name}: SwitchWithLabelsProps) {
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography variant={'body2'}>{leftLabel}</Typography>
            <SwitchElement label={rightLabel} name={name}/>
        </Stack>
    )
}