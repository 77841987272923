import {Alert, CircularProgress} from "@mui/material";
import {CloudDownload, DoneAll, Download} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {
    TssVillageData,
    useSetVillageData,
    useTssUpdatedAt,
    useTssVillageById,
    useUnsetTssUpdatedAt,
    villageBaseDataKeys
} from "../../lib";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {prepareTssVillageDataSyncDown} from "../syncTssDb/prepareTssVillageDataSyncDown";
import omit from "lodash/omit";
import {useDate} from "../../hooks/useDate";
import {PopupState} from "material-ui-popup-state/hooks";
import {useTssInterviewDetailQuery} from "../../lib/gql/__codegen__/react-query";
import {useInterviewContext} from "../InterviewProvider";


type TssDownloadInterviewCardProps = {
    popupState: PopupState | null
}

export function TssDownloadInterviewCard({popupState}: TssDownloadInterviewCardProps) {
    const {id: interviewId} = useInterviewContext()
    const villageData = useTssVillageById(interviewId)
    const {t} = useTranslation()
    const {transformDate} = useDate()

    const {data, isLoading} = useTssInterviewDetailQuery({
        id: interviewId
    }, {
        gcTime: 0,
        staleTime: 0
    })

    const setVillageData = useSetVillageData()
    const unset = useUnsetTssUpdatedAt()
    const isUpdatedTime = useTssUpdatedAt(interviewId)

    const {enqueueSnackbar} = useSnackbar()

    const projectInterviewByPk = data?.project_interview_by_pk;
    const isCurrentlyLoading = isLoading;
    const createdAt = projectInterviewByPk?.project_interview_base?.created_at;
    const isCurrentlyUploaded = !isUpdatedTime && createdAt === villageData?.created_at;

    return (
        <Alert icon={isCurrentlyLoading ? <CircularProgress size={20}/> : <CloudDownload/>} severity={'info'}>
            {createdAt ? t('downloadInterview') : t('downloadInterviewNotAvailable')}<br/>
            {createdAt && (
                <div>
                    <p>{t('uploadedDateAndTime')}: {transformDate(projectInterviewByPk.project_interview_base?.created_at, {
                        timeStyle: 'short',
                        dateStyle: 'medium',
                    })}</p>
                    <LoadingButton sx={{marginTop: 2}}
                                   startIcon={isCurrentlyUploaded ? <DoneAll/> : <Download/>}
                                   variant={'outlined'}
                                   color={isCurrentlyUploaded ? 'success' : 'info'}
                                   loading={isCurrentlyLoading}
                                   onClick={async () => {
                                       const conf = window.confirm(t('confirmDownload'))
                                       if (conf) {
                                           const res = data // await trigger({id: interviewId})
                                           // const projectInterviewByPk = res?.data?.project_interview_by_pk;
                                           if (projectInterviewByPk) {
                                               if (projectInterviewByPk.project_interview_base) {
                                                   const prepared = prepareTssVillageDataSyncDown(projectInterviewByPk)
                                                   if (prepared) {
                                                       const newVillageData = omit({
                                                           ...prepared
                                                       }, villageBaseDataKeys);
                                                       setVillageData(prepared.id, newVillageData as TssVillageData)
                                                       unset(prepared.id)
                                                       popupState?.close()
                                                   } else {
                                                       enqueueSnackbar('Something went wrong. Please contact support team.', {
                                                           variant: 'error'
                                                       })
                                                   }

                                               } else {
                                                   enqueueSnackbar('Currently there is no remote data.', {
                                                       variant: 'error'
                                                   })
                                               }
                                           }
                                           // @ts-ignore
                                           if (res?.error?.message ?? res?.error) {
                                               // @ts-ignore
                                               enqueueSnackbar(res?.error?.message ?? res?.error, {
                                                   variant: 'error'
                                               })
                                           }
                                       }
                                   }}>download</LoadingButton>
                </div>
            )}
        </Alert>
    )
}