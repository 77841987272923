import {Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Month_Enum, useTssVillageById} from "../../../lib";
import {MonthlyBreakdownData} from "../../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {useTranslation} from "react-i18next";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownChartsProps = {
    interviewId: string
}

type PreparedMonthlyData = (MonthlyBreakdownData & {
    name: Month_Enum
    stack: string
})[];

export function BreakdownIncomeChartsMonthly({interviewId}: BreakdownChartsProps) {
    const villageData = useTssVillageById(interviewId)
    const {t} = useTranslation()
    const {getIncome} = useChartValueHelper()
    const monthlyCalculation = villageData?.monthBasedCalculation
    const calc: PreparedMonthlyData = []
    const incomeData = villageData?.totalOnMonthlyCalculation ? getIncome(villageData.totalOnMonthlyCalculation) : []
    if (monthlyCalculation) {
        Object.keys(monthlyCalculation).forEach((cId) => {
            const current = monthlyCalculation[cId as Month_Enum]
            // console.log(current)
            calc.push({
                name: t(`month.${cId}.short`),
                stack: 'income',
                ...current
            })
        })
    }
    // line for expenditure

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                width={500}
                height={300}
                data={calc}
                // margin={{
                //     top: 20,
                //     right: 30,
                //     left: 20,
                //     bottom: 5,
                // }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis tickFormatter={v => getNumberFormat(v)}/>
                <Tooltip
                    itemSorter={(props) =>
                        // @ts-ignore
                        Number(props.format)
                    }
                    formatter={(value) => {
                        return getNumberFormat(value as number)
                    }}
                />

                {incomeData.reverse().map((d, i) => (
                    <Bar dataKey={d.key} key={`data-${i}`}
                         fill={d.color}
                         stackId={'income'} name={d.name}
                         format={d.sort}/>
                ))}
                <Line type={'monotone'} dataKey={'total_expenditure'} stroke={'red'}
                      strokeWidth={2}
                      fill={'rgba(255,0,0,0.5)'}
                      name={t('expenditure')}/>

            </ComposedChart>
        </ResponsiveContainer>
    )
}