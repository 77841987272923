import {useTranslation} from 'react-i18next'
import {Stack, Typography} from '@mui/material'
import TotalAnimalsLabel from './partials/TotalAnimalsLabel'
import {FormStack} from "../../../common/form/FormStack";
import {TextFieldLabelInput} from "../../../common/form/TextFieldLabelInput";

type BasicHerdDataFormProps = {
    hideMilk?: boolean
    hasEggs?: boolean
}

export default function BasicHerdDataForm({hideMilk, hasEggs}: BasicHerdDataFormProps) {
    const {t} = useTranslation()
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={4} alignContent={'center'} justifyContent={'center'}>
            <Typography align={'center'} component={'div'} variant={'body1'}>
                {t('startReferenceYear', {ns: 'tss'})}<br/>
                <TotalAnimalsLabel/>
            </Typography>
            <FormStack direction={'row'}>
                <TextFieldLabelInput label={t('livestock.breedingFemale', {ns: 'tss'})}
                                     name={'breeding_female'}/>

                <TextFieldLabelInput label={t('livestock.allOther', {ns: 'tss'})} name={'animals_other'}/>

            </FormStack>
            <FormStack direction={'row'} sx={{borderLeft: '1px solid #eee'}} paddingLeft={4}>

                <TextFieldLabelInput label={t('livestock.newBirth', {ns: 'tss'})} name={'new_birth'}/>
                <TextFieldLabelInput label={t('livestock.newBirthSecondary', {ns: 'tss'})}
                                     name={'new_birth_secondary'}/>
                {hasEggs && (
                    <TextFieldLabelInput label={t('livestock.eggsConsumed', {ns: 'tss'})}
                                         name={'total_eggs_consumed'}/>
                )}
            </FormStack>
        </Stack>
    )
}
