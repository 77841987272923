import {Stack, Typography} from "@mui/material";
import {RadioButtonGroup, SwitchElement} from "react-hook-form-mui";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";
import {useTranslation} from "react-i18next";
import {GreenCropsContinuousConsumeSum} from "./GreenCropsContinuousConsumeSum";
import {FormFieldWithLabel} from "../../../common/form/FormFieldWithLabel";
import {QuantityStepper} from "../../../common/form/QuantityStepper";
import {TextFieldLabelInput} from "../../../common/form/TextFieldLabelInput";
import {MonthMultiSelectElement} from "../../../common/form/MonthMultiSelectElement";

type CropsGreenCropsConsumeProps = {
    seasonId: string
    name: string
    label: string
}

export default function GreenCropsContinuousConsume({seasonId, name, label}: CropsGreenCropsConsumeProps) {
    const {t} = useTranslation()
    return (
        <Stack spacing={2}>
            <Typography variant={'h6'}>{label}</Typography>
            <Stack direction={'row'} spacing={3} alignContent={'center'} alignItems={'flex-end'}>
                <TextFieldHiddenInput name={`${name}.season_id`} value={seasonId}/>
                <FormFieldWithLabel label={t('crops.people')}>
                    <QuantityStepper name={`${name}.people`}/>
                </FormFieldWithLabel>
                <TextFieldLabelInput label={t('weeks')} name={`${name}.weeks`}/>
                <TextFieldLabelInput label={t('daysPerWeek')} name={`${name}.days_per_week`}/>
                <MonthMultiSelectElement label={t('monthsG')} name={`${name}.months`}
                                         size={'small'}
                                         minWidth={100}/>
                <RadioButtonGroup
                    options={[{
                        label: '1',
                        id: 1
                    }, {
                        label: '2',
                        id: 2
                    }, {
                        label: '3',
                        id: 3
                    }]}
                    name={`${name}.meals_day`}
                    label={t('crops.mealsDay')}
                    row
                    type={"number"}
                />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography variant={'body2'}>{t('crops.fullMeal')}</Typography>
                    <SwitchElement label={t('crops.orSnack')} name={`${name}.is_snack`}/>
                </Stack>
                <GreenCropsContinuousConsumeSum name={name}/>
            </Stack>
        </Stack>
    )
}