import {useFileUploadItem} from "@nhost/react";
import {CircularProgress, ListItem, ListItemText, Stack} from "@mui/material";

export function FileUploadMultiItem({itemRef}: { itemRef: any }) {
    const {name, progress, isUploading, cancel} = useFileUploadItem(itemRef)
    if (progress === 100) {
        return null
    }
    return (
        <ListItem>
            <ListItemText>
                {isUploading ? (
                    <Stack direction={'row'} spacing={2}>
                        <CircularProgress variant={'determinate'} value={progress || 0} size={20}/>
                        <span>
                            {name}
                        </span>
                    </Stack>
                ) : <> {name} </>}
            </ListItemText>
        </ListItem>
    )
}