import CaloriesIncomeExpense from "../../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {calculateHouseholdNeeds, useProjectAndInterview} from "../../../lib";
import {useInterviewContext} from "../../InterviewProvider";
import {TextFieldHiddenInput} from "../../../common/form/TextFieldHiddenInput";

export function LeftoverCerealSum() {
    const {id} = useInterviewContext()
    const {project} = useProjectAndInterview(id)
    const hhSize = useWatch({name: 'people_in_household'})
    const cereal = useWatch({name: 'leftover_cereal_term'})
    const amount = useWatch({name: 'leftover_cereal_amount'})
    const kcal = cereal?.kcal || 0
    if (!kcal || !hhSize) {
        return null
    }
    const num = Number(amount || 0)
    const sum = kcal * num
    const need = calculateHouseholdNeeds({
        hhMember: Number(hhSize || 0),
        kcalPppd: project?.kcal_pppd,
        durationDays: project?.reference_period_duration as number,
    })
    const final = Number((sum / need) * 100)
    return (
        <>
            <TextFieldHiddenInput name={'leftover_cereal_total_calories'} value={final}/>
            <CaloriesIncomeExpense calories={final?.toFixed(1)}/>
        </>
    )
}