import {Box, Divider, IconButton, Stack, Typography} from "@mui/material";
import {
    TssNote,
    useTssNotesCurrentMessage,
    useTssNotesList,
    useTssNotesSetComment,
    useTssNotesSetCurrentMessage
} from "./TssNotesProvider";
import {getUserNameShort} from "../../lib/helpers/userNameHelper";
import {Delete, Done, DoneAll, Edit} from "@mui/icons-material";
import {useUserId} from "@nhost/react";
import {useDate} from "../../hooks/useDate";
import {useTranslation} from "react-i18next";

function TssContentListItem({item}: {
    item: TssNote
}) {
    const userId = useUserId()
    const isOwn = item.user_id === userId
    const {getDate} = useDate()
    const setComment = useTssNotesSetComment()
    const setMessage = useTssNotesSetCurrentMessage()
    const currentEditMessage = useTssNotesCurrentMessage()
    const {t} = useTranslation()

    return (
        <Stack key={item.id} spacing={1}>
            <Divider>
                <Stack direction={'row'}>
                    <Typography variant={'caption'}>
                        <strong>{isOwn ? 'Me' : item.user ? getUserNameShort(item.user) : null}</strong>&nbsp;-&nbsp;
                        {getDate(item.created_at, {
                            timeStyle: 'short',
                            dateStyle: 'short'
                        })}
                    </Typography>
                </Stack>
            </Divider>

            <Stack direction={'row'} spacing={2}>
                <Box flex={1}>
                    <div dangerouslySetInnerHTML={{__html: item.message as string}}/>
                </Box>
                <div>
                    <Stack spacing={1}
                           direction={'row'} justifyContent={'flex-end'}>
                        <IconButton size={'small'}
                                    color={item.is_done ? 'warning' : 'default'}
                                    title={item.is_done ? t('markUnresolved') : t('markResolved')}
                                    onClick={() => {
                                        const conf = window.confirm(t('confirmMarkAsDone'))
                                        if (conf) {
                                            setComment({
                                                ...item,
                                                is_done: !item.is_done
                                            })
                                        }
                                    }} disabled={!!currentEditMessage}>
                            {item.is_done ? <DoneAll fontSize={'small'}/> : <Done fontSize={'small'}/>}
                        </IconButton>
                        <IconButton size={'small'} onClick={() => setMessage(item)} disabled={!!currentEditMessage}>
                            <Edit fontSize={'small'}/>
                        </IconButton>
                        <IconButton
                            size={'small'}
                            color={'error'}
                            disabled={!!currentEditMessage}
                            onClick={() => {
                                const conf = window.confirm(t('confirmDeleteComment'))
                                if (conf) {
                                    setComment({
                                        ...item,
                                        is_done: true
                                    })
                                }
                            }}>
                            <Delete fontSize={'small'}/>
                        </IconButton>
                    </Stack>
                </div>
            </Stack>
        </Stack>
    )
}

export function TssContentList() {
    const list = useTssNotesList()
    return (
        <Stack>
            {list.map((item) => (
                <TssContentListItem item={item} key={item.id}/>
            ))}
        </Stack>
    )
}