import {Alert, Button} from "@mui/material";
import {FileUpload} from "@mui/icons-material";
import omit from "lodash/omit";
import {
    TssVillageData,
    useSetVillageData,
    useTssResetVillageData,
    useUnsetTssUpdatedAt,
    villageBaseDataKeys
} from "../../lib";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {useInterviewContext} from "../InterviewProvider";


export function TssSelectFileForInterview() {
    const {id: interviewId} = useInterviewContext()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {t} = useTranslation()
    const resetVillageData = useTssResetVillageData()
    const setVillageData = useSetVillageData()
    const unset = useUnsetTssUpdatedAt()

    return (
        <Alert icon={<FileUpload/>} severity={'success'}>
            {t('importExternalJsonFile')}<br/>

            <Button
                component="label"
                variant={'outlined'}
                sx={{marginTop: 2}}
                startIcon={<FileUpload/>}
            >
                Select File
                <input
                    type="file"
                    accept="application/json"
                    hidden
                    ref={fileInputRef}
                    onChange={event => {
                        const reader = new FileReader()
                        reader.onload = (ev: any) => {
                            const obj = JSON.parse(ev.target.result)
                            if (typeof obj.id === 'string' && obj?.id && obj?.baseData) {
                                obj.id = interviewId
                                resetVillageData(interviewId)
                                setVillageData(interviewId, omit({
                                    ...obj
                                }, villageBaseDataKeys) as TssVillageData)
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = ''
                                }
                                unset(interviewId)
                            }
                        }
                        reader.readAsText(event.target.files?.[0] as any)
                    }}
                />
            </Button>
        </Alert>
    )
}