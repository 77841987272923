import {useTssVillageById} from "../../../lib";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import React from "react";
import {getNumberFormat} from "../../../lib/helpers/numberFormat";
import {useChartValueHelper} from "./useChartValueHelper";

type BreakdownIncomeExpenditurePieChartProps = {
    interviewId: string
}

export function BreakdownKcalPieChart({interviewId}: BreakdownIncomeExpenditurePieChartProps) {
    const villageData = useTssVillageById(interviewId)
    const {getKcal} = useChartValueHelper()

    const data = villageData?.totalOnMonthlyCalculation ? getKcal(villageData.totalOnMonthlyCalculation) : []
    if (villageData?.totalOnMonthlyCalculation?.total_kcal) {
        if (villageData.totalOnMonthlyCalculation.total_kcal < 100) {
            data.push({
                color: 'white',
                name: 'Missing Calories',
                value: 100 - villageData.totalOnMonthlyCalculation.total_kcal,
                key: '',
                sort: 100
            })
        }
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie data={data}
                     dataKey="value"
                     cx="50%"
                     cy="50%"
                     fill="#8884d8"
                     paddingAngle={3}
                     innerRadius={60}
                     outerRadius={80}
                     label={props => {
                         return props.name
                     }}>
                    {data.map((value, index) => (
                        <Cell key={`cell1-${index}`} fill={value.color}/>
                    ))}
                </Pie>
                <Tooltip formatter={v => getNumberFormat(v as number)}/>
            </PieChart>
        </ResponsiveContainer>
    )
}