import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useFieldArray} from "react-hook-form";
import {ExchangedFoodTableFormRow} from "./ExchangedFoodTableFormRow";
import {Add} from "@mui/icons-material";

type ExchangedFoodTableFormProps = {
    name: string
}

export function ExchangedFoodTableForm({name}: ExchangedFoodTableFormProps) {
    const {fields, append, remove} = useFieldArray({
        name
    })
    const {t} = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('livestock.animalsExchanged')}
                        </TableCell>
                        <TableCell>
                            {t('livestock.unit')}
                        </TableCell>
                        <TableCell>
                            {t('livestock.quantityPerAnimal')}
                        </TableCell>
                        <TableCell>
                            {t('foodItem')}
                        </TableCell>
                        <TableCell>
                            {t('monthsOfConsumption')}
                        </TableCell>
                        <TableCell/>
                        <TableCell>
                            Kcal
                        </TableCell>
                        <TableCell align={'right'}>
                            <IconButton onClick={() => {
                                append({quantity: ''})
                            }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, i) => (
                        <ExchangedFoodTableFormRow name={name}
                                                   iteration={i}
                                                   key={field.id}
                                                   remove={remove}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}