import FormStepper from '../../common/form/FormStepper'
import {useState} from 'react'
import {Box, Collapse} from '@mui/material'
import {useTranslation} from 'react-i18next'
import BasicHerdDataForm from './steps/BasicHerdDataForm'
import MilkProductionForm from './steps/MilkProductionForm'
import SlaughterAndLossesForm from './steps/SlaughterAndLossesForm'
import ExchangedGiftsForm from './steps/ExchangedGiftsForm'
import {Livestock_Type_Enum, Sale_Purchase_Type_Enum} from '../../lib'
import {hasEggLivestockType, hideMilkOnLivestockType} from "./steps/helpers/calculate";
import {LivestockFormData} from "../../lib/state/tssTypes/tssLivestockTypes";
import {useFormState} from "react-hook-form";
import {PurchaseSaleTableForm} from "../../common/form/purchaseSaleForm";

type LivestockFormStepperProps = {
    livestockType: Livestock_Type_Enum
    onSubmit?: (data: LivestockFormData) => void | Promise<void>
}

export function LivestockFormStepper({livestockType}: LivestockFormStepperProps) {
    const {t} = useTranslation()
    const [step, setStep] = useState<number>(0)
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>({})
    const [error, setError] = useState<{
        [k: number]: boolean;
    }>({})
    const hideMilk = hideMilkOnLivestockType(livestockType)
    const hasEggs = hasEggLivestockType(livestockType)
    const {errors} = useFormState()
    return (
        <div>
            <FormStepper
                stepperProps={{
                    activeStep: step,
                    nonLinear: true,
                }}
                completed={completed}
                handleStep={(step) => setStep(step)}
                items={[{
                    label: t('livestock.stepper.basic')
                }, {
                    label: t('livestock.stepper.milkProduction'),
                    disabled: hideMilk
                }, {
                    label: t('livestock.stepper.sales'),
                    error: Array.isArray(errors?.sales) && errors?.sales?.length > 0
                }, {
                    label: t('livestock.stepper.purchase'),
                    error: Array.isArray(errors?.purchases) && errors?.purchases?.length > 0
                }, {
                    label: t('livestock.stepper.exchange'),
                    error: Array.isArray(errors?.exchanged_for_food) && errors?.exchanged_for_food?.length > 0
                }, {
                    label: t('livestock.stepper.slaughter')
                }]}/>
            <Box padding={3}>
                <Collapse in={step === 0}>
                    <div>
                        <BasicHerdDataForm hideMilk={hideMilk} hasEggs={hasEggs}/>
                    </div>
                </Collapse>
                <Collapse in={step === 1}>
                    <div>
                        {!hideMilk && (
                            <MilkProductionForm livestockType={livestockType}/>
                        )}
                    </div>
                </Collapse>
                <Collapse in={step === 2}>
                    <div>
                        <PurchaseSaleTableForm name={'sales'} enableMonths type={Sale_Purchase_Type_Enum.Sale}/>
                    </div>
                </Collapse>
                <Collapse in={step === 3}>
                    <div>
                        <PurchaseSaleTableForm name={'purchases'} enableMonths/>
                    </div>
                </Collapse>
                <Collapse in={step === 4}>
                    <div>
                        <ExchangedGiftsForm/>
                    </div>
                </Collapse>
                <Collapse in={step === 5}>
                    <div>
                        <SlaughterAndLossesForm/>
                    </div>
                </Collapse>
            </Box>
        </div>
    )
}
