import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

type InterviewFetchDataProps = {}

export function InterviewFetchData({}: InterviewFetchDataProps) {
    const {t} = useTranslation()
    // const setInitialState = useTssState(state => state.setInitial)
    return (
        <Typography>
            {t('interviewsNoData')}
        </Typography>
    )
}
