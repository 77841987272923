import {useTranslation} from "react-i18next";
import {Livestock_Type_Enum, useTssVillageById} from "../../lib";

export function useTotalSummaryTable({interviewId}: { interviewId: string }) {
    const {t} = useTranslation()
    const villageData = useTssVillageById(interviewId)
    const totalData: {
        name: string
        income?: number
        expenditure?: number
        calories?: number
        isGroupHeader?: boolean
    }[] = []

    if (villageData?.livestock) {
        totalData.push({
            name: t('livestockLabel'),
            isGroupHeader: true
        })
        Object.keys(villageData.livestock).map(livestock => {
            const data = villageData.livestock[livestock as Livestock_Type_Enum]
            totalData.push({
                name: t(`livestock.type.${livestock.toLowerCase()}`),
                calories: data.total_calories,
                income: data.total_cash_income,
                expenditure: data.total_cash_expenses
            })
        })
    }

    if (villageData?.crops) {
        totalData.push({
            name: t('crops.name'),
            isGroupHeader: true
        })
        Object.keys(villageData.crops).map(crops => {
            const data = villageData.crops[crops]
            totalData.push({
                name: data.harvested_crops ? data.harvested_crops + ' (' + data.season?.name + ')' : data.season?.name,
                calories: data.total_kcal,
                income: data.total_cash_income,
                expenditure: data.total_expenses
            })
        })
        if (villageData?.baseData?.leftover_cereal_total_calories) {
            totalData.push({
                name: t('leftoverCereal'),
                calories: villageData?.baseData?.leftover_cereal_total_calories
            })
        }
    }
    if (villageData?.purchasesFood?.length) {
        totalData.push({
            name: t('purchasesFood'),
            isGroupHeader: true
        })
    }
    villageData?.purchasesFood?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            calories: item.total_kcal,
            expenditure: item.total_expenses
        })
    })
    if (villageData?.purchasesNonFood?.length) {
        totalData.push({
            name: t('purchasesNonFood'),
            isGroupHeader: true
        })
    }
    villageData?.purchasesNonFood?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            calories: item.total_kcal,
            expenditure: item.total_expenses
        })
    })
    if (villageData?.labour_jobs?.length) {
        totalData.push({
            name: t('labourJobs'),
            isGroupHeader: true
        })
    }
    villageData?.labour_jobs?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            calories: item.total_kcal,
            income: item.total_cash_income
        })
    })
    if (villageData?.self_employment?.length) {
        totalData.push({
            name: t('selfEmployment'),
            isGroupHeader: true
        })
    }
    villageData?.self_employment?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            income: item.total_cash_income
        })
    })
    if (villageData?.fishing_wild_food?.length || villageData?.fishing_wild_food_expenses?.length) {
        totalData.push({
            name: t('fishingWildFood'),
            isGroupHeader: true
        })
    }
    villageData?.fishing_wild_food?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            calories: item.total_kcal,
            income: item.total_cash_income
        })
    })
    villageData?.fishing_wild_food_expenses?.forEach(item => {
        totalData.push({
            name: item.term.label,
            expenditure: item.total_expenses
        })
    })
    if (villageData?.labour_exchange?.length) {
        totalData.push({
            name: t('labourExchange'),
            isGroupHeader: true
        })
    }
    villageData?.labour_exchange?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            calories: item.total_kcal,
            income: item.total_cash_income
        })
    })
    if (villageData?.migration?.length) {
        totalData.push({
            name: t('migration'),
            isGroupHeader: true
        })
    }
    villageData?.migration?.forEach(item => {
        totalData.push({
            name: item.activity ?? '',
            calories: item.total_kcal,
            income: item.total_cash_income
        })
    })
    if (villageData?.remittances_credit?.length) {
        totalData.push({
            name: t('remittanceCredit'),
            isGroupHeader: true
        })
    }
    villageData?.remittances_credit?.forEach(item => {
        totalData.push({
            name: item.term?.label,
            income: item.total_cash_income,
            expenditure: item.total_expenses
        })
    })
    if (villageData?.food_aid?.length) {
        totalData.push({
            name: t('foodAid'),
            isGroupHeader: true
        })
    }
    villageData?.food_aid?.forEach(item => {
        totalData.push({
            name: item?.is_school ? t('school') : item.term?.label,
            income: item.total_cash_income,
            calories: item.total_kcal,
        })
    })


    return totalData
}