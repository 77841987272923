import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useFieldArray} from "react-hook-form";
import {Add} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {FishingWildFoodFormCollapse} from "./FishingWildFoodFormCollapse";

type FishingWildFoodFormProps = {
    name: string
}


export function FishingWildFoodForm({name}: FishingWildFoodFormProps) {
    const {t} = useTranslation()
    const {fields, remove, append} = useFieldArray({name})
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        <TableCell>
                            {t('produced')}
                        </TableCell>
                        <TableCell>
                            {t('sold')}
                        </TableCell>
                        <TableCell>
                            {t('cashIncome')}
                        </TableCell>
                        <TableCell>
                            {t('calories')}
                        </TableCell>
                        <TableCell align={'right'}>
                            <IconButton
                                onClick={() => {
                                    append({})
                                }}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, iteration) => (
                        <FishingWildFoodFormCollapse name={`${name}.${iteration}`} key={field.id}
                                                     iteration={iteration}
                                                     remove={() => remove(iteration)}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}