import {TableCell} from "@mui/material";
import {useWatch} from "react-hook-form";
import {ManageEmploymentButtonProps} from "./employmentTypes";
import {EmploymentData, useCalculateHouseholdNeedsById, useProportionalKcalById} from "../../lib";
import {useInterviewContext} from "../InterviewProvider";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {durationCalculator} from "../../lib/helpers/durationCalculator";
import {getNumberFormat} from "../../lib/helpers/numberFormat";

type EmploymentTableFormSumProps = {
    name: string
    type: ManageEmploymentButtonProps['name']
}

export function EmploymentTableFormSum({name, type}: EmploymentTableFormSumProps) {
    const values: EmploymentData = useWatch({name})
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)
    const proportionCalculate = useProportionalKcalById(id)
    let sum: number
    let amountFoodOrSalary = type === 'labourExchange' ? Number(values.food_amount) : Number(values.payment);
    const hhAmount = Number(values.people || 0) * amountFoodOrSalary
    const {frequency, numberMonths, frequencyMonth} = durationCalculator({duration: values.duration})
    if (values.is_area_based) {
        sum = amountFoodOrSalary * numberMonths * frequencyMonth
    } else {
        sum = hhAmount * frequency
    }

    if (type === 'labourExchange') {
        const kcalNeed = calculate((values.food_term?.kcal ?? 0) * sum)
        return (
            <>
                <TableCell>
                    <TextFieldHiddenInput name={`${name}.total_kg`} value={sum}/>
                    {getNumberFormat(sum || 0)}
                </TableCell>
                <TableCell>
                    <TextFieldHiddenInput name={`${name}.total_kcal`} value={kcalNeed.percent}/>
                    {(kcalNeed.percent || 0).toFixed(1)}%
                </TableCell>
            </>
        )
    }

    let kcalNeed = 0
    if (values.meals_included) {
        let obj = {
            householdMember: Number(values.people),
            mealsDay: 1,
            numberWeeks: frequency / 7
        };
        kcalNeed = proportionCalculate(obj)
    }
    return (
        <>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_cash_income`} value={sum}/>
                {getNumberFormat(sum)}
            </TableCell>
            <TableCell>
                <TextFieldHiddenInput name={`${name}.total_kcal`} value={kcalNeed}/>
                {kcalNeed.toFixed(1)}%
            </TableCell>
        </>
    )
}