import {bindDialog, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {forwardRef, MutableRefObject, PropsWithChildren, ReactNode} from "react";
import {
    AppBar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Dialog,
    IconButton,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import CaloriesIncomeExpense from "../../tss/shared/CaloriesIncomeExpense";

type CardDialogActionProps = PropsWithChildren<{
    income?: number
    category: string
    calories?: number
    expenses?: number
    backgroundColor?: string
    icon?: ReactNode
}>


export const CardDialogAction = forwardRef(function CardD({
                                                              children,
                                                              category,
                                                              income,
                                                              calories,
                                                              expenses,
                                                              backgroundColor,
                                                              icon
                                                          }: CardDialogActionProps, ref) {
    const popupState = usePopupState({
        variant: 'dialog',
        popupId: category
    })

    if (ref) {
        (ref as MutableRefObject<any>).current = popupState
    }
    return (
        <>
            <Card variant={'outlined'}
                  sx={{
                      ...(backgroundColor && ({
                          backgroundColor: backgroundColor,
                          color: 'white'
                      }))
                  }}>
                <CardActionArea {...bindTrigger(popupState)} sx={{
                    minHeight: 110,
                }}>
                    <CardContent sx={{padding: 2}}>
                        <Stack spacing={4} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack>
                                <Typography variant={'h5'} marginBottom={1}>
                                    {category}
                                </Typography>
                                <CaloriesIncomeExpense calories={calories?.toFixed(1)}
                                                       income={income}
                                                       expenses={expenses}
                                                       small
                                                       stackProps={{
                                                           justifyContent: 'flex-start'
                                                       }}
                                />
                            </Stack>
                            {icon && (
                                <Box sx={{
                                    '& .svg-inline--fa': {
                                        fontSize: 32
                                    }
                                }}>
                                    {icon}
                                </Box>
                            )}
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog
                fullScreen={true}
                disableEscapeKeyDown
                {...bindDialog(popupState)}
                onClose={(ev, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return
                    }
                    popupState.close()
                }}
                PaperProps={{
                    sx: {
                        minWidth: '95vw',
                        '& .MuiDialogContent-root': {
                            maxHeight: 'calc(100vh - 128px)',
                        }
                    }
                }}>
                <AppBar position={'relative'} color={'primary'} variant={'outlined'} elevation={0}>
                    <Toolbar sx={{justifyContent: 'space-between'}}>
                        <Typography variant={'h6'}>{category}</Typography>
                        <IconButton onClick={() => popupState.close()} color={'inherit'}><Close/></IconButton>
                    </Toolbar>
                </AppBar>
                {children}
            </Dialog>
        </>
    )

})
