import {useTranslation} from "react-i18next";
import {TssLayout} from "../../../theme/layouts/tss";
import Settings from "../../../theme/components/settings/Settings";
import {PageRoot} from "../../../common/layout/PageRoot";

export default function SettingsPage() {
    const {t} = useTranslation()
    return (
        <PageRoot title={t('user.settings.main')}>
            <TssLayout>
                <Settings/>
            </TssLayout>
        </PageRoot>
    )
}