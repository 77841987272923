import {Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Month_Enum, useTssVillageById} from "../../../lib";
import {useTranslation} from "react-i18next";
import {useChartValueHelper} from "./useChartValueHelper";
import {MonthlyBreakdownData} from "../../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {useState} from "react";
import {FormStack} from "../../../common/form/FormStack";

type PreparedMonthlyData = (MonthlyBreakdownData & {
    name: Month_Enum
    stack: string
})[];


type BreakdownKcalMonthlyProps = {
    interviewId: string
}

export function BreakdownKcalMonthly({interviewId}: BreakdownKcalMonthlyProps) {
    const [hideFoodAid, setHide] = useState(false)
    const [complexVersion, setComplexVersion] = useState(false)
    const villageData = useTssVillageById(interviewId)
    const {t} = useTranslation()
    const {getKcal} = useChartValueHelper()
    const monthlyCalculation = villageData?.monthBasedCalculation
    const calc: PreparedMonthlyData = []
    const kcalData = villageData?.totalOnMonthlyCalculation ? getKcal(villageData.totalOnMonthlyCalculation) : []
    if (monthlyCalculation) {
        Object.keys(monthlyCalculation).forEach((cId) => {
            const current = monthlyCalculation[cId as Month_Enum]
            // console.log(current)
            calc.push({
                name: t(`month.${cId}.short`),
                stack: 'income',
                ...current
            })
        })
    }
    return (
        <>
            <FormStack direction={'row'}>
                <FormGroup>
                    <FormControlLabel control={<Switch
                        value={complexVersion}
                        onChange={(_, checked) => {
                            setComplexVersion(checked)
                        }}/>} label={t('showDetails')}/>
                </FormGroup>
                <FormGroup>
                    <FormControlLabel control={<Switch
                        value={hideFoodAid}
                        onChange={(_, checked) => {
                            setHide(checked)
                        }}/>} label={t('hideFoodAid')}/>
                </FormGroup>
            </FormStack>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={calc}
                    stackOffset="sign"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="5 5"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip
                        itemSorter={(props) =>
                            // @ts-ignore
                            Number(props.format)
                        }
                        formatter={v => (v as number).toFixed(1) + '%'}/>
                    {kcalData.reverse().map((d, i) => (d.key.includes('foodAid') && hideFoodAid) ? null : (
                        <Bar key={`data-${i}`} dataKey={d.key}
                             fill={complexVersion ? d.color : '#009688'}
                             stroke={complexVersion ? d.color : '#009688'}
                             stackId={'kcal'}
                             format={d.sort}
                             name={d.name}/>
                    ))}
                    <ReferenceLine y={8.33} stroke="#000"
                                   label={{
                                       value: t('monthlyCaloriesNeeds'),
                                       position: 'insideBottomLeft',
                                       fill: '#000'
                                   }}
                                   strokeWidth={2}
                                   strokeOpacity={1}/>
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}