import {Stack} from "@mui/material";
import {TextFieldElement} from "react-hook-form-mui";
import {TermsSelector} from "../../common/form/TermsSelector";
import {LabourDurationFrequencyForm} from "../../common/form/purchaseSaleForm/LabourDurationFrequencyForm";
import {useTranslation} from "react-i18next";
import {TextFieldNumberFormat} from "../../common/form/TextFieldNumberFormat";
import {FormStack} from "../../common/form/FormStack";

type FishingWildFoodFormRowProps = {
    name: string
}

export function FishingWildFoodFormRow({name}: FishingWildFoodFormRowProps) {
    const {t} = useTranslation()
    return (
        <>
            <Stack spacing={2} bgcolor={'grey.100'} padding={2}>

                <FormStack direction={'row'} alignItems={'center'}>
                    <TermsSelector name={`${name}.term`}
                                   rules={{required: t('required')}}
                                   filterCategories={['199298cf-62b2-4108-b3ce-0f4dd64dd619', 'fdb2cda5-824b-41a1-af86-5b7751241b2b']}
                                   autocompleteProps={{size: 'small', sx: {minWidth: '220px'}}} label={t('activity')}/>
                    <TextFieldNumberFormat name={`${name}.quantity`} size={'small'}
                                           label={t('quantityProduced')}/>
                    <TextFieldElement name={`${name}.quantity_unit`} label={t('customUnit')} type={'number'}
                                      size={'small'} sx={{maxWidth: '120px'}}/>


                    <LabourDurationFrequencyForm label={t('per')} name={`${name}.duration`} showMonthPicker hideYear/>
                </FormStack>
                <FormStack direction={'row'} alignItems={'center'}>

                    <TextFieldNumberFormat name={`${name}.sold_quantity`} label={t('unitsSold')} type={'number'}
                                           size={'small'} sx={{maxWidth: '120px'}}/>
                    <TextFieldNumberFormat name={`${name}.sold_unit`} label={t('customUnit')} type={'number'}
                                           size={'small'} sx={{maxWidth: '120px'}}/>
                    <TextFieldNumberFormat name={`${name}.price`} label={t('price')} type={'number'}
                                           size={'small'} sx={{maxWidth: '120px'}}/>

                </FormStack>
            </Stack>
        </>
    )
}