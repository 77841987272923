import {SelectElement, SelectElementProps, TextFieldElement, TextFieldElementProps} from 'react-hook-form-mui'
import {Measure_Unit_Type_Enum, useInterviewCountryId, useTssVillageById} from '../../lib'
import {Stack} from '@mui/material'
import {TextFieldHiddenInput} from './TextFieldHiddenInput'
import {FieldValues, useWatch} from 'react-hook-form'
import {useTranslation} from "react-i18next";
import {useAllMeasureUnits} from "../../lib/state/allVariablesState";
import {UNDEFINED_UUID} from "../../lib/config";
import {useInterviewContext} from "../../tss/InterviewProvider";

type MeasureSelectorInputValueProps = {
    name: string
    list: {
        id: string
        label: string
        multiplier: number
    }[]
    size?: TextFieldElementProps['size']
    measureType: Measure_Unit_Type_Enum | Measure_Unit_Type_Enum[]
}

function MeasureSelectorInputValue({name, list, size, measureType}: MeasureSelectorInputValueProps) {
    const {t} = useTranslation()
    const value = useWatch({
        name: `${name}_id`
    })
    const unit = list.find(item => item?.id === value)?.multiplier
    return (
        <>
            {value === '_other' || value === UNDEFINED_UUID ? (
                <TextFieldElement size={size}
                                  name={name}
                                  label={measureType === Measure_Unit_Type_Enum.LandSize ? t('customArea') : t('customUnit')}
                                  sx={{minWidth: 80}}/>
            ) : (
                <TextFieldHiddenInput name={name} value={unit || 1}/>
            )}
        </>
    )
}

type MeasureSelectorProps<T extends FieldValues> = SelectElementProps<T> & {
    measureType: Measure_Unit_Type_Enum | Measure_Unit_Type_Enum[]
}

export function MeasureSelector<TFieldValues extends FieldValues>({
                                                                      measureType,
                                                                      name,
                                                                      ...props
                                                                  }: MeasureSelectorProps<TFieldValues>) {
    const {id} = useInterviewContext()
    const interview = useTssVillageById(id)
    // let countryKey = interview?.location?.country_region?.country?.id
    // console.log({countryKey, interview})
    const countryKey = useInterviewCountryId()

    const units = useAllMeasureUnits()
    const options = (units || [])
        .filter(item => {
            if (measureType) {
                if (Array.isArray(measureType)) {
                    if (!measureType.includes(item.type)) {
                        return false
                    }
                } else {
                    if (measureType !== item.type) {
                        return false
                    }
                }
            }
            if (countryKey) {
                if (!(!item.country_id || item.country_id === countryKey)) {
                    return false
                }
            } else {
                return item.country_id === null
            }
            return true
        })
        .map(item => {
            return {
                id: item.id,
                label: item.name,
                multiplier: item.multiplier
            }
        })
    options.push({
        id: UNDEFINED_UUID,
        label: 'Other',
        multiplier: null
    })
    return (
        <Stack spacing={2} direction={'row'}>
            <SelectElement {...props}
                           name={`${name}_id` as any}
                           options={options}
                           sx={{minWidth: '120px', ...props.sx}}
                           InputProps={{
                               sx: {
                                   minWidth: '120px'
                               }
                           }}
            />
            <MeasureSelectorInputValue
                measureType={measureType}
                name={name}
                list={options || []}
                size={props.size}/>
        </Stack>
    )
}
