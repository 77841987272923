export function replaceDeepNestedValue(obj: any, findValue: string, replaceValue: string): any {
    if (Array.isArray(obj)) {
        return obj.map(item => replaceDeepNestedValue(item, findValue, replaceValue));
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc: any, [key, value]) => {
            acc[key] = replaceDeepNestedValue(value, findValue, replaceValue);
            return acc;
        }, {});
    } else if (obj === findValue) {
        return replaceValue;
    }

    return obj;
}

