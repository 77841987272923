import {ReactNode} from "react";

export type ManageEmploymentButtonProps = {
    name: 'labourJobs' | 'labourExchange' | 'migration'
    interviewId: string
    calories?: number
    income?: number
    icon?: ReactNode
}

export const labelFieldPropMap: { [k in ManageEmploymentButtonProps['name']]: string } = {
    labourExchange: 'labour_exchange',
    migration: 'migration',
    labourJobs: 'labour_jobs',
}