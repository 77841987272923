import {useTranslation} from "react-i18next";
import {SwitchWithLabels} from "../../common/form/SwitchWithLabels";
import {TermsSelector} from "../../common/form/TermsSelector";
import {SwitchElement, TextFieldElement} from "react-hook-form-mui";
import {useWatch} from "react-hook-form";
import {PurchasePerDurationForm} from "../../common/form/purchaseSaleForm/PurchasePerDurationForm";
import {TextFieldHiddenInput} from "../../common/form/TextFieldHiddenInput";
import {TextFieldNumberFormat} from "../../common/form/TextFieldNumberFormat";
import {FormStack} from "../../common/form/FormStack";

type RemittancesCreditFormTableRowProps = {
    name: string
}

export function FoodAidFormTableRow({name}: RemittancesCreditFormTableRowProps) {
    const {t} = useTranslation()
    const isSchool = useWatch({name: name + '.is_school'})
    const isSold = useWatch({name: name + '.is_resold'})

    return (
        <>
            <FormStack direction={'row'} alignItems={'center'}>
                <SwitchWithLabels name={`${name}.is_school`} leftLabel={t('household')}
                                  rightLabel={t('school')}/>
                {isSchool ? (
                    <>
                        <TextFieldElement name={`${name}.number_children`}
                                          size={'small'}
                                          label={t('numberChildren')}/>
                    </>
                ) : (
                    <>
                        <TextFieldHiddenInput name={`${name}.number_children`} value={0}/>
                        <TermsSelector name={`${name}.term`} label={t('type')}
                                       rules={{required: t('required')}}
                                       filterCategories={['3222baf3-8f4f-421f-a200-eced33c72312', '2cea27af-c69c-4dd1-9fe6-32d9e4779bbe']}
                                       autocompleteProps={{size: 'small'}}/>
                        <TextFieldNumberFormat name={`${name}.amount`} label={t('amountKg')} type={'number'}
                                               size={'small'}/>
                        <PurchasePerDurationForm name={`${name}.duration`} showMonthPicker label={t('per')}/>
                        <SwitchElement label={t('isSold')} name={`${name}.is_resold`}/><br/>

                    </>
                )}
                {isSold ? (
                    <>
                        <TextFieldElement name={`${name}.description`} label={t('description')}
                                          size={'small'}/>
                        <TextFieldElement name={`${name}.sold_quantity`} label={t('amountKg')}
                                          size={'small'}/>
                        <TextFieldElement name={`${name}.total_cash_income`} label={t('cashIncome')}
                                          size={'small'}/>
                    </>
                ) : (
                    <>
                        <TextFieldHiddenInput name={`${name}.description`} value={''}/>
                        <TextFieldHiddenInput name={`${name}.sold_quantity`} value={''}/>
                        <TextFieldHiddenInput name={`${name}.total_cash_income`} value={''}/>
                    </>
                )}
            </FormStack>

        </>
    )
}