import {useWatch} from "react-hook-form";
import {multiplyObjectFields, useCalculateHouseholdNeedsById} from "../../../../lib";
import {useInterviewContext} from "../../../InterviewProvider";
import {TextFieldHiddenInput} from "../../../../common/form/TextFieldHiddenInput";
import {Warning} from "@mui/icons-material";
import {Box, Stack} from "@mui/material";
import {PopperContainer} from "../../../../common/popper/PopperContainer";
import {useTranslation} from "react-i18next";
import {LivestockFormData} from "../../../../lib/state/tssTypes/tssLivestockTypes";

type ExchangedFoodTableFormRowSumProps = {
    name: string
}


export function ExchangedFoodTableFormRowSum({name}: ExchangedFoodTableFormRowSumProps) {
    const {t} = useTranslation()
    const values: LivestockFormData['exchanged_for_food'][number] = useWatch({
        name
    })
    const {id} = useInterviewContext()
    const calculate = useCalculateHouseholdNeedsById(id)
    const kcal = !values.is_resold
        ? (values.term?.kcal ?? 0) * multiplyObjectFields(values, ['quantity', 'quantity_unit', 'amount'])
        : 0
    const x = kcal ? calculate(kcal) : {percent: 0}
    const isTooMuchKcal = (x.percent / (values.months?.length ?? 12)) > 8
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <TextFieldHiddenInput name={`${name}.total_kcal`} value={x.percent}/>
            {x.percent.toFixed(1)}%
            {isTooMuchKcal && (
                <PopperContainer variant={'popover'}
                                 triggerIcon={{
                                     color: 'warning',
                                     children: <Warning/>
                                 }}>
                    <Box padding={2}>
                        {t('monthsConsumptionErrorMessage')}
                    </Box>
                </PopperContainer>
            )}
        </Stack>
    )
}