import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {FormContainer} from "react-hook-form-mui";
import {arrayFieldSum, TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {FishingWildFoodTabs} from "./FishingWildFoodTabs";
import {formDialogFlexStyle} from "../../common/form/formFlexStyles";
import {FishingWildFoodDialogSum} from "./FishingWildFoodDialogSum";
import {faFish} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {cleanMissingTermIds} from "../syncTssDb/cleanMissingTermIds";

type FishingWildFoodOverviewProps = {
    interviewId: string
}

const FORM_ID = 'fishing-wild-food'

export function FishingWildFoodOverview({interviewId}: FishingWildFoodOverviewProps) {
    const {t} = useTranslation()
    const village = useTssVillageById(interviewId)
    const totals = village?.total
    const popperRef = useRef<PopupState | null>(null)
    const setVillageData = useSetVillageData()

    return (
        <CardDialogAction income={totals?.cash_income_fishing_wild_food}
                          calories={totals?.calories_fishing_wild_food}
                          category={t('fishingWildFood')}
                          icon={<FontAwesomeIcon icon={faFish}/>}
                          expenses={totals?.cash_expenses_wild_food}
                          ref={popperRef}>
            <FormContainer
                FormProps={{
                    id: FORM_ID,
                    style: formDialogFlexStyle
                }}
                defaultValues={{
                    'fishing_wild_food': village?.fishing_wild_food,
                    'fishing_wild_food_expenses': village?.fishing_wild_food_expenses
                }}
                onSuccess={({fishing_wild_food, fishing_wild_food_expenses}) => {
                    const cleanedData = {
                        fishing_wild_food: cleanMissingTermIds(fishing_wild_food),
                        fishing_wild_food_expenses: cleanMissingTermIds(fishing_wild_food_expenses)
                    }
                    const updatedValues = {
                        ...cleanedData,
                        total: {
                            ...village?.total,
                            calories_fishing_wild_food: arrayFieldSum(cleanedData.fishing_wild_food || [], 'total_kcal'),
                            cash_income_fishing_wild_food: arrayFieldSum(cleanedData.fishing_wild_food || [], 'total_cash_income'),
                            cash_expenses_wild_food: arrayFieldSum(cleanedData?.fishing_wild_food_expenses || [], 'total_expenses')
                        }
                    } as TssVillageData;

                    setVillageData(interviewId, updatedValues)
                    popperRef.current?.close()
                }}>
                <DialogContent>
                    <FishingWildFoodTabs/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <FishingWildFoodDialogSum/>
                    <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </FormContainer>
        </CardDialogAction>
    )
}