import {TermsSelector} from "../../common/form/TermsSelector";
import {useTranslation} from "react-i18next";
import {SwitchElement, TextFieldElement} from "react-hook-form-mui";
import {useWatch} from "react-hook-form";
import {LabourDurationFrequencyForm} from "../../common/form/purchaseSaleForm/LabourDurationFrequencyForm";
import {Stack, Typography} from "@mui/material";
import {Frequency} from "../../lib";
import {TextFieldNumberFormat} from "../../common/form/TextFieldNumberFormat";
import {FormStack} from "../../common/form/FormStack";
import {MonthMultiSelectElement} from "../../common/form/MonthMultiSelectElement";


export enum PAYMENT_TYPE {
    'FOOD' = 'FOOD', 'SALARY' = 'SALARY',
}


type LabourPaymentFormTableProps = {
    name: string
    type?: PAYMENT_TYPE
}

export function LabourPaymentFormTable({
                                           name,
                                           type = PAYMENT_TYPE.SALARY
                                       }: LabourPaymentFormTableProps) {
    const {t} = useTranslation()
    let paymentTerm = `${name}.is_area_based`;
    const term = useWatch({name: paymentTerm})
    const frequencyType: Frequency = useWatch({name: `${name}.duration.frequency_type`})
    return (
        <FormStack direction={'row'} alignItems={'center'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography variant={'body2'}>{t('time')}</Typography>
                <SwitchElement label={t('area')} name={`${name}.is_area_based`}/>
            </Stack>
            <TermsSelector name={`${name}.term`} autocompleteProps={{size: 'small'}} label={t('typeOfWork')}
                           rules={{required: t('required')}}
                           filterCategories={['e280b0dc-d3bd-4ade-b8c9-4e7a9eb5e83e', 'e5a557a7-a634-437b-b7cb-d784d205b459', '59152174-75ba-4ea5-ab57-3449a1763fc7', '28871568-bc92-4934-b39f-1c2a042f6cc0', '07d23ed4-2e43-40cb-8d70-05a043f44e7f', 'd7c71147-d298-4b62-aac2-5c8a86310b88', '02728d85-7620-430c-8d97-12abd3dbbe21', 'c484a3e0-995b-4d0f-aa9b-7d4e67c93646']}/>
            <TextFieldElement name={`${name}.description`} label={t('description')} size={'small'}/>

            {!term ? (
                <>
                    <TextFieldElement name={`${name}.people`} label={t('numberPeopleHousehold')} type={'number'}
                                      size={'small'}/>
                    {type === PAYMENT_TYPE.SALARY ? (
                        <TextFieldNumberFormat name={`${name}.payment`} label={t('payment')}
                                               size={'small'}/>
                    ) : (
                        <>
                            <TermsSelector name={`${name}.food_term`}
                                           filterCategories={['f50792d4-25cd-4bbd-b7a4-19f096957299']}
                                           autocompleteProps={{size: 'small'}}
                                           label={t('typeFood')}/>
                            <TextFieldNumberFormat name={`${name}.food_amount`} type={'number'}
                                                   size={'small'} label={t('amountFood')}/>
                        </>
                    )}
                    <LabourDurationFrequencyForm name={`${name}.duration`} showMonthPicker/>
                    {type !== PAYMENT_TYPE.FOOD && frequencyType === Frequency.DAY && (
                        <SwitchElement label={t('mealsIncluded')} name={`${name}.meals_included`}/>
                    )}
                </>
            ) : (
                <>
                    {type === PAYMENT_TYPE.SALARY ? (

                        <TextFieldNumberFormat name={`${name}.payment`} label={t('paymentPerArea')}
                                               type={'number'}
                                               size={'small'}/>
                    ) : (
                        <>
                            <TermsSelector name={`${name}.food_term`} autocompleteProps={{size: 'small'}}
                                           label={t('typeFood')}/>
                            <TextFieldNumberFormat name={`${name}.food_amount`} type={'number'}
                                                   size={'small'} label={t('amountFood')}/>
                        </>
                    )}
                    <TextFieldElement name={`${name}.duration.frequency_months`} label={t('numberTimes')}
                                      type={'number'}
                                      size={'small'}/>
                    <MonthMultiSelectElement name={`${name}.duration.frequency_months_multi`} label={t('months')}
                                             size={'small'}/>
                </>
            )}

        </FormStack>
    )
}