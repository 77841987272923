import {Avatar, Chip, Stack, StackProps} from "@mui/material";
import {getNumberFormat} from "../../lib/helpers/numberFormat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBowlRice, faMoneyBillWave} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@mui/material/styles";
import {DataUsage, Done, DoneAll, Flip} from "@mui/icons-material";

type CaloriesIncomeExpenseProps = {
    stackProps?: StackProps
    calories?: number | string
    income?: number
    expenses?: number
    small?: boolean
    diffIncomeExpenses?: boolean
    diffIncomeExpensesTick?: boolean
    diffCalories?: boolean
    showDiffIncomeExpenses?: boolean
    showDiffCalories?: boolean
    filled?: boolean
}

export default function CaloriesIncomeExpense({
                                                  stackProps,
                                                  income = 0,
                                                  expenses = 0,
                                                  calories = 0,
                                                  small,
                                                  filled,
                                                  showDiffCalories,
                                                  showDiffIncomeExpenses
                                              }: CaloriesIncomeExpenseProps) {
    const theme = useTheme()
    let isImbalancedCash = false
    let isBalancedCalories = false
    if (showDiffIncomeExpenses) {
        const diffIncome = income - expenses
        const calc = ((diffIncome / income * 100) + (diffIncome / expenses * 100)) / 2
        isImbalancedCash = calc > 10 || calc < -10;
    }
    if (showDiffCalories) {
        isBalancedCalories = 90 < Number(calories) && Number(calories) < 135
    }

    return (
        <Stack spacing={small ? 1 : 3} direction={'row'} justifyContent={'center'} {...stackProps}>
            {!!calories && (
                <Chip variant={filled ? 'filled' : 'outlined'}
                      color={'info'}
                      size={small ? 'small' : undefined}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faBowlRice}
                                                                                     color={filled ? 'white' : theme.palette.info.main}/></Avatar>}
                      label={`${calories}%`}/>
            )}
            {showDiffCalories && (
                <>
                    {isBalancedCalories ? (
                        <Done fontSize={'small'} sx={{color: 'primary.main', marginLeft: '4px!important'}}/>
                    ) : (
                        <DataUsage fontSize={'small'} sx={{color: '#ccc', marginLeft: '4px!important'}}/>
                    )}
                </>
            )}
            {!!income && (
                <Chip color={'success'}
                      sx={{
                          marginLeft: showDiffCalories ? '4px!important' : undefined,
                          color: filled ? 'white' : undefined
                      }}
                      size={small ? 'small' : undefined}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faMoneyBillWave}
                                                                                     color={filled ? 'white' : theme.palette.success.dark}/></Avatar>}
                      variant={filled ? 'filled' : 'outlined'} label={getNumberFormat(income)}/>
            )}

            {showDiffIncomeExpenses && (
                <>
                    {isImbalancedCash ? (
                        <Flip fontSize={'small'} sx={{color: '#ccc', marginLeft: '4px!important'}}/>
                    ) : (

                        <DoneAll fontSize={'small'} sx={{color: 'primary.main', marginLeft: '4px!important'}}/>
                    )}
                </>
            )}
            {!!expenses && (
                <Chip color={'error'}
                      sx={{
                          marginLeft: showDiffIncomeExpenses ? '4px!important' : undefined
                      }}
                      variant={filled ? 'filled' : 'outlined'}
                      size={small ? 'small' : undefined}
                      avatar={<Avatar sx={{bgcolor: 'transparent'}}><FontAwesomeIcon icon={faMoneyBillWave}
                                                                                     color={filled ? 'white' : theme.palette.error.main}/></Avatar>}
                      label={getNumberFormat(expenses)}/>
            )}

        </Stack>
    )
}