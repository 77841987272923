import {TermsSelector} from "../../../common/form/TermsSelector";
import {SwitchElement} from "react-hook-form-mui";
import {LabourDurationFrequencyForm} from "../../../common/form/purchaseSaleForm/LabourDurationFrequencyForm";
import {Stack, Typography} from "@mui/material";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {TextFieldNumberFormat} from "../../../common/form/TextFieldNumberFormat";
import {FormStack} from "../../../common/form/FormStack";

type SelfEmploymentFormRowProps = {
    name: string
    hideYear?: boolean
    filterCategories?: string[]
}


export function SelfEmploymentFormRow({
                                          name,
                                          hideYear,
                                          filterCategories = ['9955032a-5aba-451f-bace-6f3b03ce963e']
                                      }: SelfEmploymentFormRowProps) {
    const termsOfPayment = `${name}.is_payment_person`;
    const isPayedPerson = useWatch({name: termsOfPayment})
    const {t} = useTranslation()
    return (
        <FormStack direction={'row'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant={'body2'}>{t('unit')}</Typography>
                <SwitchElement label={t('person')} name={termsOfPayment}/>
            </Stack>
            <TermsSelector name={`${name}.term`} filterCategories={filterCategories}
                           rules={{required: t('required')}}
                           autocompleteProps={{size: 'small', sx: {minWidth: '220px'}}} label={t('activity')}/>
            <TextFieldNumberFormat name={`${name}.profit_per_unit`} label={t('profit')} size={'small'}
                                   sx={{width: '120px'}}/>
            {isPayedPerson && (
                <TextFieldNumberFormat name={`${name}.profit_by_person`} label={'# of persons'} size={'small'}/>
            )}
            <TextFieldNumberFormat name={`${name}.quantity`} size={'small'}
                                   label={isPayedPerson ? '# of times' : 'quantity'}/>
            <LabourDurationFrequencyForm name={`${name}.duration`} showMonthPicker hideYear={hideYear}/>
        </FormStack>

    )
}
