import omit from "lodash/omit";
import cleanDeep from "clean-deep";
import {UNDEFINED_UUID} from "../../lib/config";
import {replaceDeepNestedValue} from "./replaceDeepNestedValueWithOther";

import {
    Livestock_Type_Enum,
    Project_Interview_Backup_Insert_Input,
    Project_Interview_Base_Insert_Input,
    Project_Interview_Crops_Season_Insert_Input,
    Project_Interview_Data_Insert_Input,
    Project_Interview_Data_Type_Enum,
    Project_Interview_Livestock_Insert_Input,
    Project_Interview_Productive_Asset_Insert_Input,
    TssVillageData
} from "../../lib";


export function prepareTssVillageDataSyncUp(data: TssVillageData) {
    // convert TSS state into DB insert statement

    const base: Partial<Project_Interview_Base_Insert_Input> = {
        ...omit(data.baseData, ['leftover_cereal_term', 'leftover_cereal_description', 'interview_conducted_date', 'other_productive_assets']),
        interview_id: data.id,
        leftover_cereal_term_id: data.baseData?.leftover_cereal_term?.id,
    }
    const otherProductiveAssets = data.baseData?.other_productive_assets?.filter((i: {
        key: { id: string }
        value: string
    }) => i.key?.id)
        .map((i: {
            key: { id: string }
            value: string
        }) => ({
            term_id: i.key?.id,
            amount: Number(i.value || 0)
        } as Project_Interview_Productive_Asset_Insert_Input))


    const livestockInsertInputs: Project_Interview_Livestock_Insert_Input[] = data.livestock && Object.keys(data.livestock).map(key => {
        const livestock = data.livestock[key as Livestock_Type_Enum]
        // const livestockId = getUid()
        const d = omit(livestock, [
            'purchases', 'exchanged_for_food', 'milkProductionOffPeak', 'milkProductionPeak', 'animals_given_away', 'sales', 'milkProductionOffPeakSecondary', 'milkProductionPeakSecondary'
        ])

        const livestockInsert: Project_Interview_Livestock_Insert_Input = {
            ...d as Project_Interview_Livestock_Insert_Input,
        }
        if (livestock.milkProductionOffPeak?.milked_female) {
            livestockInsert.off_peak_milk = {
                data: livestock.milkProductionOffPeak,
            }

        }
        if (livestock.milkProductionPeak?.milked_female) {
            livestockInsert.peak_milk = {
                data: livestock.milkProductionPeak
            }
        }
        if (livestock.milkProductionPeakSecondary?.milked_female) {
            livestockInsert.peak_milk_secondary = {
                data: livestock.milkProductionPeakSecondary
            }
        }
        if (livestock.milkProductionOffPeakSecondary?.milked_female) {
            livestockInsert.off_peak_milk_secondary = {
                data: livestock.milkProductionOffPeakSecondary
            }
        }


        const purchaseInserts: Omit<Project_Interview_Data_Insert_Input, 'term'>[] = livestock.purchases?.map(i => ({
            ...omit(i, ['term', 'title']),
            type: Project_Interview_Data_Type_Enum.LivestockExpenditure,
            term_id: i.term?.id
        })) ?? []
        const saleInserts: Omit<Project_Interview_Data_Insert_Input, 'term'>[] = livestock.sales?.map(i => ({
            ...omit(i, ['term', 'title']),
            type: Project_Interview_Data_Type_Enum.LivestockSale,
            term_id: i.term?.id
        })) ?? []
        const exchangedInserts: Omit<Project_Interview_Data_Insert_Input, 'term'>[] = livestock.exchanged_for_food?.map(i => ({
            ...omit(i, ['term', 'title']),
            type: Project_Interview_Data_Type_Enum.LivestockExchanged,
            term_id: i.term?.id
        })) ?? []
        const givenAwayInserts: Omit<Project_Interview_Data_Insert_Input, 'term'>[] = livestock.animals_given_away?.map(i => ({
            ...omit(i, ['term', 'title']),
            type: Project_Interview_Data_Type_Enum.LivestockGivenAway,
        })) ?? []

        const livestockData: Project_Interview_Data_Insert_Input[] = [...purchaseInserts, ...saleInserts, ...exchangedInserts, ...givenAwayInserts]
        if (livestockData?.length) {
            livestockInsert.project_interview_data = {
                data: livestockData
            }
        }
        return livestockInsert
    })
    const cropSeasonInsertInput: Project_Interview_Crops_Season_Insert_Input[] = data.crops && Object.keys(data.crops).map(crop => {
        const cropSeason = data.crops[crop]
        const d = omit(cropSeason, ['green_crops', 'root_crops', 'season', 'interview_id', 'expenditures', 'production', 'id'])
        const insert: Project_Interview_Crops_Season_Insert_Input = {
            crop_season_id: cropSeason.season?.id,
            ...d
        }
        if (cropSeason.green_crops) {
            insert.green_crops = {
                data: {
                    ...omit(cropSeason.green_crops, ['season_id', 'id'])
                }
            }
        }
        if (cropSeason.root_crops) {
            insert.root_crops = {
                data: {
                    ...omit(cropSeason.root_crops, ['season_id', 'id'])
                }
            }
        }
        insert.project_interview_crops = {
            data: cropSeason.production?.map(crop => {
                return {
                    ...omit(crop, ['term', 'season_id', 'title']),
                    term_id: crop.term?.id
                }
            }) ?? []
        }
        insert.project_interview_data = {
            data: cropSeason.expenditures?.map(expenditure => {
                return {
                    ...omit(expenditure, ['term', 'title']),
                    term_id: expenditure.term?.id,
                    type: Project_Interview_Data_Type_Enum.CropsExpenditure
                }
            }) ?? []
        }

        return insert
    })


    const interviewDataInserts: Project_Interview_Data_Insert_Input[] = []

    data.purchasesFood?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title']),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.ExpenditureFood
    }))
    data.purchasesNonFood?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title']),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.ExpenditureNonFood
    }))
    data.migration?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title']),
        type: Project_Interview_Data_Type_Enum.Migration
    }))
    data.fishing_wild_food?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title', 'total_calories']),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.FishingWildFood
    }))
    data.fishing_wild_food_expenses?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title']),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.FishingWildFoodExpenses
    }))
    data.food_aid?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title', 'total_calories']),
        term_id: i.term?.id,
        type: Project_Interview_Data_Type_Enum.FoodAid
    }))
    data.remittances_credit?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', 'title']),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.RemittancesCredit
    }))

    data.labour_jobs?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', "title", "food_term"]),
        term_id: i.term.id,
        food_term_id: i.food_term?.id,
        type: Project_Interview_Data_Type_Enum.Employment
    }))

    data.labour_exchange?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', "title", "food_term"]),
        term_id: i.term?.id,
        food_term_id: i.food_term?.id,
        type: Project_Interview_Data_Type_Enum.LabourExchange
    }))
    data.self_employment?.forEach(i => interviewDataInserts.push({
        ...omit(i, ['term', "title"]),
        term_id: i.term.id,
        type: Project_Interview_Data_Type_Enum.SelfEmployment
    }))


    const projectInterviewInsertInput: Project_Interview_Base_Insert_Input = {
        ...base,
        project_interview_productive_assets: {
            data: otherProductiveAssets || []
        },
        project_interview_livestocks: {
            data: livestockInsertInputs || []
        },
        project_interview_crops_seasons: {
            data: cropSeasonInsertInput || []
        },
        project_interview_data: {
            data: interviewDataInserts.map(
                i => cleanDeep(omit(i, ['total_kg', 'term', 'title', 'food_term', 'total_calories']))
            )
        }
    }
    if (data.total) {
        projectInterviewInsertInput.project_interview_total = {
            data: data.total
        }
    }

    const projectInterviewBackup: Project_Interview_Backup_Insert_Input = {
        interview_id: data.id,
        data: data
    }
    const cleanedInsertData = replaceDeepNestedValue(cleanDeep(projectInterviewInsertInput, {
        emptyStrings: true,
        cleanKeys: ['project_interview_id'] // dont publish any old base data id
    }), '_other', UNDEFINED_UUID); // need to remove empty strings for numeric and _other for uuid on measure selector

    return {
        projectInterviewInsertInput: cleanedInsertData,
        projectInterviewBackup
    }
}